<template>
    <v-container>
        <v-row class="details__card">
            <v-col cols="9" xl="9" lg="9" md="12" sm="12" class="pa-5">
                <v-row>
                    <v-col cols="12" md="12">
                        <Separator :text="$t('service:partsTally')" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-data-table
                            class="serviceParts__table mb-5"
                            fixed-header
                            :mobile-breakpoint="1100"
                            height="calc(100vh - 500px)"
                            hide-default-footer
                            :search="search"
                            :headers="headers"
                            ><template v-slot:body>
                                <tbody>
                                    <tr
                                        v-for="(item,
                                        index) in getServicePartsDetails.partsTable"
                                        :key="index"
                                    >
                                        <td>{{ item.manufacturerIndex }}</td>
                                        <td>{{ item.name }}</td>
                                        <td>
                                            {{
                                                item.substitute ? 'Tak' : 'Nie'
                                            }}
                                        </td>
                                        <td>{{ item.price }}</td>
                                        <td>{{ item.quantity }}</td>
                                        <td>
                                            <v-checkbox
                                                dense
                                                readonly
                                                color="secondary"
                                                :ripple="false"
                                                v-model="item.wasUsed"
                                            ></v-checkbox>
                                        </td>
                                        <!-- <td /> -->
                                        <td>
                                            {{
                                                setSum(
                                                    item.price,
                                                    item.quantity
                                                )
                                            }}
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <span class="d-flex justify-end totalSum">
                            <span
                                >{{ $t('service:wasUsedTotal').toUpperCase() }}:
                                <money
                                    v-bind="money"
                                    class="parts--money"
                                    readonly
                                    :value="getServicePartsDetails.sum || 0"
                                ></money>
                            </span>
                            <span> + 23% VAT</span>
                        </span>
                        <div class="d-flex justify-end totalSum">
                            {{ $t('service:total').toUpperCase() }}:
                            <span></span>
                            <money
                                v-bind="money"
                                class="parts--money"
                                readonly
                                :value="
                                    setTotalSum(
                                        getServicePartsDetails.partsTable
                                    )
                                "
                            ></money>

                            <span> + 23% VAT</span>
                        </div>
                        <div
                            v-if="
                                setTotalSumWithDiscount(
                                    getServicePartsDetails.partsTable
                                )
                            "
                            class="d-flex justify-end totalSum"
                        >
                            {{
                                $t(
                                    'service:totalSumWithDiscount'
                                ).toUpperCase()
                            }}:
                            <span></span>
                            <money
                                v-bind="money"
                                class="parts--money"
                                readonly
                                :value="
                                    setTotalSumWithDiscount(
                                        getServicePartsDetails.partsTable
                                    )
                                "
                            ></money>

                            <span> + 23% VAT</span>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="3" xl="3" lg="3" md="12" sm="12" class="pa-5"
                ><v-row>
                    <v-col cols="12" md="12">
                        <Separator :text="$t('service:partReckoning')" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12" class="mb-5">
                        <v-text-field
                            readonly
                            dense
                            :value="
                                getServicePartsDetails.soldPartsInvoiceNumber ||
                                ''
                            "
                            :label="$t('service:partFVNumver')"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="12" class="mt-5">
                        <v-text-field
                            readonly
                            dense
                            :value="
                                setDate(
                                    getServicePartsDetails.internalPaymentDate
                                )
                            "
                            :label="$t('service:reckoningDateInner')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-text-field
                            readonly
                            dense
                            v-model="
                                getServicePartsDetails.internalDocumentNumber
                            "
                            :label="$t('service:documentNumberInner')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-text-field
                            readonly
                            dense
                            :value="
                                setDate(
                                    getServicePartsDetails.externalPaymentDate
                                )
                            "
                            :label="$t('service:reckoningDateOuter')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12" class="mb-5">
                        <v-text-field
                            readonly
                            dense
                            v-model="
                                getServicePartsDetails.externalDocumentNumber
                            "
                            :label="$t('service:documentNumberOuter')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12" class="mt-5">
                        <v-text-field
                            readonly
                            dense
                            v-model="getServicePartsDetails.description"
                            :label="$t('service:comments')"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <Buttons />
    </v-container>
</template>

<script>
import Buttons from './../Buttons/Buttons'
import { mapActions, mapGetters } from 'vuex'
export default {
    data() {
        return {
            search: '',
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: ' PLN',
                precision: 2,
                masked: false,
            },
            headers: [
                {
                    text: this.$t('service:index'),
                    align: 'left',
                    value: 'manufacturerIndex',
                    width: 80,
                },
                { text: this.$t('global:name'), value: 'name', width: 150 },
                {
                    text: this.$t('service:replacement'),
                    value: 'substitute',
                    width: 120,
                },
                { text: this.$t('service:price'), value: 'price', width: 80 },
                {
                    text: this.$t('service:quantity'),
                    value: 'quantity',
                    width: 80,
                },
                {
                    text: this.$t('service:wasUsed'),
                    value: 'wasUsed',
                    width: 80,
                },
                // {
                //     text: this.$t('service:wasUsedTotal'),
                //     value: 'wasUsedSum',
                //     width: 105,
                // },
                { text: this.$t('service:total'), value: 'sum', width: 105 },
                // { width: 50 },
            ],
        }
    },
    components: {
        Buttons,
    },
    computed: {
        ...mapGetters(['getServicePartsDetails', 'getServiceDetails']),
    },
    methods: {
        ...mapActions(['fetchServicePartsTab']),
        setSum(price, quantity) {
            let sum = price * quantity
            sum = sum.toFixed(2)
            return sum
        },
        setTotalSum(items) {
            let temp = [0]
            items.forEach(item => {
                const sum = item.price * item.quantity
                temp.push(sum)
            })
            // części Błąd
            // const totalSum = parseInt(temp.reduce((acc, val) => acc + val))
            const totalSum = temp.reduce((acc, val) => acc + val)
            return totalSum
        },
        setTotalWasUsedSum(items) {
            let temp = [0]
            items.forEach(item => {
                const sum = item.price * item.quantity
                if (item.wasUsed) temp.push(sum)
            })
            const totalSum = parseInt(temp.reduce((acc, val) => acc + val))
            return totalSum
        },
        setTotalSumWithDiscount(items) {
            const discount = this.$get(
                this.getServiceDetails,
                'device.model.servicePrice.partsDiscountInPercent.price',
                ''
            )

            if (discount) {
                let temp = [0]
                items.forEach(item => {
                    const sum = item.price * item.quantity
                    temp.push(sum)
                })
                let totalSum = parseInt(temp.reduce((acc, val) => acc + val))
                totalSum = totalSum - totalSum * (discount * 0.01)
                return totalSum
            } else {
                return ''
            }
        },
        setDate(date) {
            if (date) return this.$moment(date).format('DD-MM-YYYY')
        },
    },
    beforeMount() {
        this.fetchServicePartsTab({ id: this.$route.params.id, badge: true })
    },
}
</script>

<style lang="sass" scoped>
.totalSum
    margin-top: 5px
    font-size: 14px
    font-weight: 500
.parts--money
    text-align: right
    margin-right: 5px
    max-width: 100px
.table
    border-bottom: 1px solid grey
</style>
