<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" sm="12" class="pa-0">
                <v-stepper v-model="step" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step editable step="1">{{
                                $t('service:confirmService')
                            }}</v-stepper-step>
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <div class="d-flex align-center">
                                    <p class="mt-3 mr-5 clientConfirmService">
                                        {{
                                            `${$t(
                                                'service:clientConfirmService'
                                            )}:`
                                        }}
                                    </p>

                                    <v-radio-group
                                        v-model="
                                            getServiceModal.realization
                                                .signature.confirmService
                                        "
                                        row
                                        class="ml-5"
                                    >
                                        <v-radio
                                            :label="$t('global:yes')"
                                            :value="true"
                                        ></v-radio>
                                        <v-radio
                                            :label="$t('global:no')"
                                            :value="false"
                                        ></v-radio>
                                    </v-radio-group>
                                </div>
                                <div class="date-generate">
                                    <p v-if="!isSignatureDateAvaliable">
                                        Generuj datę i czas wykonania usługi:
                                    </p>
                                    <p v-if="isSignatureDateAvaliable">
                                        Data i czas wykonania usługi:
                                    </p>
                                    <v-btn
                                        v-if="!isSignatureDateAvaliable"
                                        @click="generateSignatureData"
                                        class="generate-button"
                                        color="buttons--add"
                                    >
                                        Generuj
                                    </v-btn>
                                    <v-btn
                                        v-if="isSignatureDateAvaliable"
                                        :disabled="isSignatureDateAvaliable"
                                        @click="generateSignatureData"
                                        class="generate-button generate-button--disabled"
                                        color="buttons--add"
                                        >{{ signatureDate || tempDate }}</v-btn
                                    >
                                </div>
                                <div
                                    id="app"
                                    class="ma-3 d-flex justify-center"
                                >
                                    <VueSignaturePad
                                        :options="{
                                            onBegin,
                                            onEnd,
                                        }"
                                        style="border: 1px solid red;"
                                        saveType="image/png"
                                        width="600px"
                                        :height="
                                            !this.isTablet() ? '300px' : '150px'
                                        "
                                        ref="signaturePad"
                                    />
                                </div>
                                <img
                                    class="signature--image"
                                    v-if="
                                        !this.isTablet() &&
                                        $get(
                                            getServiceModal,
                                            'realization.signature.signature.data',
                                            ''
                                        )
                                    "
                                    :src="
                                        getServiceModal.realization.signature
                                            .signature.data
                                    "
                                    alt="entelli logo"
                                />
                                <img
                                    class="signature--image--mobile"
                                    v-if="
                                        this.isTablet() &&
                                        $get(
                                            getServiceModal,
                                            'realization.signature.signature.data',
                                            ''
                                        )
                                    "
                                    :src="
                                        getServiceModal.realization.signature
                                            .signature.data
                                    "
                                    alt="entelli logo"
                                />
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
    props: {},
    data() {
        return {
            step: 1,
            tempDate: '',
            isSignatureDateAvaliable: true,
        }
    },
    computed: {
        ...mapGetters(['getServiceModal']),
        signatureDate: {
            get() {
                if (this.getServiceModal?.realization?.signature?.date) {
                    this.isSignatureDateAvaliable = true
                    return this.getServiceModal?.realization?.signature?.date
                } else {
                    this.isSignatureDateAvaliable = false
                    return ''
                }
            },
            set(date) {
                this.getServiceModal.realization.signature.date = date
                this.isSignatureDateAvaliable = true
            },
        },
    },

    methods: {
        onEnd() {
            this.$emit('signature', this.$refs, this.confirmService)
        },
        onBegin() {
            this.$refs.signaturePad.resizeCanvas()
        },
        generateSignatureData() {
            const date = moment().format('DD/MM/YYYY - HH:mm')
            this.tempDate = date
            this.signatureDate = date
        },
    },
    mounted() {
        if (this.getServiceModal.realization?.signature?.date) {
            this.isSignatureDateAvaliable = true
        } else {
            this.isSignatureDateAvaliable = false
        }
    },
}
</script>
<style lang="sass" scoped>
.signature--image
    height: 150px
    padding: 0 70px
.signature--image--mobile
    height: 80px
    padding: 0 70px
.date-generate
    display: flex

    justify-content: flex-end
    margin-right: 9%
    .generate-button
        height: 29px
        margin-left: 43px
        border-radius: 3px
        min-width: 177px
        font-size: 10px
        &--disabled
            font-size: 14px
</style>
