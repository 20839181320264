<template>
    <div>
        <Modal
            :title="$t('global:edit')"
            :open="open"
            v-on:close="closeModal"
            :height="'800'"
            :width="'1000'"
        >
            <AEContent slot="AEContent" />
            <Buttons
                @close-modal="open = false"
                slot="buttons"
                :action="'edit'"
            />
        </Modal>
        <div class="d-flex justify-end buttons--details">
            <v-tooltip left>
                <template v-slot:activator="{ on }">
                    <v-btn
                        fab
                        dark
                        v-on="on"
                        v-if="edit()"
                        small
                        @click="editParts()"
                        class="buttons--add margin--1"
                    >
                        <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('global:edit') }}</span>
            </v-tooltip>
        </div>
    </div>
</template>
<script>
import Buttons from './Modal/Buttons.vue'
import AEContent from './Modal/Content'
import { mapMutations } from 'vuex'
export default {
    i18nOptions: { namespaces: ['users', 'global'] },

    data() {
        return {
            open: false,
        }
    },

    methods: {
        ...mapMutations([
            'setImplementationModal',
            'clearImplementationModal',
            'setServicePartsModal',
        ]),
        edit() {
            return this.checkPermissions('UPDATE')
        },
        editParts() {
            this.setServicePartsModal()
            this.open = true
        },
        closeModal() {
            this.open = false
        },
    },
    components: { Buttons, AEContent },
}
</script>
