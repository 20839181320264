<template>
    <v-container>
        <v-row>
            <v-col cols="12" xl="12" lg="12" md="12" sm="12">
                <v-row>
                    <v-col cols="12" md="12">
                        <v-data-table
                            class="serviceParts__modal"
                            :height="this.isTablet() ? '262' : '262'"
                            fixed-header
                            :mobile-breakpoint="1100"
                            hide-default-footer
                            :items="estimatesServices"
                            :headers="headersList"
                        >
                            <template v-slot:body>
                                <tbody>
                                    <tr
                                        v-for="(item,
                                        index) in estimatesServices"
                                        :key="index"
                                    >
                                        <td>
                                            {{ item.field }}
                                        </td>
                                        <td>{{ item.quantity }} h</td>
                                        <td>
                                            <money-field
                                                v-model="item.price"
                                                readonly
                                            ></money-field>
                                        </td>
                                        <td>
                                            <money-field
                                                :value="
                                                    setSum(
                                                        item.price,
                                                        item.quantity
                                                    )
                                                "
                                                readonly
                                            ></money-field>
                                        </td>

                                        <td v-if="mode === 'edit'">
                                            <div>
                                                <v-icon
                                                    small
                                                    color="#003399"
                                                    class="mr-2"
                                                    @click="
                                                        editItem(item, index)
                                                    "
                                                    >mdi-pencil</v-icon
                                                >
                                                <v-icon
                                                    small
                                                    color="#e60000"
                                                    @click="
                                                        deleteItem(item, index)
                                                    "
                                                    >mdi-delete</v-icon
                                                >
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                            <template v-slot:top>
                                <v-dialog
                                    v-model="dialog"
                                    width="400px"
                                    style="z-index: 999;"
                                >
                                    <v-card>
                                        <v-card-title>
                                            <span class="headline"
                                                >Informacje</span
                                            >
                                        </v-card-title>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" sm="4" md="4">
                                                    <money-field
                                                        label="Cena"
                                                        v-model="editInfo.price"
                                                    ></money-field>
                                                </v-col>
                                                <v-col cols="12" sm="4" md="4">
                                                    <v-text-field
                                                        v-model="
                                                            editInfo.quantity
                                                        "
                                                        :min="0"
                                                        label="Ilość"
                                                        ><template
                                                            v-slot:prepend
                                                        >
                                                            <v-icon
                                                                @click="
                                                                    halfValue(
                                                                        'MINUS'
                                                                    )
                                                                "
                                                                size="20"
                                                                color="grey"
                                                                >mdi-minus</v-icon
                                                            > </template
                                                        ><template
                                                            v-slot:append-outer
                                                        >
                                                            <v-icon
                                                                @click="
                                                                    halfValue(
                                                                        'ADD'
                                                                    )
                                                                "
                                                                size="20"
                                                                color="grey"
                                                                >mdi-plus</v-icon
                                                            >
                                                        </template></v-text-field
                                                    >
                                                </v-col>
                                            </v-row>
                                        </v-container>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                class="buttons mr-3"
                                                @click="closeDialog"
                                                >Anuluj</v-btn
                                            >

                                            <v-btn
                                                dark
                                                class="buttons buttons--add"
                                                @click="saveExtraModal"
                                                >Zapisz</v-btn
                                            >
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <div class="d-flex justify-end totalSum">
                            {{ $t('service:total').toUpperCase() }}:
                            <span></span>
                            <money
                                v-bind="money"
                                class="parts--money"
                                readonly
                                :value="setTotalSum(estimatesServices)"
                            ></money>

                            <span> + 23% VAT</span>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <!-- <Buttons /> -->
    </v-container>
</template>
<script>
import Buttons from './Buttons.vue'
import { mapActions, mapGetters } from 'vuex'
import findIndex from 'lodash.findindex'
export default {
    props: ['mode'],
    data() {
        return {
            search: '',
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: ' PLN',
                precision: 2,
                masked: false,
            },
            headers: [
                {
                    text: this.$t('estimates:serviceName'),
                    align: 'left',
                    value: 'field',
                    width: 200,
                },
                {
                    text: this.$t('estimates:quantityOwnManHours'),
                    align: 'left',
                    value: 'quantity',
                    width: 70,
                },
                {
                    text: this.$t('estimates:priceOneHour'),
                    align: 'left',
                    value: 'price',
                    width: 150,
                },
                {
                    text: this.$t('estimates:totalNetto'),
                    align: 'left',
                    value: 'totalNetto',
                    width: 150,
                },
            ],
            dialog: false,
            editInfo: {
                id: '',
                quantity: '',
                price: '',
                wasUsed: '',
            },
            wasUsedItems: [
                { text: this.$t('global:yes'), value: true },
                { text: this.$t('global:no'), value: false },
            ],
        }
    },
    components: {
        Buttons,
    },

    computed: {
        ...mapGetters([
            'getServicePartsDetails',
            'getServiceDetails',
            'getEstimatesDetailsTable',
            'getRegistryDictionaries',
            'getEstimatesChooseService',
        ]),

        headersList: {
            get: function () {
                if (this.mode === 'edit') {
                    // ADD HEADER FOR EDITING
                    this.headers.push({ width: 50 })
                    return this.headers
                }
                return this.headers
            },
        },
        estimatesServices: {
            get: function () {
                const data = this.getEstimatesChooseService?.estimatesServices
                if (data) {
                    return data
                } else {
                    return []
                }
            },
        },
    },
    methods: {
        ...mapActions(['fetchServiceEstimatesTab']),
        setSum(price, quantity) {
            let sum = price * quantity
            sum = sum.toFixed(2)
            return sum
        },
        setTotalSum(items) {
            let temp = [0]
            items.forEach(item => {
                const sum = item.price * item.quantity
                temp.push(sum)
            })
            const totalSum = parseFloat(temp.reduce((acc, val) => acc + val))
            return totalSum
        },

        setTotalSumWithDiscount(items) {
            const discount = this.$get(
                this.getServiceDetails,
                'device.model.servicePrice.partsDiscountInPercent.price',
                ''
            )

            if (discount) {
                let temp = [0]
                items.forEach(item => {
                    const sum = item.price * item.quantity
                    temp.push(sum)
                })
                let totalSum = parseFloat(temp.reduce((acc, val) => acc + val))
                totalSum = totalSum - totalSum * (discount * 0.01)
                return totalSum
            } else {
                return ''
            }
        },
        setDate(date) {
            if (date) return this.$moment(date).format('DD-MM-YYYY')
        },
        editItem(el, index) {
            this.editInfo.arrayIndex = index
            this.editInfo.quantity = el.quantity || 1
            this.editInfo.price = el.price || 0
            this.editInfo.total = el.price * (el.quantity || 1)
            this.dialog = true
        },
        deleteItem(el, index) {
            let arr = this.getEstimatesChooseService?.estimatesServices
            arr.splice(index, 1)
        },
        saveExtraModal() {
            let arr = this.getEstimatesChooseService?.estimatesServices
            const index = this.editInfo.arrayIndex
            arr[index].price = this.editInfo.price

            arr[index].quantity = this.editInfo.quantity
                .toString()
                .replace(',', '.')

            arr[index].total = this.editInfo.quantity * this.editInfo.price
            this.dialog = false
        },
        closeDialog() {
            this.dialog = false
        },
        halfValue(mode) {
            if (mode === 'ADD') {
                this.editInfo.quantity =
                    parseFloat(this.editInfo.quantity) + 0.5
            }
            if (mode === 'MINUS') {
                this.editInfo.quantity =
                    parseFloat(this.editInfo.quantity) - 0.5
            }
        },
    },
    beforeMount() {
        this.fetchServiceEstimatesTab({ id: this.$route.params.id })
    },
}
</script>

<style lang="sass" scoped>
.totalSum
    margin-top: 5px
    font-size: 14px
    font-weight: 500
.parts--money
    text-align: right
    margin-right: 5px
    max-width: 100px
.table
    border-bottom: 1px solid grey
</style>
