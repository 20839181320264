<template>
    <div>
        <Modal
            :title="$t('global:edit')"
            :height="window.height >= 800 ? '800' : 'calc( 600px - 110px )'"
            :width="window.width >= 800 ? '880' : 'calc( 600px - 110px )'"
            :open="open"
            v-on:close="closeModal"
        >
            <AEContent slot="AEContent" />
            <Buttons
                @close-modal="open = false"
                slot="buttons"
                :action="'edit'"
            />
        </Modal>
        <Modal
            :title="$t('service:confirmService')"
            :open="openSignatureModal"
            v-on:close="closeSignatureModal"
        >
            <SignatureContent @signature="customerSignature" slot="AEContent" />
            <ButtonsSignature
                @close-modal="closeSignatureModal"
                :saveSignature="{ signature, confirmService }"
                slot="buttons"
            />
        </Modal>
        <div class="d-flex justify-end buttons--details">
            <v-tooltip v-if="!isTablet()" left>
                <template v-slot:activator="{ on }">
                    <v-btn
                        fab
                        dark
                        v-on="on"
                        @click="signatureModal()"
                        small
                        class="mr-3 buttons--add"
                    >
                        <v-icon size="30">mdi-draw</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('service:customerSignature') }}</span>
            </v-tooltip>
            <v-btn
                v-if="isTablet()"
                fab
                dark
                v-on="on"
                @click="signatureModal()"
                small
                class="mr-3 buttons--add"
            >
                <v-icon size="30">mdi-draw</v-icon>
            </v-btn>

            <v-tooltip left>
                <template v-slot:activator="{ on }">
                    <v-btn
                        fab
                        dark
                        v-on="on"
                        @click="$emit('open-new-tabs')"
                        small
                        class="mr-3 buttons--add"
                    >
                        <v-icon size="18">mdi-library-shelves</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('service:goToModels') }}</span>
            </v-tooltip>
            <v-tooltip left>
                <template v-slot:activator="{ on }">
                    <v-btn
                        fab
                        dark
                        v-on="on"
                        v-if="edit()"
                        small
                        @click="editService()"
                        class="buttons--add margin--1"
                    >
                        <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('service:editImplementation') }}</span>
            </v-tooltip>
        </div>
    </div>
</template>
<script>
import Buttons from './../Modal/Buttons'
import ButtonsSignature from './../SignatureModal/ButtonsSignature'
import AEContent from './../Modal/Content'
import SignatureContent from './../SignatureModal/SignatureModal'
import { mapMutations, mapActions } from 'vuex'
export default {
    i18nOptions: { namespaces: ['users', 'global'] },

    data() {
        return {
            open: false,
            openSignatureModal: false,
            signature: {},
            clearSign: {},
            confirmService: {},

            window: {
                height: 0,
                width: 0,
            },
        }
    },

    methods: {
        ...mapActions(['setServiceStep']),
        ...mapMutations(['setServiceModal', 'clearServiceModal']),
        edit() {
            return this.checkPermissions('UPDATE')
        },
        customerSignature(sig, confirmService) {
            this.confirmService = confirmService
            this.clearSign = sig.signaturePad
            this.signature = sig.signaturePad.saveSignature()
        },
        closeSignatureModal() {
            this.openSignatureModal = false
            if (this.clearSign.toData) this.clearSign.clearSignature()
        },
        editService() {
            // this.setServiceStep(1);
            this.setServiceModal()
            this.open = true
        },
        signatureModal() {
            this.openSignatureModal = true

            this.setServiceModal()
        },
        closeModal() {
            this.open = false
        },
        handleResize() {
            this.window.height = window.innerHeight
            this.window.width = window.innerWidth
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize()
    },
    components: { Buttons, AEContent, SignatureContent, ButtonsSignature },
}
</script>
