<template>
    <div>
        <v-btn @click="edit()" class="buttons buttons--add">
            {{ $t('global:save') }}
        </v-btn>
        <ConfirmModal
            :openConfirm="openConfirm"
            @close-confirm="closeConfirmModal"
        >
            <div slot="main">
                <ConfirmContent :type="type" />
            </div>
            <div slot="buttons">
                <div class="d-flex justify-center">
                    <v-btn
                        color="rgb(117, 117, 117)"
                        width="210"
                        height="40"
                        @click="closeConfirmModal()"
                        class="white--text mx-2"
                    >
                        Nie zmieniaj statusu
                    </v-btn>
                    <v-btn
                        width="210"
                        height="40"
                        color="#445d75"
                        class="white--text mx-2"
                        @click="changeStatus()"
                        >Gotowa do weryfikacji</v-btn
                    >
                </div>
            </div>
        </ConfirmModal>
    </div>
</template>
<script>
import ConfirmContent from './StatusChangeContent.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    props: {
        action: {
            type: String,
        },
    },
    components: {
        ConfirmContent,
    },
    data() {
        return {
            disabled: false,
            type: '',
            openConfirm: false,
        }
    },
    computed: {
        ...mapGetters(['getServiceModal']),
    },
    methods: {
        ...mapActions([
            'createService',
            'updateServiceEstimate',
            'setEstimateStatus',
            'fetchService'
        ]),
        ...mapMutations([
            'setSnackbars'
        ]),

        async edit() {
            this.disabled = true
            let success = await this.updateServiceEstimate({
                id: this.$route.params.id,
            })
            if (success) {
                this.openConfirm = true
                this.$emit('closeAddEstimateModal')
                this.fetchService({ id: this.$route.params.id })
            } else {
                this.setSnackbars({
                    type: 'error',
                    text: `Błąd zapisu wyceny`,
                })
            }
            this.disabled = false
        },

        closeConfirmModal() {
            this.openConfirm = false
            this.$root.$emit('clearModal')
        },
        async changeStatus() {
            this.openConfirm = false
            this.setEstimateStatus({
                name: 'Do weryfikacji',
                color: {
                    background: '#55BD42',
                    font: '#FFFFFF',
                },
            })
            let success = await this.updateServiceEstimate({
                id: this.$route.params.id,
            })
            this.fetchService({ id: this.$route.params.id })
        },
    },
}
</script>
