<template>
    <div>
        <v-btn @click="edit()" :disabled="!getServiceError.stepOne.validation" class="buttons buttons--add">
            {{ $t('global:save') }}
        </v-btn>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    props: {
        action: {
            type: String,
        },
    },
    computed: {
        ...mapGetters(['getServiceModal', 'getServiceError']),
    },

    methods: {
        ...mapActions(['updateService']),
        ...mapMutations([
            'setImplementationServiceWasChanged',
            'setServiceModal',
        ]),
        async edit() {

            this.updateService({ id: this.$route.params.id }).then(() => {
                
                this.$emit('close-modal')
                this.setImplementationServiceWasChanged(false)
            })
        },
        
    },
}
</script>
