var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"elevation-0",model:{value:(_vm.getServiceStep.step),callback:function ($$v) {_vm.$set(_vm.getServiceStep, "step", $$v)},expression:"getServiceStep.step"}},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () => _vm.getServiceError.stepOne.validation,
                            ],"editable":"","step":"1"}},[_vm._v(_vm._s(_vm.$t('service:basicInformations')))]),_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () => _vm.getServiceError.stepTwo.validation,
                            ],"editable":"","step":"2"}},[_vm._v(_vm._s(_vm.$t('service:detailsInformations')))]),_c('v-divider'),(
                                _vm.getServiceModal.settleTheTransportCheckboxValue
                            )?_c('v-divider'):_vm._e(),(
                                _vm.getServiceModal.settleTheTransportCheckboxValue
                            )?_c('v-stepper-step',{attrs:{"rules":[
                                () => _vm.getServiceError.stepThree.validation,
                            ],"editable":"","step":"3"}},[_vm._v(_vm._s(_vm.$t('service:transport')))]):_vm._e(),(
                                _vm.getServiceModal.settleTheTransportCheckboxValue
                            )?_c('v-divider'):_vm._e()],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-row',[_c('v-col',{staticClass:"background--date pl-5 py-0 mb-2",attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.$get(
                                                        _vm.getServiceModal,
                                                        'realization.startDate',
                                                        false
                                                    )
                                                        ? _vm.$moment(
                                                              _vm.getServiceModal
                                                                  .realization
                                                                  .startDate
                                                          ).format(
                                                              'DD-MM-YYYY'
                                                          )
                                                        : '',"clearable":"","label":`${_vm.$t(
                                                    'service:startDate'
                                                )}`},on:{"click:clear":function($event){_vm.getServiceModal.realization.startDate = null}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"size":"35","color":"secondary"},on:{"click":function($event){return _vm.presentTime(
                                                                'start'
                                                            )}}},[_vm._v("mdi-play-circle")])]},proxy:true}],null,true)},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","min":new Date()
                                                    .toISOString()
                                                    .substr(0, 10)},on:{"input":function($event){_vm.startDate = false}},model:{value:(
                                                _vm.getServiceModal.realization
                                                    .startDate
                                            ),callback:function ($$v) {_vm.$set(_vm.getServiceModal.realization
                                                    , "startDate", $$v)},expression:"\n                                                getServiceModal.realization\n                                                    .startDate\n                                            "}})],1)],1),_c('v-col',{staticClass:"background--date pl-5 py-0 mb-2",attrs:{"cols":"2","md":"2"}},[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.getServiceModal.realization
                                                .startTime,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.getServiceModal.realization
                                                , "startTime", $event)},"update:return-value":function($event){return _vm.$set(_vm.getServiceModal.realization
                                                , "startTime", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","label":`${_vm.$t(
                                                    'service:startTime'
                                                )}`,"readonly":""},on:{"click:clear":function($event){_vm.getServiceModal.realization.startTime = null}},model:{value:(
                                                    _vm.getServiceModal
                                                        .realization
                                                        .startTime
                                                ),callback:function ($$v) {_vm.$set(_vm.getServiceModal
                                                        .realization
                                                        , "startTime", $$v)},expression:"\n                                                    getServiceModal\n                                                        .realization\n                                                        .startTime\n                                                "}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startTime),callback:function ($$v) {_vm.startTime=$$v},expression:"startTime"}},[(_vm.startTime)?_c('v-time-picker',{attrs:{"full-width":"","format":"24hr"},on:{"click:minute":function($event){return _vm.$refs.menu2.save(
                                                    _vm.getServiceModal
                                                        .realization
                                                        .startTime
                                                )}},model:{value:(
                                                _vm.getServiceModal.realization
                                                    .startTime
                                            ),callback:function ($$v) {_vm.$set(_vm.getServiceModal.realization
                                                    , "startTime", $$v)},expression:"\n                                                getServiceModal.realization\n                                                    .startTime\n                                            "}}):_vm._e()],1)],1),_c('v-col',{staticClass:"background--date pl-5 py-0 mb-2",attrs:{"cols":"3","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.$get(
                                                        _vm.getServiceModal,
                                                        'realization.endDate',
                                                        false
                                                    )
                                                        ? _vm.$moment(
                                                              _vm.getServiceModal
                                                                  .realization
                                                                  .endDate
                                                          ).format(
                                                              'DD-MM-YYYY'
                                                          )
                                                        : '',"clearable":"","label":`${_vm.$t(
                                                    'service:endDate'
                                                )}`,"readonly":"","disabled":!_vm.getServiceModal
                                                        .realization
                                                        .startDate},on:{"click:clear":function($event){_vm.getServiceModal.realization.endDate = null}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"size":"35","color":"red","disabled":!_vm.getServiceModal
                                                                .realization
                                                                .startDate},on:{"click":function($event){return _vm.presentTime(
                                                                'stop'
                                                            )}}},[_vm._v("mdi-stop-circle")])]},proxy:true}],null,true)},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","min":new Date()
                                                    .toISOString()
                                                    .substr(0, 10),"disabled":!_vm.getServiceModal.realization
                                                    .startDate},on:{"input":function($event){_vm.endDate = false}},model:{value:(
                                                _vm.getServiceModal.realization
                                                    .endDate
                                            ),callback:function ($$v) {_vm.$set(_vm.getServiceModal.realization
                                                    , "endDate", $$v)},expression:"\n                                                getServiceModal.realization\n                                                    .endDate\n                                            "}})],1)],1),_c('v-col',{staticClass:"background--date pl-5 py-0 mb-2",attrs:{"cols":"2","md":"2"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.getServiceModal.realization
                                                .endTime,"transition":"scale-transition","format":"24hr","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.getServiceModal.realization
                                                , "endTime", $event)},"update:return-value":function($event){return _vm.$set(_vm.getServiceModal.realization
                                                , "endTime", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","label":`${_vm.$t(
                                                    'service:endTime'
                                                )}`,"readonly":"","disabled":!_vm.getServiceModal
                                                        .realization
                                                        .startDate &&
                                                    !_vm.getServiceModal
                                                        .realization
                                                        .startTime},on:{"click:clear":function($event){_vm.getServiceModal.realization.endTime = null}},model:{value:(
                                                    _vm.getServiceModal
                                                        .realization.endTime
                                                ),callback:function ($$v) {_vm.$set(_vm.getServiceModal
                                                        .realization, "endTime", $$v)},expression:"\n                                                    getServiceModal\n                                                        .realization.endTime\n                                                "}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endTime),callback:function ($$v) {_vm.endTime=$$v},expression:"endTime"}},[(_vm.endTime)?_c('v-time-picker',{attrs:{"full-width":"","format":"24hr"},on:{"click:minute":function($event){return _vm.$refs.menu.save(
                                                    _vm.getServiceModal
                                                        .realization.endTime
                                                )}},model:{value:(
                                                _vm.getServiceModal.realization
                                                    .endTime
                                            ),callback:function ($$v) {_vm.$set(_vm.getServiceModal.realization
                                                    , "endTime", $$v)},expression:"\n                                                getServiceModal.realization\n                                                    .endTime\n                                            "}}):_vm._e()],1)],1),_c('v-col',{staticClass:"background--total pl-2 py-0 mb-2",attrs:{"cols":"2","md":"2"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"text--settlement"},[_vm._v(_vm._s(_vm.$t( 'service:timeService' )))])])],1),_c('v-row',[_c('v-col',{staticClass:"pr-0 text--settlement",attrs:{"cols":"4"}},[_vm._v(_vm._s(`${_vm.$t('service:h')}:`)+" "),_c('span',[_vm._v(_vm._s(_vm.getServiceTime().h))])]),_c('v-col',{staticClass:"pl-1 text--settlement",attrs:{"cols":"4"}},[_vm._v(_vm._s(`${_vm.$t('service:m')}:`)),_c('span',[_vm._v(_vm._s(_vm.getServiceTime().m))])]),_c('v-col',{staticClass:"pl-0 text--settlement",attrs:{"cols":"4"}},[_vm._v(_vm._s(`${_vm.$t('service:d')}:`)),_c('span',[_vm._v(_vm._s(_vm.getServiceTime().d))])])],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-5",attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('service:timeSettlement')}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"4","md":"4","lg":"4"}},[_c('v-text-field',{attrs:{"value":`${_vm.$get(
                                            _vm.getServiceDetails,
                                            'technician.name',
                                            ''
                                        )} ${_vm.$get(
                                            _vm.getServiceDetails,
                                            'technician.lastname',
                                            ''
                                        )}`,"readonly":"","label":_vm.$t(
                                                'service:nameLastnameTechnic'
                                            )}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4","md":"4","lg":"4"}},[_c('v-select',{attrs:{"clearable":"","items":_vm.cars.map(
                                                e => e.registrationNumber
                                            ),"label":_vm.$t('service:registrationNumber')},on:{"click":_vm.searchCars},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                        on,
                                                        attrs,
                                                    }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(" "+_vm._s(data.item)+" ")])]}}],null,true)},[_c('span',[_vm._v("Model: "+_vm._s(_vm.cars.find( e => e.registrationNumber === data.item ).model))])])]}}]),model:{value:(
                                            _vm.getServiceModal.carRegistrationNumber
                                        ),callback:function ($$v) {_vm.$set(_vm.getServiceModal, "carRegistrationNumber", $$v)},expression:"\n                                            getServiceModal.carRegistrationNumber\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"2","md":"2","lg":"2"}},[_c('v-text-field',{attrs:{"min":0,"readonly":"","label":_vm.$t('service:survey')},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"size":"20","color":"grey"},on:{"click":() => {
                                                        _vm.getServiceModal.realization.survey -= 0.5
                                                        _vm.setImplementationServiceWasChanged(
                                                            true
                                                        )
                                                    }}},[_vm._v("mdi-minus")])]},proxy:true},{key:"append-outer",fn:function(){return [_c('v-icon',{attrs:{"size":"20","color":"grey"},on:{"click":() => {
                                                        _vm.getServiceModal.realization.survey += 0.5
                                                        _vm.setImplementationServiceWasChanged(
                                                            true
                                                        )
                                                    }}},[_vm._v("mdi-plus")])]},proxy:true}]),model:{value:(
                                            _vm.getServiceModal.realization
                                                .survey
                                        ),callback:function ($$v) {_vm.$set(_vm.getServiceModal.realization
                                                , "survey", $$v)},expression:"\n                                            getServiceModal.realization\n                                                .survey\n                                        "}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"2","md":"2","lg":"2"}},[_c('v-text-field',{attrs:{"readonly":"","label":_vm.$t('service:repair')},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"size":"20","color":"grey"},on:{"click":() => {
                                                        _vm.getServiceModal.realization.repair -= 0.5
                                                        _vm.setImplementationServiceWasChanged(
                                                            true
                                                        )
                                                    }}},[_vm._v("mdi-minus")])]},proxy:true},{key:"append-outer",fn:function(){return [_c('v-icon',{attrs:{"size":"20","color":"grey"},on:{"click":() => {
                                                        _vm.getServiceModal.realization.repair += 0.5
                                                        _vm.setImplementationServiceWasChanged(
                                                            true
                                                        )
                                                    }}},[_vm._v("mdi-plus")])]},proxy:true}]),model:{value:(
                                            _vm.getServiceModal.realization
                                                .repair
                                        ),callback:function ($$v) {_vm.$set(_vm.getServiceModal.realization
                                                , "repair", $$v)},expression:"\n                                            getServiceModal.realization\n                                                .repair\n                                        "}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"2","md":"2","lg":"2"}},[_c('v-text-field',{attrs:{"readonly":"","label":_vm.$t('service:implementation')},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"size":"20","color":"grey"},on:{"click":() => {
                                                        _vm.getServiceModal.realization.installation -= 0.5
                                                        _vm.setImplementationServiceWasChanged(
                                                            true
                                                        )
                                                    }}},[_vm._v("mdi-minus")])]},proxy:true},{key:"append-outer",fn:function(){return [_c('v-icon',{attrs:{"size":"20","color":"grey"},on:{"click":() => {
                                                        _vm.getServiceModal.realization.installation += 0.5
                                                        _vm.setImplementationServiceWasChanged(
                                                            true
                                                        )
                                                    }}},[_vm._v("mdi-plus")])]},proxy:true}]),model:{value:(
                                            _vm.getServiceModal.realization
                                                .installation
                                        ),callback:function ($$v) {_vm.$set(_vm.getServiceModal.realization
                                                , "installation", $$v)},expression:"\n                                            getServiceModal.realization\n                                                .installation\n                                        "}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"2","md":"2","lg":"2"}},[_c('v-text-field',{attrs:{"readonly":"","label":_vm.$t('service:diagnostics')},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"size":"20","color":"grey"},on:{"click":() => {
                                                        _vm.getServiceModal.realization.diagnostics -= 0.5
                                                        _vm.setImplementationServiceWasChanged(
                                                            true
                                                        )
                                                    }}},[_vm._v("mdi-minus")])]},proxy:true},{key:"append-outer",fn:function(){return [_c('v-icon',{attrs:{"size":"20","color":"grey"},on:{"click":() => {
                                                        _vm.getServiceModal.realization.diagnostics += 0.5
                                                        _vm.setImplementationServiceWasChanged(
                                                            true
                                                        )
                                                    }}},[_vm._v("mdi-plus")])]},proxy:true}]),model:{value:(
                                            _vm.getServiceModal.realization
                                                .diagnostics
                                        ),callback:function ($$v) {_vm.$set(_vm.getServiceModal.realization
                                                , "diagnostics", $$v)},expression:"\n                                            getServiceModal.realization\n                                                .diagnostics\n                                        "}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"2","md":"2","lg":"2"}},[_c('v-text-field',{attrs:{"readonly":"","label":_vm.$t('service:ItService')},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"size":"20","color":"grey"},on:{"click":() => {
                                                        _vm.getServiceModal.realization.itService -= 0.5
                                                        _vm.setImplementationServiceWasChanged(
                                                            true
                                                        )
                                                    }}},[_vm._v("mdi-minus")])]},proxy:true},{key:"append-outer",fn:function(){return [_c('v-icon',{attrs:{"size":"20","color":"grey"},on:{"click":() => {
                                                        _vm.getServiceModal.realization.itService += 0.5
                                                        _vm.setImplementationServiceWasChanged(
                                                            true
                                                        )
                                                    }}},[_vm._v("mdi-plus")])]},proxy:true}]),model:{value:(
                                            _vm.getServiceModal.realization
                                                .itService
                                        ),callback:function ($$v) {_vm.$set(_vm.getServiceModal.realization
                                                , "itService", $$v)},expression:"\n                                            getServiceModal.realization\n                                                .itService\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4","md":"4","lg":"4"}},[_c('money-field',{attrs:{"readonly":"","label":_vm.$t('service:nettoStandard'),"value":_vm.standardPrice()}})],1),_c('v-col',{attrs:{"cols":"4","md":"4","lg":"4"}},[(
                                            this
                                                .getImplementationServiceWasChanged
                                        )?_c('money-field',{attrs:{"disabled":_vm.canEditPrice(),"label":_vm.$t('service:nettoDedicated'),"value":_vm.dedicatedPrice()},on:{"input":e => _vm.setDedicatedPriceValue(e)}}):_c('money-field',{attrs:{"disabled":_vm.canEditPrice(),"label":_vm.$t('service:nettoDedicated'),"value":this.getServiceModal.realization
                                                .dedicatedPrice},on:{"input":e => _vm.setDedicatedPriceValue(e)}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-5",attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('service:counters')}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"4","md":"4","lg":"4"}},[_c('span',{staticClass:"text--implementation"},[_vm._v(_vm._s(_vm.$t('service:countersMonoDevice')))]),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"6","lg":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t(
                                                        'service:startCounter'
                                                    )},on:{"keypress":_vm.onlyNumber},model:{value:(
                                                    _vm.getServiceModal
                                                        .realization
                                                        .counters
                                                        .monoDeviceStart
                                                ),callback:function ($$v) {_vm.$set(_vm.getServiceModal
                                                        .realization
                                                        .counters
                                                        , "monoDeviceStart", $$v)},expression:"\n                                                    getServiceModal\n                                                        .realization\n                                                        .counters\n                                                        .monoDeviceStart\n                                                "}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"6","lg":"6"}},[_c('v-text-field',{attrs:{"disabled":!_vm.getServiceModal
                                                        .realization
                                                        .counters
                                                        .monoDeviceStart,"label":_vm.$t('service:endCounter'),"error-messages":_vm.getServiceImplementationError.monoDeviceEnd},on:{"keypress":_vm.onlyNumber},model:{value:(
                                                    _vm.getServiceModal
                                                        .realization
                                                        .counters
                                                        .monoDeviceEnd
                                                ),callback:function ($$v) {_vm.$set(_vm.getServiceModal
                                                        .realization
                                                        .counters
                                                        , "monoDeviceEnd", $$v)},expression:"\n                                                    getServiceModal\n                                                        .realization\n                                                        .counters\n                                                        .monoDeviceEnd\n                                                "}})],1)],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"8","md":"8","lg":"8"}},[_c('span',{staticClass:"text--implementation"},[_vm._v(_vm._s(_vm.$t('service:countersColorDevice')))]),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"4","md":"4","lg":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t(
                                                        'service:startCounter'
                                                    )},on:{"keypress":_vm.onlyNumber},model:{value:(
                                                    _vm.getServiceModal
                                                        .realization
                                                        .counters
                                                        .colorDeviceStart
                                                ),callback:function ($$v) {_vm.$set(_vm.getServiceModal
                                                        .realization
                                                        .counters
                                                        , "colorDeviceStart", $$v)},expression:"\n                                                    getServiceModal\n                                                        .realization\n                                                        .counters\n                                                        .colorDeviceStart\n                                                "}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4","md":"4","lg":"4"}},[_c('v-text-field',{attrs:{"disabled":!_vm.getServiceModal
                                                        .realization
                                                        .counters
                                                        .colorDeviceStart,"label":_vm.$t('service:endCounter'),"error-messages":_vm.getServiceImplementationError.colorDeviceEnd},on:{"keypress":_vm.onlyNumber},model:{value:(
                                                    _vm.getServiceModal
                                                        .realization
                                                        .counters
                                                        .colorDeviceEnd
                                                ),callback:function ($$v) {_vm.$set(_vm.getServiceModal
                                                        .realization
                                                        .counters
                                                        , "colorDeviceEnd", $$v)},expression:"\n                                                    getServiceModal\n                                                        .realization\n                                                        .counters\n                                                        .colorDeviceEnd\n                                                "}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4","md":"4","lg":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t('service:scan')},on:{"keypress":_vm.onlyNumber},model:{value:(
                                                    _vm.getServiceModal
                                                        .realization
                                                        .counters.scans
                                                ),callback:function ($$v) {_vm.$set(_vm.getServiceModal
                                                        .realization
                                                        .counters, "scans", $$v)},expression:"\n                                                    getServiceModal\n                                                        .realization\n                                                        .counters.scans\n                                                "}})],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0"},[_c('span',{staticClass:"text--implementation"},[_vm._v(_vm._s(_vm.$t('service:countersCar')))]),_c('v-row',[_c('v-col',{staticClass:"py-0"},[_c('v-text-field',{attrs:{"label":_vm.$t(
                                                        'service:startCounter'
                                                    )},on:{"keypress":_vm.onlyNumber},model:{value:(
                                                    _vm.getServiceModal
                                                        .realization
                                                        .counters.carStart
                                                ),callback:function ($$v) {_vm.$set(_vm.getServiceModal
                                                        .realization
                                                        .counters, "carStart", $$v)},expression:"\n                                                    getServiceModal\n                                                        .realization\n                                                        .counters.carStart\n                                                "}})],1),_c('v-col',{staticClass:"py-0"},[_c('v-text-field',{attrs:{"label":_vm.$t('service:endCounter'),"rules":[
                                                    val =>
                                                        val >=
                                                        _vm.getServiceModal
                                                            .realization
                                                            .counters
                                                            .carStart,
                                                ],"error-messages":_vm.getServiceImplementationError.carEnd},on:{"keypress":_vm.onlyNumber,"keyup":_vm.setTransportCalculate},model:{value:(
                                                    _vm.getServiceModal
                                                        .realization
                                                        .counters.carEnd
                                                ),callback:function ($$v) {_vm.$set(_vm.getServiceModal
                                                        .realization
                                                        .counters, "carEnd", $$v)},expression:"\n                                                    getServiceModal\n                                                        .realization\n                                                        .counters.carEnd\n                                                "}})],1),_c('v-col',{staticClass:"py-0"},[_c('v-text-field',{attrs:{"value":_vm.kmTraveled || 0,"readonly":"","label":_vm.$t('service:km')}})],1),_c('v-col',{staticClass:"py-0"},[_c('money-field',{attrs:{"value":_vm.carsCost,"readonly":"","label":_vm.$t(
                                                        'service:transportationCost'
                                                    ),"error-messages":this
                                                        .rateOneKmTransportError}})],1),_c('v-col',{staticClass:"py-0"},[_c('v-checkbox',{attrs:{"dense":"","label":_vm.$t(
                                                        'service:settleTheTransport'
                                                    )},on:{"click":_vm.settleTheTransportCheckboxClick},model:{value:(
                                                    _vm.getServiceModal.settleTheTransportCheckboxValue
                                                ),callback:function ($$v) {_vm.$set(_vm.getServiceModal, "settleTheTransportCheckboxValue", $$v)},expression:"\n                                                    getServiceModal.settleTheTransportCheckboxValue\n                                                "}})],1)],1)],1)],1)],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-row',[_c('v-col',{staticClass:"pb-5",attrs:{"cols":"6","md":"6"}},[_c('span',{staticClass:"ml-3 text--implementation"},[_vm._v(_vm._s(`${_vm.$t('service:required')}`))]),_c('v-checkbox',{staticClass:"checkbox--implementation",attrs:{"color":"secondary","label":`${_vm.$t(
                                            'service:continuation'
                                        )}`},model:{value:(
                                            _vm.getServiceModal.realization
                                                .requirements.continuation
                                        ),callback:function ($$v) {_vm.$set(_vm.getServiceModal.realization
                                                .requirements, "continuation", $$v)},expression:"\n                                            getServiceModal.realization\n                                                .requirements.continuation\n                                        "}}),_c('v-checkbox',{staticClass:"checkbox--implementation",attrs:{"color":"secondary","label":`${_vm.$t(
                                            'service:valuation'
                                        )}`},model:{value:(
                                            _vm.getServiceModal.realization
                                                .requirements.valuation
                                        ),callback:function ($$v) {_vm.$set(_vm.getServiceModal.realization
                                                .requirements, "valuation", $$v)},expression:"\n                                            getServiceModal.realization\n                                                .requirements.valuation\n                                        "}}),_c('v-checkbox',{staticClass:"checkbox--implementation",attrs:{"color":"secondary","label":`${_vm.$t(
                                            'service:ItService'
                                        )}`},model:{value:(
                                            _vm.getServiceModal.realization
                                                .requirements.itService
                                        ),callback:function ($$v) {_vm.$set(_vm.getServiceModal.realization
                                                .requirements, "itService", $$v)},expression:"\n                                            getServiceModal.realization\n                                                .requirements.itService\n                                        "}}),_c('v-checkbox',{staticClass:"checkbox--implementation",attrs:{"color":"secondary","label":`${_vm.$t('service:claim')}`},model:{value:(
                                            _vm.getServiceModal.realization
                                                .requirements.claim
                                        ),callback:function ($$v) {_vm.$set(_vm.getServiceModal.realization
                                                .requirements, "claim", $$v)},expression:"\n                                            getServiceModal.realization\n                                                .requirements.claim\n                                        "}}),_c('v-checkbox',{staticClass:"checkbox--implementation",attrs:{"color":"secondary","label":`${_vm.$t(
                                            'service:replacementDevice'
                                        )}`},model:{value:(
                                            _vm.getServiceModal.realization
                                                .requirements
                                                .placeholderDevice
                                        ),callback:function ($$v) {_vm.$set(_vm.getServiceModal.realization
                                                .requirements
                                                , "placeholderDevice", $$v)},expression:"\n                                            getServiceModal.realization\n                                                .requirements\n                                                .placeholderDevice\n                                        "}}),_c('v-checkbox',{staticClass:"checkbox--implementation",attrs:{"color":"secondary","label":`${_vm.$t(
                                            'service:expertise'
                                        )}`},model:{value:(
                                            _vm.getServiceModal.realization
                                                .requirements.expertise
                                        ),callback:function ($$v) {_vm.$set(_vm.getServiceModal.realization
                                                .requirements, "expertise", $$v)},expression:"\n                                            getServiceModal.realization\n                                                .requirements.expertise\n                                        "}}),_c('v-checkbox',{staticClass:"checkbox--implementation",attrs:{"color":"secondary","label":`${_vm.$t('service:invoice')}`},model:{value:(
                                            _vm.getServiceModal.realization
                                                .requirements.invoice
                                        ),callback:function ($$v) {_vm.$set(_vm.getServiceModal.realization
                                                .requirements, "invoice", $$v)},expression:"\n                                            getServiceModal.realization\n                                                .requirements.invoice\n                                        "}})],1),_c('v-col',{attrs:{"cols":"6","md":"6"}},[_c('span',{staticClass:"ml-3 text--implementation"},[_vm._v(_vm._s(`${_vm.$t('service:comments')}:`))]),_c('v-checkbox',{staticClass:"checkbox--implementation",attrs:{"color":"secondary","label":`${_vm.$t(
                                            'service:repairWorkshop'
                                        )}`},model:{value:(
                                            _vm.getServiceModal.realization
                                                .requirements.repairWorkshop
                                        ),callback:function ($$v) {_vm.$set(_vm.getServiceModal.realization
                                                .requirements, "repairWorkshop", $$v)},expression:"\n                                            getServiceModal.realization\n                                                .requirements.repairWorkshop\n                                        "}}),_c('v-checkbox',{staticClass:"checkbox--implementation",attrs:{"color":"secondary","label":`${_vm.$t('service:remote')}`},model:{value:(
                                            _vm.getServiceModal.realization
                                                .requirements.remoteService
                                        ),callback:function ($$v) {_vm.$set(_vm.getServiceModal.realization
                                                .requirements, "remoteService", $$v)},expression:"\n                                            getServiceModal.realization\n                                                .requirements.remoteService\n                                        "}}),_c('v-checkbox',{staticClass:"checkbox--implementation",attrs:{"color":"secondary","label":`${_vm.$t(
                                            'service:serviceUnreasonable'
                                        )}`},model:{value:(
                                            _vm.getServiceModal.realization
                                                .requirements
                                                .unjustifiedService
                                        ),callback:function ($$v) {_vm.$set(_vm.getServiceModal.realization
                                                .requirements
                                                , "unjustifiedService", $$v)},expression:"\n                                            getServiceModal.realization\n                                                .requirements\n                                                .unjustifiedService\n                                        "}}),_c('v-checkbox',{staticClass:"checkbox--implementation",attrs:{"color":"secondary","label":`${_vm.$t(
                                            'service:correctOperation'
                                        )}`},model:{value:(
                                            _vm.getServiceModal.realization
                                                .requirements.clientApproval
                                        ),callback:function ($$v) {_vm.$set(_vm.getServiceModal.realization
                                                .requirements, "clientApproval", $$v)},expression:"\n                                            getServiceModal.realization\n                                                .requirements.clientApproval\n                                        "}}),_c('v-checkbox',{staticClass:"checkbox--implementation mt-5 pt-3",attrs:{"color":"secondary","label":`${_vm.$t(
                                            'service:informedCustomer'
                                        )}`},model:{value:(
                                            _vm.getServiceModal.realization
                                                .requirements
                                                .clientCostInformed
                                        ),callback:function ($$v) {_vm.$set(_vm.getServiceModal.realization
                                                .requirements
                                                , "clientCostInformed", $$v)},expression:"\n                                            getServiceModal.realization\n                                                .requirements\n                                                .clientCostInformed\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12","md":"12"}},[_c('v-textarea',{attrs:{"no-resize":"","rows":"1","auto-grow":"","label":`${_vm.$t(
                                            'service:scopeOfIntervention'
                                        )}`},model:{value:(
                                            _vm.getServiceModal.realization
                                                .interventionRange
                                        ),callback:function ($$v) {_vm.$set(_vm.getServiceModal.realization
                                                , "interventionRange", $$v)},expression:"\n                                            getServiceModal.realization\n                                                .interventionRange\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-textarea',{attrs:{"no-resize":"","rows":"1","auto-grow":"","label":`${_vm.$t(
                                            'service:commentsToNextIntervention'
                                        )}`},model:{value:(
                                            _vm.getServiceModal.realization
                                                .technicianComments
                                        ),callback:function ($$v) {_vm.$set(_vm.getServiceModal.realization
                                                , "technicianComments", $$v)},expression:"\n                                            getServiceModal.realization\n                                                .technicianComments\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t(
                                                'service:settlementIntervention'
                                            )}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"6","lg":"6"}},[_c('span',{staticClass:"text--implementation"},[_vm._v(_vm._s(_vm.$t('service:internalSettlement')))]),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"6","lg":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                        on,
                                                        attrs,
                                                    }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.$get(
                                                                _vm.getServiceModal,
                                                                'realization.payment.internalDate',
                                                                false
                                                            )
                                                                ? _vm.$moment(
                                                                      _vm.getServiceModal
                                                                          .realization
                                                                          .payment
                                                                          .internalDate
                                                                  ).format(
                                                                      'DD-MM-YYYY'
                                                                  )
                                                                : '',"clearable":"","label":_vm.$t(
                                                                'service:date'
                                                            )},on:{"click:clear":function($event){_vm.getServiceModal.realization.payment.internalDate = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.internalSettlement),callback:function ($$v) {_vm.internalSettlement=$$v},expression:"internalSettlement"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1"},on:{"input":function($event){_vm.internalSettlement = false}},model:{value:(
                                                        _vm.getServiceModal
                                                            .realization
                                                            .payment
                                                            .internalDate
                                                    ),callback:function ($$v) {_vm.$set(_vm.getServiceModal
                                                            .realization
                                                            .payment
                                                            , "internalDate", $$v)},expression:"\n                                                        getServiceModal\n                                                            .realization\n                                                            .payment\n                                                            .internalDate\n                                                    "}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"6","lg":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('service:docNumber')},model:{value:(
                                                    _vm.getServiceModal
                                                        .realization.payment
                                                        .internalDocumentNumber
                                                ),callback:function ($$v) {_vm.$set(_vm.getServiceModal
                                                        .realization.payment
                                                        , "internalDocumentNumber", $$v)},expression:"\n                                                    getServiceModal\n                                                        .realization.payment\n                                                        .internalDocumentNumber\n                                                "}})],1)],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"6","lg":"6"}},[_c('span',{staticClass:"text--implementation"},[_vm._v(_vm._s(_vm.$t('service:externalSettlement')))]),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"6","lg":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                        on,
                                                        attrs,
                                                    }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.$get(
                                                                _vm.getServiceModal,
                                                                'invoiceDate',
                                                                false
                                                            )
                                                                ? _vm.$moment(
                                                                      _vm.getServiceModal.invoiceDate
                                                                  ).format(
                                                                      'DD-MM-YYYY'
                                                                  )
                                                                : '',"clearable":"","label":_vm.$t(
                                                                'service:date'
                                                            )},on:{"click:clear":function($event){_vm.getServiceModal.invoiceDate = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.externalSettlement),callback:function ($$v) {_vm.externalSettlement=$$v},expression:"externalSettlement"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1"},on:{"input":function($event){_vm.externalSettlement = false}},model:{value:(
                                                        _vm.getServiceModal.invoiceDate
                                                    ),callback:function ($$v) {_vm.$set(_vm.getServiceModal, "invoiceDate", $$v)},expression:"\n                                                        getServiceModal.invoiceDate\n                                                    "}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"6","lg":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('service:docNumber')},model:{value:(
                                                    _vm.getServiceModal.invoiceNumber
                                                ),callback:function ($$v) {_vm.$set(_vm.getServiceModal, "invoiceNumber", $$v)},expression:"\n                                                    getServiceModal.invoiceNumber\n                                                "}})],1)],1)],1)],1)],1),_c('v-stepper-content',{attrs:{"step":"3"}},[_c('v-row',{staticClass:"px-2"},[_c('v-col',{staticClass:"pb-5",attrs:{"cols":"6","md":"6"}},[_c('span',{staticClass:"ml-3 text--implementation"},[_vm._v(_vm._s(`${_vm.$t( 'service:transportSettlement' )}`))]),_c('v-checkbox',{staticClass:"checkbox--implementation",attrs:{"color":"secondary","label":`${_vm.$t(
                                            'service:settleFromTheBaseAutomatically'
                                        )}`,"checked":""},model:{value:(
                                            _vm.getServiceModal.transportConfig
                                                .settleFromTheBaseAutomatically
                                        ),callback:function ($$v) {_vm.$set(_vm.getServiceModal.transportConfig
                                                , "settleFromTheBaseAutomatically", $$v)},expression:"\n                                            getServiceModal.transportConfig\n                                                .settleFromTheBaseAutomatically\n                                        "}}),_c('v-checkbox',{staticClass:"checkbox--implementation",attrs:{"color":"secondary","label":`${_vm.$t(
                                            'service:settleReturn'
                                        )}`},on:{"click":_vm.setTransportCalculate},model:{value:(
                                            _vm.getServiceModal.transportConfig
                                                .settleReturn
                                        ),callback:function ($$v) {_vm.$set(_vm.getServiceModal.transportConfig
                                                , "settleReturn", $$v)},expression:"\n                                            getServiceModal.transportConfig\n                                                .settleReturn\n                                        "}})],1),_c('v-col',{staticClass:"pb-5",attrs:{"cols":"6","md":"6"}},[_c('div',{staticClass:"transportConfigErrors"},[(
                                                _vm.getServiceImplementationError.minRateTransport
                                            )?_c('div',[_vm._v(" "+_vm._s(_vm.$t( 'service:minimalRateError' ))+" ")]):_vm._e(),(
                                                _vm.getServiceImplementationError.rateOneKmTransport
                                            )?_c('div',[_vm._v(" "+_vm._s(_vm.$t('service:oneKmError'))+" ")]):_vm._e(),(
                                                _vm.getServiceImplementationError.staticRateTransport
                                            )?_c('div',[_vm._v(" "+_vm._s(_vm.$t( 'service:staticRateError' ))+" ")]):_vm._e()]),_c('v-col',[_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                        on,
                                                    }){return [_c('v-icon',_vm._g({staticClass:"transportInfoIcon"},on),[_vm._v("mdi-information")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t( 'service:transportInfoIcon' )))])])],1)])],1)],1),_c('v-row',{staticClass:"px-5"},[_c('v-col',{staticClass:"py-2",attrs:{"cols":"4","md":"4","lg":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t(
                                                'service:kilometersToBeSettled'
                                            ),"disabled":_vm.getServiceModal.transportConfig
                                                .settleFromTheBaseAutomatically},on:{"keyup":_vm.kilometersToBeSettledManualEdit},model:{value:(
                                            _vm.getServiceModal.transportConfig
                                                .kilometersToBeSettled
                                        ),callback:function ($$v) {_vm.$set(_vm.getServiceModal.transportConfig
                                                , "kilometersToBeSettled", $$v)},expression:"\n                                            getServiceModal.transportConfig\n                                                .kilometersToBeSettled\n                                        "}}),_c('div',{staticClass:"setByManual"},[(
                                                !_vm.getServiceModal
                                                    .transportConfig
                                                    .settleFromTheBaseAutomatically
                                            )?_c('span',[_vm._v(_vm._s('Ustawiono ręcznie ilość kilometrów.'))]):_vm._e()])],1)],1),_c('v-row',{staticClass:"px-5"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"4","md":"4","lg":"4"}},[_c('v-select',{attrs:{"items":_vm.typeOfKm,"label":_vm.$t(
                                                'service:typeOfAccountingKilometers'
                                            )},on:{"change":_vm.setTransportCalculate},model:{value:(
                                            _vm.getServiceModal.transportConfig
                                                .typeOfAccountingKilometers
                                        ),callback:function ($$v) {_vm.$set(_vm.getServiceModal.transportConfig
                                                , "typeOfAccountingKilometers", $$v)},expression:"\n                                            getServiceModal.transportConfig\n                                                .typeOfAccountingKilometers\n                                        "}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4","md":"4","lg":"4"}},[_c('div',[_c('money-field',{attrs:{"label":_vm.$t(
                                                    'service:transportCostToBeSettled'
                                                )},model:{value:(
                                                _vm.getServiceModal
                                                    .transportConfig
                                                    .transportCostToBeSettled
                                            ),callback:function ($$v) {_vm.$set(_vm.getServiceModal
                                                    .transportConfig
                                                    , "transportCostToBeSettled", $$v)},expression:"\n                                                getServiceModal\n                                                    .transportConfig\n                                                    .transportCostToBeSettled\n                                            "}}),(_vm.minRateTransportSet)?_c('div',{staticClass:"minRateTransportTrue"},[_vm._v(" "+_vm._s(_vm.$t( 'service:settledMinimumRate' ))+" ")]):_vm._e(),(_vm.staticRateTransportSet)?_c('div',{staticClass:"minRateTransportTrue"},[_vm._v(" "+_vm._s(_vm.$t( 'service:settledStaticRate' ))+" ")]):_vm._e(),(_vm.lessThanOwnTransportCost)?_c('div',{staticClass:"transportConfigErrors"},[_vm._v(" "+_vm._s(_vm.$t( 'service:settledCostLessThanOwn' ))+" ")]):_vm._e()],1)])],1)],1)],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }