var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xl":"12","lg":"12","md":"12","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-data-table',{staticClass:"serviceParts__modal",attrs:{"height":this.isTablet() ? '262' : '262',"fixed-header":"","mobile-breakpoint":1100,"hide-default-footer":"","items":_vm.estimatesServices,"headers":_vm.headersList},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('tbody',_vm._l((_vm.estimatesServices),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(item.field)+" ")]),_c('td',[_vm._v(_vm._s(item.quantity)+" h")]),_c('td',[_c('money-field',{attrs:{"readonly":""},model:{value:(item.price),callback:function ($$v) {_vm.$set(item, "price", $$v)},expression:"item.price"}})],1),_c('td',[_c('money-field',{attrs:{"value":_vm.setSum(
                                                    item.price,
                                                    item.quantity
                                                ),"readonly":""}})],1),(_vm.mode === 'edit')?_c('td',[_c('div',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"#003399"},on:{"click":function($event){return _vm.editItem(item, index)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":"","color":"#e60000"},on:{"click":function($event){return _vm.deleteItem(item, index)}}},[_vm._v("mdi-delete")])],1)]):_vm._e()])}),0)]},proxy:true},{key:"top",fn:function(){return [_c('v-dialog',{staticStyle:{"z-index":"999"},attrs:{"width":"400px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Informacje")])]),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('money-field',{attrs:{"label":"Cena"},model:{value:(_vm.editInfo.price),callback:function ($$v) {_vm.$set(_vm.editInfo, "price", $$v)},expression:"editInfo.price"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-text-field',{attrs:{"min":0,"label":"Ilość"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"size":"20","color":"grey"},on:{"click":function($event){return _vm.halfValue(
                                                                    'MINUS'
                                                                )}}},[_vm._v("mdi-minus")])]},proxy:true},{key:"append-outer",fn:function(){return [_c('v-icon',{attrs:{"size":"20","color":"grey"},on:{"click":function($event){return _vm.halfValue(
                                                                    'ADD'
                                                                )}}},[_vm._v("mdi-plus")])]},proxy:true}]),model:{value:(
                                                        _vm.editInfo.quantity
                                                    ),callback:function ($$v) {_vm.$set(_vm.editInfo, "quantity", $$v)},expression:"\n                                                        editInfo.quantity\n                                                    "}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"buttons mr-3",on:{"click":_vm.closeDialog}},[_vm._v("Anuluj")]),_c('v-btn',{staticClass:"buttons buttons--add",attrs:{"dark":""},on:{"click":_vm.saveExtraModal}},[_vm._v("Zapisz")])],1)],1)],1)]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-end totalSum"},[_vm._v(" "+_vm._s(_vm.$t('service:total').toUpperCase())+": "),_c('span'),_c('money',_vm._b({staticClass:"parts--money",attrs:{"readonly":"","value":_vm.setTotalSum(_vm.estimatesServices)}},'money',_vm.money,false)),_c('span',[_vm._v(" + 23% VAT")])],1)])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }