<template>
    <v-container>
        <v-row class="details__card">
            <v-col cols="4" xl="4" lg="4" md="12" sm="12" class="px-5 pb-0">
                <v-row>
                    <v-col cols="12">
                        <Separator :text="$t('service:SubjectService')">
                            <div slot="other">
                                <v-tooltip
                                    v-if="
                                        warranty(
                                            $get(
                                                this.getServiceDetails,
                                                'device'
                                            )
                                        )
                                    "
                                    top
                                >
                                    <template
                                        v-slot:activator="{ on: tooltip }"
                                    >
                                        <v-avatar
                                            size="25"
                                            dark
                                            v-on="{ ...tooltip }"
                                            class="cursor-pointer mt-4 monitoring--device"
                                            color="rgb(150, 75, 132)"
                                        >
                                            <span class="white--text">G</span>
                                        </v-avatar>
                                    </template>
                                    <span>{{ $t('devices:isWarranty') }}</span>
                                </v-tooltip>
                                <v-tooltip
                                    v-if="
                                        $get(
                                            getServiceDetails,
                                            'device.monitoringDevice',
                                            ''
                                        )
                                    "
                                    top
                                >
                                    <template
                                        v-slot:activator="{ on: tooltip }"
                                    >
                                        <v-avatar
                                            size="25"
                                            dark
                                            v-on="{ ...tooltip }"
                                            class="cursor-pointer mt-4 monitoring--device"
                                            color="blue"
                                        >
                                            <span class="white--text">M</span>
                                        </v-avatar>
                                    </template>
                                    <span>{{
                                        $t('devices:monitoringDevice')
                                    }}</span>
                                </v-tooltip>
                                <v-tooltip
                                    top
                                    v-if="
                                        getServiceDetails.device.owner.field ===
                                        'Dzierżawa Sharp + click'
                                    "
                                >
                                    <template
                                        v-slot:activator="{ on: tooltip }"
                                    >
                                        <v-avatar
                                            size="25"
                                            dark
                                            v-on="{ ...tooltip }"
                                            class="cursor-pointer mt-4 monitoring--device"
                                            color="red"
                                        >
                                            <span class="white--text">S</span>
                                        </v-avatar>
                                    </template>
                                    <span>{{ $t('devices:sharpRent') }}</span>
                                </v-tooltip>
                            </div>
                        </Separator>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            dense
                            :value="
                                $get(
                                    getServiceDetails,
                                    'device.model.deviceType',
                                    ''
                                )
                            "
                            :label="`${$t('service:type')}`"
                            readonly
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            dense
                            v-if="
                                !getServiceDetails.device.identificationNumber
                            "
                            :value="`${$get(
                                getServiceDetails,
                                'device.model.manufacturer',
                                ''
                            )} ${$get(
                                getServiceDetails,
                                'device.model.model'
                            )} • ${$get(getServiceDetails, 'device.sn')}`"
                            :label="`${$t(
                                'service:manufacturer-model-sn'
                            )}, ID`"
                            readonly
                        >
                            <template v-slot:append-outer>
                                <v-btn
                                    v-if="
                                        $get(
                                            getServiceDetails,
                                            'device.model.model'
                                        )
                                    "
                                    icon
                                    small
                                    router
                                    @click="goTo"
                                >
                                    <v-icon color="secondary" size="18"
                                        >mdi-arrow-right-thick</v-icon
                                    >
                                </v-btn>
                            </template>
                        </v-text-field>
                        <v-text-field
                            dense
                            v-if="getServiceDetails.device.identificationNumber"
                            :value="`${$get(
                                getServiceDetails,
                                'device.model.manufacturer',
                                ''
                            )} ${$get(
                                getServiceDetails,
                                'device.model.model'
                            )} • ${$get(
                                getServiceDetails,
                                'device.sn'
                            )} • ${$get(
                                getServiceDetails,
                                'device.identificationNumber'
                            )}`"
                            :label="`${$t(
                                'service:manufacturer-model-sn'
                            )}, ID`"
                            readonly
                        >
                            <template v-slot:append-outer>
                                <v-btn
                                    v-if="
                                        $get(
                                            getServiceDetails,
                                            'device.model.model'
                                        )
                                    "
                                    icon
                                    small
                                    router
                                    @click="goTo"
                                >
                                    <v-icon color="secondary" size="18"
                                        >mdi-arrow-right-thick</v-icon
                                    >
                                </v-btn>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            dense
                            :value="
                                $get(
                                    getServiceDetails,
                                    'device.serviceBase.field',
                                    ''
                                )
                            "
                            :label="`${$t('service:serviceBasic')}`"
                            readonly
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-text-field
                            dense
                            :value="`${$get(
                                getServiceDetails,
                                'device.priority.field',
                                ''
                            )}`"
                            :label="`${$t('service:priority')}`"
                            readonly
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-on="on"
                                    dense
                                    :value="`${$get(
                                        getServiceDetails,
                                        'device.model.servicePrice.name',
                                        ''
                                    )}`"
                                    :label="`${$t('service:service-prices')}`"
                                    readonly
                                ></v-text-field>
                            </template>
                            <span>{{ prices }}</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <Separator
                            :text="$t('service:client')"
                            :clientID="
                                $get(
                                    getServiceDetails,
                                    'device.client.contactId',
                                    ''
                                )
                            "
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            dense
                            :value="
                                $get(
                                    getServiceDetails,
                                    'device.client.name',
                                    ''
                                )
                            "
                            :label="`${$t('service:client')}`"
                            readonly
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="7">
                        <v-text-field
                            dense
                            :value="$get(location, 'name', '')"
                            :label="`${$t('service:location')}`"
                            readonly
                        >
                            <template slot="append">
                                <template>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                icon
                                                @click="
                                                    $emit(
                                                        'view-map',
                                                        location.location
                                                    )
                                                "
                                                small
                                                v-on="on"
                                            >
                                                <v-icon color="error" size="18"
                                                    >mdi-map-marker</v-icon
                                                >
                                            </v-btn>
                                        </template>
                                        <span>{{
                                            `${$t('service:location')}`
                                        }}</span>
                                    </v-tooltip>
                                </template>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="5">
                        <v-text-field
                            dense
                            :value="$get(location, 'phoneNumber', '')"
                            :label="`${$t('service:phoneNumber')}`"
                            readonly
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        v-if="
                            !$get(
                                getServiceDetails,
                                'createdByHelpDeskUsers',
                                ''
                            )
                        "
                        cols="12"
                    >
                        <v-text-field
                            dense
                            :value="`${$get(
                                getServiceDetails,
                                'contractor.name',
                                ''
                            )} ${$get(
                                getServiceDetails,
                                'contractor.lastname',
                                ''
                            )} • ${$get(
                                getServiceDetails,
                                'contractor.email',
                                ''
                            )} • ${$get(
                                getServiceDetails,
                                'contractor.phoneNumber',
                                ''
                            )} `"
                            :label="`${$t('service:personContracts')}`"
                            readonly
                        ></v-text-field>
                    </v-col>
                    <v-col
                        v-if="
                            $get(
                                getServiceDetails,
                                'createdByHelpDeskUsers',
                                ''
                            )
                        "
                        cols="12"
                    >
                        <v-text-field
                            dense
                            :value="`${$get(
                                getServiceDetails,
                                'createdByHelpDeskUsers.name',
                                ''
                            )} ${$get(
                                getServiceDetails,
                                'createdByHelpDeskUsers.lastName',
                                ''
                            )} • ${$get(
                                getServiceDetails,
                                'createdByHelpDeskUsers.login',
                                ''
                            )} • ${$get(
                                getServiceDetails,
                                'createdByHelpDeskUsers.phoneNumber',
                                ''
                            )} `"
                            :label="`${$t('service:personContracts')}`"
                            readonly
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            dense
                            :value="`${$get(location, 'address', '')}, ${$get(
                                location,
                                'postCode',
                                ''
                            )} ${$get(location, 'city', '')} ${
                                $get(location, 'floor', '')
                                    ? '/ ' + $get(location, 'floor', '')
                                    : ''
                            }`"
                            :label="`${$t('service:locationDetails')}`"
                            readonly
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="4" xl="4" lg="4" md="12" sm="12" class="px-5 pb-0">
                <v-row>
                    <v-col cols="12">
                        <Separator :text="$t('service:scopeService')" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-textarea
                            :value="getServiceDetails.clientDescription"
                            dense
                            rows="2"
                            auto-grow
                            no-resize
                            :label="`${$t('service:customerDescription')}`"
                            readonly
                        ></v-textarea>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-textarea
                            :value="getServiceDetails.logisticianDescription"
                            dense
                            rows="2"
                            auto-grow
                            no-resize
                            :label="`${$t('service:logisticDescription')}`"
                            readonly
                        ></v-textarea>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-textarea
                            dense
                            :value="`Index ostatniej interwencji - ${$get(
                                getPrevIntervention,
                                'index',
                                'Brak'
                            )} - ${$get(
                                getPrevIntervention,
                                'realization.technicianComments',
                                ''
                            )}`"
                            rows="2"
                            auto-grow
                            no-resize
                            :label="`${$t('service:lastInterventionComments')}`"
                            readonly
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="4" xl="4" lg="4" md="12" sm="12" class="pr-5">
                <v-row>
                    <v-col cols="12">
                        <Separator :text="$t('service:serviceScheduling')" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="5">
                        <v-text-field
                            dense
                            :value="`${$get(
                                getServiceDetails,
                                'preferredTechnician.name',
                                ''
                            )} ${$get(
                                getServiceDetails,
                                'preferredTechnician.lastname',
                                ''
                            )}`"
                            :label="`${$t('service:preferredTechnic')}`"
                            readonly
                        ></v-text-field>
                    </v-col>
                    <v-col cols="5">
                        <v-text-field
                            dense
                            :value="`${
                                getServiceDetails.expectedServiceDate
                                    ? $moment(
                                          getServiceDetails.expectedServiceDate
                                      ).format('DD-MM-YYYY')
                                    : '-'
                            } ${
                                getServiceDetails.expectedServiceTime
                                    ? getServiceDetails.expectedServiceTime
                                    : ''
                            }`"
                            :label="`${$t('service:expectedServiceDate')}`"
                            readonly
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="5">
                        <v-text-field
                            dense
                            :value="`${$get(
                                getServiceDetails,
                                'technician.name',
                                ''
                            )} ${$get(
                                getServiceDetails,
                                'technician.lastname',
                                ''
                            )}`"
                            :label="`${$t('service:realizingTechnic')}`"
                            readonly
                        ></v-text-field>
                    </v-col>
                    <v-col cols="5">
                        <v-text-field
                            dense
                            :value="`${
                                getServiceDetails.plannedServiceDate
                                    ? $moment(
                                          getServiceDetails.plannedServiceDate
                                      ).format('DD-MM-YYYY')
                                    : '-'
                            } ${
                                getServiceDetails.plannedServiceTime
                                    ? getServiceDetails.plannedServiceTime
                                    : ''
                            }`"
                            :label="`${$t('service:plannedServiceDate')}`"
                            readonly
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <!-- <v-col cols="12" class="py-0 ma-0">
                        <span class="grey--text">{{
                            `${$t('service:servicesPerformed')}`
                        }}</span></v-col
                    > -->

                    <v-col cols="4" xl="4" lg="6" class="pt-0">
                        <v-checkbox
                            :input-value="
                                $get(
                                    getServiceDetails,
                                    'realizationCheckboxes.survey',
                                    false
                                )
                            "
                            class="checkbox--implementation"
                            color="secondary"
                            readonly
                            :label="`${$t('service:survey')}`"
                        ></v-checkbox>
                        <v-checkbox
                            :input-value="
                                $get(
                                    getServiceDetails,
                                    'realizationCheckboxes.repair',
                                    false
                                )
                            "
                            class="checkbox--implementation"
                            color="secondary"
                            readonly
                            :label="`${$t('service:repair')}`"
                        ></v-checkbox>
                        <v-checkbox
                            :input-value="
                                $get(
                                    getServiceDetails,
                                    'realizationCheckboxes.installation',
                                    false
                                )
                            "
                            class="checkbox--implementation"
                            color="secondary"
                            readonly
                            :label="`${$t('service:implementation')}`"
                        ></v-checkbox>
                        <v-checkbox
                            :input-value="
                                $get(
                                    getServiceDetails,
                                    'realizationCheckboxes.diagnostics',
                                    false
                                )
                            "
                            class="checkbox--implementation"
                            color="secondary"
                            readonly
                            :label="`${$t('service:diagnostics')}`"
                        ></v-checkbox>
                        <v-checkbox
                            :input-value="
                                $get(
                                    getServiceDetails,
                                    'realizationCheckboxes.itService',
                                    false
                                )
                            "
                            class="checkbox--implementation"
                            color="secondary"
                            readonly
                            :label="`${$t('service:ItService')}`"
                        ></v-checkbox>
                    </v-col>
                    <v-col cols="4" xl="4" lg="6" class="pa-0">
                        <v-checkbox
                            :input-value="
                                $get(
                                    getServiceDetails,
                                    'realizationCheckboxes.firstInstallation',
                                    false
                                )
                            "
                            class="checkbox--implementation"
                            color="secondary"
                            readonly
                            :label="`${$t('service:firstInstallation')}`"
                        ></v-checkbox>
                        <v-checkbox
                            :input-value="
                                $get(
                                    getServiceDetails,
                                    'realizationCheckboxes.repairWorkshop',
                                    false
                                )
                            "
                            class="checkbox--implementation"
                            color="secondary"
                            readonly
                            :label="`${$t('service:repairWorkshop')}`"
                        ></v-checkbox>
                        <v-checkbox
                            :input-value="
                                $get(
                                    getServiceDetails,
                                    'realizationCheckboxes.remoteService',
                                    false
                                )
                            "
                            class="checkbox--implementation"
                            color="secondary"
                            readonly
                            :label="`${$t('service:remote')}`"
                        ></v-checkbox>
                        <v-checkbox
                            :input-value="
                                $get(
                                    getServiceDetails,
                                    'realizationCheckboxes.reception',
                                    false
                                )
                            "
                            class="checkbox--implementation"
                            color="secondary"
                            readonly
                            :label="`${$t('service:reception')}`"
                        ></v-checkbox>
                        <v-checkbox
                            :input-value="
                                $get(
                                    getServiceDetails,
                                    'realizationCheckboxes.supply',
                                    false
                                )
                            "
                            class="checkbox--implementation"
                            color="secondary"
                            readonly
                            :label="`${$t('service:supply')}`"
                        ></v-checkbox>
                    </v-col>
                    <v-col cols="4" xl="4" lg="6" class="pt-0 pr-0">
                        <v-checkbox
                            :input-value="
                                $get(
                                    getServiceDetails,
                                    'realizationCheckboxes.continuation',
                                    false
                                )
                            "
                            class="checkbox--implementation"
                            color="secondary"
                            readonly
                            :label="`${$t('service:continuation')}`"
                        ></v-checkbox>
                        <v-checkbox
                            :input-value="
                                $get(
                                    getServiceDetails,
                                    'realizationCheckboxes.complaint',
                                    false
                                )
                            "
                            class="checkbox--implementation"
                            color="secondary"
                            readonly
                            :label="`${$t('service:complaint')}`"
                        ></v-checkbox>
                        <v-checkbox
                            :input-value="
                                $get(
                                    getServiceDetails,
                                    'realizationCheckboxes.urgent',
                                    false
                                )
                            "
                            class="checkbox--implementation"
                            color="red"
                            readonly
                            :label="`${$t('service:urgent')}`"
                        >
                        </v-checkbox>
                        <v-checkbox
                            :input-value="
                                warranty($get(getServiceDetails, 'device'))
                            "
                            class="checkbox--implementation"
                            color="secondary"
                            readonly
                            :label="`${$t('service:isWarranty')}`"
                        ></v-checkbox>
                        <v-checkbox
                            :input-value="
                                $get(
                                    getServiceDetails,
                                    'realizationCheckboxes.costInformation',
                                    false
                                )
                            "
                            class="checkbox--implementation"
                            color="secondary"
                            readonly
                            :label="`${$t('service:costInformation')}`"
                        ></v-checkbox>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" class="margin--implementation">
                        <Separator :text="$t('service:implemen')" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">
                        <v-text-field
                            dense
                            :value="
                                $get(
                                    getServiceDetails,
                                    'realization.startDate',
                                    ''
                                )
                                    ? $moment(
                                          $get(
                                              getServiceDetails,
                                              'realization.startDate',
                                              ''
                                          )
                                      ).format('DD-MM-YYYY')
                                    : ''
                            "
                            :label="`${$t('service:startService')}`"
                            readonly
                            ><template slot="prepend">
                                <v-icon color="#19aa8d" class="mt-1" size="20"
                                    >mdi-play-circle</v-icon
                                >
                            </template></v-text-field
                        >
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            dense
                            :value="
                                $get(
                                    getServiceDetails,
                                    'realization.endDate',
                                    ''
                                )
                                    ? $moment(
                                          $get(
                                              getServiceDetails,
                                              'realization.endDate',
                                              ''
                                          )
                                      ).format('DD-MM-YYYY')
                                    : ''
                            "
                            :label="`${$t('service:endService')}`"
                            readonly
                        >
                            <template slot="prepend">
                                <v-icon color="red" class="mt-1" size="20"
                                    >mdi-stop-circle</v-icon
                                >
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="4" class="background--total pl-2 py-0 mb-2">
                        <v-row>
                            <v-col cols="12" class="py-0">
                                <span class="text--settlement">{{
                                    $t('service:timeService')
                                }}</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4" class="pr-0 text--settlement"
                                >{{ `${$t('service:h')}:` }}
                                <span>{{ getServiceTime().h }}</span>
                            </v-col>
                            <v-col cols="4" class="pl-1 text--settlement"
                                >{{ `${$t('service:m')}:`
                                }}<span>{{ getServiceTime().m }}</span></v-col
                            >
                            <v-col cols="4" class="pl-0 text--settlement"
                                >{{ `${$t('service:d')}:`
                                }}<span>{{ getServiceTime().d }}</span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import _ from 'lodash'
export default {
    data() {
        return {}
    },
    computed: {
        ...mapGetters(['getServiceDetails', 'getPrevIntervention']),
        location: {
            get() {
                if (!_.isEmpty(this.getServiceDetails.staticDeviceData)) {
                    let location = this.getServiceDetails.staticDeviceData.client.branches.find(
                        el =>
                            el._id ===
                            this.getServiceDetails.staticDeviceData.branch
                    )
                    if (location) {
                        location.floor = this.getServiceDetails?.device?.floor
                    }

                    return location
                } else {
                    let location = this.getServiceDetails.device.client.branches.find(
                        el => el._id === this.getServiceDetails.device.branch
                    )
                    if (location) {
                        location.floor = this.getServiceDetails?.device?.floor
                    }

                    return location
                }
            },
        },
        prices: {
            get() {
                let prices =
                    this.$get(
                        this.getServiceDetails,
                        'device.model.servicePrice'
                    ) || {}
                let str = ''
                str += `${this.$t('service:survey')}: ${Number(
                    this.$get(prices, 'survey.price', 0)
                ).toFixed(2)} PLN / `
                str += `${this.$t('service:repair')}: ${Number(
                    this.$get(prices, 'repair.price', 0)
                ).toFixed(2)} PLN / `
                str += `${this.$t('service:installation')}: ${Number(
                    this.$get(prices, 'installation.price', 0)
                ).toFixed(2)} PLN / `
                str += `${this.$t('service:diagnostics')}: ${Number(
                    this.$get(prices, 'diagnostics.price', 0)
                ).toFixed(2)} PLN / `
                str += `${this.$t('service:itService')}: ${Number(
                    this.$get(prices, 'itService.price', 0)
                ).toFixed(2)} PLN / `
                str += `${this.$t('service:remote')}: ${Number(
                    this.$get(prices, 'remote.price', 0)
                ).toFixed(2)} PLN `
                return str
            },
        },
        address: {
            get() {
                let branches = this.$get(
                    this.getServiceDetails,
                    'device.client.branches',
                    []
                )
                let branch = branches.find(
                    el => el._id === this.getServiceDetails.device.branch
                )
                if (branch)
                    return `${branch.name} ${branch.address} \n ${branch.postCode} ${branch.city}`
                else return ''
            },
        },
    },
    methods: {
        getServiceTime() {
            if (
                !this.getServiceDetails.realization.endDate ||
                !this.getServiceDetails.realization.startDate ||
                !this.getServiceDetails.realization.endTime ||
                !this.getServiceDetails.realization.startTime
            )
                return { h: '--', m: '--', d: '--' }
            let end = moment(
                `${this.getServiceDetails.realization.endDate.split('T')[0]}T${
                    this.getServiceDetails.realization.endTime
                }:00`
            )
            let start = moment(
                `${
                    this.getServiceDetails.realization.startDate.split('T')[0]
                }T${this.getServiceDetails.realization.startTime}:00`
            )
            let result = moment.duration(end.diff(start))
            return {
                h: result._data.hours,
                m: result._data.minutes,
                d: result._data.days,
            }
        },
        goTo() {
            this.$router.push('/devices/' + this.getServiceDetails.device._id)
        },

        warranty(item) {
            return (
                (item.guaranteeTo &&
                    this.$moment(item.guaranteeTo).isSameOrAfter(
                        new Date(),
                        'days'
                    )) ||
                (item.partGuarantee &&
                    this.$moment(item.partGuarantee).isSameOrAfter(
                        new Date(),
                        'days'
                    ))
            )
        },
    },
}
</script>
<style lang="sass" scoped>
.checkbox--implementation
    height: 20px
    margin-top: 10px
    margin-left: 0px
.margin--implementation
    margin-top: 160px
.summaryLine--font
    font-size: 13px
.monitoring--device
    margin-top: -2px !important
    margin-left: 5px !important
    z-index: 2 !important

.background--total
    background-color: #eaecd1
    font-size: 12px
    margin-top: -10px
    padding: 2px 5px !important
</style>
