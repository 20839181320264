var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"12"}},[_c('v-stepper',{staticClass:"elevation-0",model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"editable":"","step":"1"}},[_vm._v(_vm._s(_vm.$t('service:confirmService')))]),_c('v-divider')],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('div',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"mt-3 mr-5 clientConfirmService"},[_vm._v(" "+_vm._s(`${_vm.$t( 'service:clientConfirmService' )}:`)+" ")]),_c('v-radio-group',{staticClass:"ml-5",attrs:{"row":""},model:{value:(
                                        _vm.getServiceModal.realization
                                            .signature.confirmService
                                    ),callback:function ($$v) {_vm.$set(_vm.getServiceModal.realization
                                            .signature, "confirmService", $$v)},expression:"\n                                        getServiceModal.realization\n                                            .signature.confirmService\n                                    "}},[_c('v-radio',{attrs:{"label":_vm.$t('global:yes'),"value":true}}),_c('v-radio',{attrs:{"label":_vm.$t('global:no'),"value":false}})],1)],1),_c('div',{staticClass:"date-generate"},[(!_vm.isSignatureDateAvaliable)?_c('p',[_vm._v(" Generuj datę i czas wykonania usługi: ")]):_vm._e(),(_vm.isSignatureDateAvaliable)?_c('p',[_vm._v(" Data i czas wykonania usługi: ")]):_vm._e(),(!_vm.isSignatureDateAvaliable)?_c('v-btn',{staticClass:"generate-button",attrs:{"color":"buttons--add"},on:{"click":_vm.generateSignatureData}},[_vm._v(" Generuj ")]):_vm._e(),(_vm.isSignatureDateAvaliable)?_c('v-btn',{staticClass:"generate-button generate-button--disabled",attrs:{"disabled":_vm.isSignatureDateAvaliable,"color":"buttons--add"},on:{"click":_vm.generateSignatureData}},[_vm._v(_vm._s(_vm.signatureDate || _vm.tempDate))]):_vm._e()],1),_c('div',{staticClass:"ma-3 d-flex justify-center",attrs:{"id":"app"}},[_c('VueSignaturePad',{ref:"signaturePad",staticStyle:{"border":"1px solid red"},attrs:{"options":{
                                        onBegin: _vm.onBegin,
                                        onEnd: _vm.onEnd,
                                    },"saveType":"image/png","width":"600px","height":!this.isTablet() ? '300px' : '150px'}})],1),(
                                    !this.isTablet() &&
                                    _vm.$get(
                                        _vm.getServiceModal,
                                        'realization.signature.signature.data',
                                        ''
                                    )
                                )?_c('img',{staticClass:"signature--image",attrs:{"src":_vm.getServiceModal.realization.signature
                                        .signature.data,"alt":"entelli logo"}}):_vm._e(),(
                                    this.isTablet() &&
                                    _vm.$get(
                                        _vm.getServiceModal,
                                        'realization.signature.signature.data',
                                        ''
                                    )
                                )?_c('img',{staticClass:"signature--image--mobile",attrs:{"src":_vm.getServiceModal.realization.signature
                                        .signature.data,"alt":"entelli logo"}}):_vm._e()])],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }