<template>
    <div>
        <v-tooltip
            v-if="
                $get(
                    getServiceDetails,
                    'realization.requirements.continuation',
                    false
                )
            "
            left
        >
            <template v-slot:activator="{ on }">
                <v-btn
                    fab
                    v-on="on"
                    small
                    :disabled="
                        $get(
                            getServiceDetails,
                            'realization.requirementsCompleted.continuation',
                            false
                        )
                    "
                    @click="$emit('continuation')"
                    class="buttons--add mr-2"
                >
                    K
                </v-btn>
            </template>
            <span>{{ $t('service:continuation') }}</span>
        </v-tooltip>
        <v-tooltip left>
            <template v-slot:activator="{ on }">
                <v-btn
                    fab
                    dark
                    v-on="on"
                    small
                    @click="$emit('show-service-order')"
                    class="buttons--add"
                >
                    <v-icon small>mdi-note-multiple-outline</v-icon>
                </v-btn>
            </template>
            <span>{{ $t('service:showServiceOrder') }}</span>
        </v-tooltip>
        <v-tooltip left>
            <template v-slot:activator="{ on }">
                <v-btn
                    fab
                    dark
                    v-on="on"
                    small
                    @click="$emit('show-service-card')"
                    class="buttons--add mx-2"
                >
                    <v-icon small>mdi-file-outline</v-icon>
                </v-btn>
            </template>
            <span>{{ $t('service:showCardOrder') }}</span>
        </v-tooltip>

        <v-tooltip left>
            <template v-slot:activator="{ on }">
                <v-btn
                    fab
                    dark
                    v-on="on"
                    v-if="edit()"
                    small
                    @click="$emit('edit-service')"
                    class="buttons--add margin--1"
                >
                    <v-icon small>mdi-pencil</v-icon>
                </v-btn>
            </template>
            <span>{{ $t('service:editService') }}</span>
        </v-tooltip>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    data() {
        return {}
    },
    computed: {
        ...mapGetters(['getServiceDetails']),
    },
    methods: {
        edit() {
            return this.checkPermissions('UPDATE')
        },
    },
}
</script>
