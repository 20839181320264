<template>
    <div class="pa-5 confirmModal">
        <v-row>
            <v-col cols="12">
                <div class="d-flex justify-center py-5">
                    <img
                        width="100"
                        height="100"
                        src="@/assets/img/global/warning.svg"
                        alt="warning"
                    />
                </div>

                <v-card-title class="d-flex justify-center pa-2">{{
                    $t('service:confirm')
                }}</v-card-title>
                <v-card-text class="d-flex justify-center pa-2">{{
                    confirmText
                }}</v-card-text>
                <v-row>
                    <v-col cols="6" v-if="type == 'invoice'">
                        <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="date"
                                    :label="dateLabel"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                first-day-of-week="1"
                                @change="$emit('confirm-date', date)"
                                v-model="date"
                                @input="menu = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col
                        cols="6"
                        v-if="type == 'invoice' || type == 'claim'"
                        :offset="type != 'invoice' ? '3' : '0'"
                    >
                        <v-text-field
                            :label="dataLabel"
                            :error-messages="error"
                            ref="search"
                            v-model="text"
                            @keyup="
                                $emit('confirm-number', $event.target.value)
                            "
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    props: {
        type: {
            type: String,
        },
        error: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            confirmType: this.type,
            menu: false,
            text: '',
            date: '',
        }
    },
    methods: {
        clearConfirmModal() {
            this.text = ''
            this.date = ''
        },
    },
    computed: {
        confirmText: {
            get(type) {
                if (type._props.type == 'valuation')
                    return `${this.$t('service:confirmValuation')}`
                else if (type._props.type == 'expertise')
                    return `${this.$t('service:confirmExpertise')}`
                else if (type._props.type == 'continuation')
                    return `${this.$t('service:confirmContinuation')}`
                else if (type._props.type == 'claim')
                    return `${this.$t('service:confirmClaim')}`
                else if (type._props.type == 'invoice')
                    return `${this.$t('service:confirmInvoice')}`
                else if (type._props.type == 'price')
                    return `${this.$t('service:confirmPrice')}`
            },
        },
        dateLabel: {
            get(type) {
                if (type._props.type == 'invoice') {
                    return `${this.$t('service:reckoningDateOuter')}`
                }
            },
        },
        dataLabel: {
            get(type) {
                if (type._props.type == 'invoice')
                    return `${this.$t('service:documentNumber')}`
                else if (type._props.type == 'claim')
                    return `${this.$t('service:claimNumber')}`
            },
        },
    },
    mounted() {
        this.$root.$on('clearModal', () => {
            this.clearConfirmModal()
        })
    },
}
</script>
