<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" sm="12" class="pa-0">
                <v-stepper v-model="step" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step editable step="1">{{
                                $t('estimates:services')
                            }}</v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step editable step="2">{{
                                $t('estimates:parts')
                            }}</v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step editable step="3">{{
                                $t('estimates:transport')
                            }}</v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step editable step="4">{{
                                $t('estimates:commentsAndDeadline')
                            }}</v-stepper-step>
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1"
                                ><step-services-component
                            /></v-stepper-content>
                            <v-stepper-content step="2"
                                ><step-parts-component
                            /></v-stepper-content>
                            <v-stepper-content step="3"
                                ><step-transport-component
                            /></v-stepper-content>
                            <v-stepper-content step="4"
                                ><step-comments-component
                            /></v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import StepPartsComponent from './StepParts/StepPartsComponent.vue'
import StepTransportComponent from './StepTransport/StepTransportComponent.vue'
import StepCommentsComponent from './StepComments/StepCommentsComponent.vue'
import StepServicesComponent from './StepServices/StepServicesComponent.vue'

export default {
    props: ['modalRef'],
    data: () => ({
        step: 1,
    }),
    components: {
        StepPartsComponent,
        StepTransportComponent,
        StepCommentsComponent,
        StepServicesComponent,
    },
}
</script>
<style scoped lang="scss"></style>
