var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{staticClass:"details__card"},[_c('v-col',{staticClass:"pa-5",attrs:{"cols":"9","xl":"9","lg":"9","md":"12","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('service:partsTally')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-data-table',{staticClass:"serviceParts__table mb-5",attrs:{"fixed-header":"","mobile-breakpoint":1100,"height":"calc(100vh - 500px)","hide-default-footer":"","search":_vm.search,"headers":_vm.headers},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('tbody',_vm._l((_vm.getServicePartsDetails.partsTable),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(item.manufacturerIndex))]),_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(" "+_vm._s(item.substitute ? 'Tak' : 'Nie')+" ")]),_c('td',[_vm._v(_vm._s(item.price))]),_c('td',[_vm._v(_vm._s(item.quantity))]),_c('td',[_c('v-checkbox',{attrs:{"dense":"","readonly":"","color":"secondary","ripple":false},model:{value:(item.wasUsed),callback:function ($$v) {_vm.$set(item, "wasUsed", $$v)},expression:"item.wasUsed"}})],1),_c('td',[_vm._v(" "+_vm._s(_vm.setSum( item.price, item.quantity ))+" ")])])}),0)]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"d-flex justify-end totalSum"},[_c('span',[_vm._v(_vm._s(_vm.$t('service:wasUsedTotal').toUpperCase())+": "),_c('money',_vm._b({staticClass:"parts--money",attrs:{"readonly":"","value":_vm.getServicePartsDetails.sum || 0}},'money',_vm.money,false))],1),_c('span',[_vm._v(" + 23% VAT")])]),_c('div',{staticClass:"d-flex justify-end totalSum"},[_vm._v(" "+_vm._s(_vm.$t('service:total').toUpperCase())+": "),_c('span'),_c('money',_vm._b({staticClass:"parts--money",attrs:{"readonly":"","value":_vm.setTotalSum(
                                    _vm.getServicePartsDetails.partsTable
                                )}},'money',_vm.money,false)),_c('span',[_vm._v(" + 23% VAT")])],1),(
                            _vm.setTotalSumWithDiscount(
                                _vm.getServicePartsDetails.partsTable
                            )
                        )?_c('div',{staticClass:"d-flex justify-end totalSum"},[_vm._v(" "+_vm._s(_vm.$t( 'service:totalSumWithDiscount' ).toUpperCase())+": "),_c('span'),_c('money',_vm._b({staticClass:"parts--money",attrs:{"readonly":"","value":_vm.setTotalSumWithDiscount(
                                    _vm.getServicePartsDetails.partsTable
                                )}},'money',_vm.money,false)),_c('span',[_vm._v(" + 23% VAT")])],1):_vm._e()])],1)],1),_c('v-col',{staticClass:"pa-5",attrs:{"cols":"3","xl":"3","lg":"3","md":"12","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('service:partReckoning')}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mb-5",attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"readonly":"","dense":"","value":_vm.getServicePartsDetails.soldPartsInvoiceNumber ||
                            '',"label":_vm.$t('service:partFVNumver')}})],1),_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"readonly":"","dense":"","value":_vm.setDate(
                                _vm.getServicePartsDetails.internalPaymentDate
                            ),"label":_vm.$t('service:reckoningDateInner')}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"readonly":"","dense":"","label":_vm.$t('service:documentNumberInner')},model:{value:(
                            _vm.getServicePartsDetails.internalDocumentNumber
                        ),callback:function ($$v) {_vm.$set(_vm.getServicePartsDetails, "internalDocumentNumber", $$v)},expression:"\n                            getServicePartsDetails.internalDocumentNumber\n                        "}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"readonly":"","dense":"","value":_vm.setDate(
                                _vm.getServicePartsDetails.externalPaymentDate
                            ),"label":_vm.$t('service:reckoningDateOuter')}})],1),_c('v-col',{staticClass:"mb-5",attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"readonly":"","dense":"","label":_vm.$t('service:documentNumberOuter')},model:{value:(
                            _vm.getServicePartsDetails.externalDocumentNumber
                        ),callback:function ($$v) {_vm.$set(_vm.getServicePartsDetails, "externalDocumentNumber", $$v)},expression:"\n                            getServicePartsDetails.externalDocumentNumber\n                        "}})],1),_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"readonly":"","dense":"","label":_vm.$t('service:comments')},model:{value:(_vm.getServicePartsDetails.description),callback:function ($$v) {_vm.$set(_vm.getServicePartsDetails, "description", $$v)},expression:"getServicePartsDetails.description"}})],1)],1)],1)],1),_c('Buttons')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }