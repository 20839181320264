var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"ml-3",attrs:{"cols":"3","md":"3","lg":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({
                        on,
                        attrs,
                    }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.$get(
                                _vm.getEstimatesComments,
                                'estimateValidTo',
                                ''
                            )
                                ? _vm.$moment(
                                      _vm.getEstimatesComments.estimateValidTo
                                  ).format('DD-MM-YYYY')
                                : '',"clearable":"","label":_vm.$t('estimates:estimateValidTo')},on:{"click:clear":function($event){_vm.getEstimatesComments.estimateValidTo = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.estimateValidToPicker),callback:function ($$v) {_vm.estimateValidToPicker=$$v},expression:"estimateValidToPicker"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","min":new Date()
                                                    .toISOString()
                                                    .substr(0, 10)},on:{"input":function($event){_vm.estimateValidToPicker = false}},model:{value:(_vm.getEstimatesComments.estimateValidTo),callback:function ($$v) {_vm.$set(_vm.getEstimatesComments, "estimateValidTo", $$v)},expression:"getEstimatesComments.estimateValidTo"}})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"ml-3",attrs:{"cols":"7","md":"7","lg":"7"}},[_c('v-text-field',{attrs:{"label":_vm.$t('estimates:estimateAdditionalContent')},model:{value:(_vm.getEstimatesComments.estimateAdditionalContent),callback:function ($$v) {_vm.$set(_vm.getEstimatesComments, "estimateAdditionalContent", $$v)},expression:"getEstimatesComments.estimateAdditionalContent"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-10"})],1),_c('v-row',[_c('v-col',{staticClass:"mt-10"})],1),_c('v-row',[_c('v-col',{staticClass:"mt-12"})],1),_c('v-row',[_c('v-col',{staticClass:"ml-3",attrs:{"cols":"12","md":"12","lg":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('estimates:remarks')},model:{value:(_vm.getEstimatesComments.remarks),callback:function ($$v) {_vm.$set(_vm.getEstimatesComments, "remarks", $$v)},expression:"getEstimatesComments.remarks"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }