<template>
    <div>
        <TableDetails
            @sort="setSort"
            @search="search"
            :headers="headers"
            :page="getServiceArchivesTable.page"
            :items="getServiceArchivesTable.items"
            :length="getServiceArchivesTable.pages"
            @change-page="setPage"
        >
            <Columns slot="columns" :items="getServiceArchivesTable.items" />
        </TableDetails>
    </div>
</template>
<script></script>
<script>
import Columns from './Details/Columns'
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
    i18nOptions: { namespaces: ['devices'] },
    computed: {
        ...mapGetters(['getServiceArchivesTable', 'getServiceDetails']),
    },
    methods: {
        ...mapMutations(['setServiceArchivesTable']),
        ...mapActions(['fetchArchiveServices']),
        search(value) {
            this.setServiceArchivesTable({ search: value })
            this.fetchArchiveServices({ id: this.getServiceDetails.device._id })
        },
        setSort(sort) {
            this.setServiceArchivesTable({ sort })
            this.fetchArchiveServices({ id: this.getServiceDetails.device._id })
        },
        setPage(page) {
            this.setServiceArchivesTable({ page })
            this.fetchArchiveServices({ id: this.getServiceDetails.device._id })
        },
    },
    data() {
        return {
            open: false,
            length: 10,
            headers: [
                {
                    text: this.$t('service:index'),
                    value: 'index',
                    width: '10%',
                },
                {
                    text: this.$t('service:createdAt'),
                    value: 'createdAt',
                    width: '10%',
                },
                {
                    text: this.$t('service:technician'),
                    value: 'technician',
                    width: '15%',
                },
                {
                    text: this.$t('service:contractor'),
                    value: 'contractor',
                    width: '20%',
                },
                {
                    text: this.$t('service:interventionRange'),
                    value: 'interventionRange',
                    width: '24%',
                },
                {
                    text: this.$t('service:technicianComments'),
                    value: 'technicianComments',
                    width: '15%',
                },
                {
                    text: '',
                    value: 'arrow',
                    align: 'right',
                    width: '6%',
                    sortable: false,
                },
            ],
            items: [],
        }
    },

    components: {
        Columns,
    },
}
</script>
<style lang="sass" scoped>
.summary--cost
    position: absolute !important
    right: 30%
    bottom: 50px
</style>
