var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{staticClass:"details__card"},[_c('v-col',{staticClass:"px-5 pb-0",attrs:{"cols":"4","xl":"4","lg":"4","md":"12","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('service:SubjectService')}},[_c('div',{attrs:{"slot":"other"},slot:"other"},[(
                                    _vm.warranty(
                                        _vm.$get(
                                            this.getServiceDetails,
                                            'device'
                                        )
                                    )
                                )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: tooltip }){return [_c('v-avatar',_vm._g({staticClass:"cursor-pointer mt-4 monitoring--device",attrs:{"size":"25","dark":"","color":"rgb(150, 75, 132)"}},{ ...tooltip }),[_c('span',{staticClass:"white--text"},[_vm._v("G")])])]}}],null,false,1982527514)},[_c('span',[_vm._v(_vm._s(_vm.$t('devices:isWarranty')))])]):_vm._e(),(
                                    _vm.$get(
                                        _vm.getServiceDetails,
                                        'device.monitoringDevice',
                                        ''
                                    )
                                )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: tooltip }){return [_c('v-avatar',_vm._g({staticClass:"cursor-pointer mt-4 monitoring--device",attrs:{"size":"25","dark":"","color":"blue"}},{ ...tooltip }),[_c('span',{staticClass:"white--text"},[_vm._v("M")])])]}}],null,false,1338683774)},[_c('span',[_vm._v(_vm._s(_vm.$t('devices:monitoringDevice')))])]):_vm._e(),(
                                    _vm.getServiceDetails.device.owner.field ===
                                    'Dzierżawa Sharp + click'
                                )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: tooltip }){return [_c('v-avatar',_vm._g({staticClass:"cursor-pointer mt-4 monitoring--device",attrs:{"size":"25","dark":"","color":"red"}},{ ...tooltip }),[_c('span',{staticClass:"white--text"},[_vm._v("S")])])]}}],null,false,787539405)},[_c('span',[_vm._v(_vm._s(_vm.$t('devices:sharpRent')))])]):_vm._e()],1)])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.$get(
                                _vm.getServiceDetails,
                                'device.model.deviceType',
                                ''
                            ),"label":`${_vm.$t('service:type')}`,"readonly":""}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(
                            !_vm.getServiceDetails.device.identificationNumber
                        )?_c('v-text-field',{attrs:{"dense":"","value":`${_vm.$get(
                            _vm.getServiceDetails,
                            'device.model.manufacturer',
                            ''
                        )} ${_vm.$get(
                            _vm.getServiceDetails,
                            'device.model.model'
                        )} • ${_vm.$get(_vm.getServiceDetails, 'device.sn')}`,"label":`${_vm.$t(
                            'service:manufacturer-model-sn'
                        )}, ID`,"readonly":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [(
                                    _vm.$get(
                                        _vm.getServiceDetails,
                                        'device.model.model'
                                    )
                                )?_c('v-btn',{attrs:{"icon":"","small":"","router":""},on:{"click":_vm.goTo}},[_c('v-icon',{attrs:{"color":"secondary","size":"18"}},[_vm._v("mdi-arrow-right-thick")])],1):_vm._e()]},proxy:true}],null,false,2503912751)}):_vm._e(),(_vm.getServiceDetails.device.identificationNumber)?_c('v-text-field',{attrs:{"dense":"","value":`${_vm.$get(
                            _vm.getServiceDetails,
                            'device.model.manufacturer',
                            ''
                        )} ${_vm.$get(
                            _vm.getServiceDetails,
                            'device.model.model'
                        )} • ${_vm.$get(
                            _vm.getServiceDetails,
                            'device.sn'
                        )} • ${_vm.$get(
                            _vm.getServiceDetails,
                            'device.identificationNumber'
                        )}`,"label":`${_vm.$t(
                            'service:manufacturer-model-sn'
                        )}, ID`,"readonly":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [(
                                    _vm.$get(
                                        _vm.getServiceDetails,
                                        'device.model.model'
                                    )
                                )?_c('v-btn',{attrs:{"icon":"","small":"","router":""},on:{"click":_vm.goTo}},[_c('v-icon',{attrs:{"color":"secondary","size":"18"}},[_vm._v("mdi-arrow-right-thick")])],1):_vm._e()]},proxy:true}],null,false,2503912751)}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.$get(
                                _vm.getServiceDetails,
                                'device.serviceBase.field',
                                ''
                            ),"label":`${_vm.$t('service:serviceBasic')}`,"readonly":""}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"dense":"","value":`${_vm.$get(
                            _vm.getServiceDetails,
                            'device.priority.field',
                            ''
                        )}`,"label":`${_vm.$t('service:priority')}`,"readonly":""}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-text-field',_vm._g({attrs:{"dense":"","value":`${_vm.$get(
                                    _vm.getServiceDetails,
                                    'device.model.servicePrice.name',
                                    ''
                                )}`,"label":`${_vm.$t('service:service-prices')}`,"readonly":""}},on))]}}])},[_c('span',[_vm._v(_vm._s(_vm.prices))])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('service:client'),"clientID":_vm.$get(
                                _vm.getServiceDetails,
                                'device.client.contactId',
                                ''
                            )}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.$get(
                                _vm.getServiceDetails,
                                'device.client.name',
                                ''
                            ),"label":`${_vm.$t('service:client')}`,"readonly":""}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"7"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.$get(_vm.location, 'name', ''),"label":`${_vm.$t('service:location')}`,"readonly":""}},[_c('template',{slot:"append"},[[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$emit(
                                                    'view-map',
                                                    _vm.location.location
                                                )}}},on),[_c('v-icon',{attrs:{"color":"error","size":"18"}},[_vm._v("mdi-map-marker")])],1)]}}])},[_c('span',[_vm._v(_vm._s(`${_vm.$t('service:location')}`))])])]],2)],2)],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.$get(_vm.location, 'phoneNumber', ''),"label":`${_vm.$t('service:phoneNumber')}`,"readonly":""}})],1)],1),_c('v-row',[(
                        !_vm.$get(
                            _vm.getServiceDetails,
                            'createdByHelpDeskUsers',
                            ''
                        )
                    )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","value":`${_vm.$get(
                            _vm.getServiceDetails,
                            'contractor.name',
                            ''
                        )} ${_vm.$get(
                            _vm.getServiceDetails,
                            'contractor.lastname',
                            ''
                        )} • ${_vm.$get(
                            _vm.getServiceDetails,
                            'contractor.email',
                            ''
                        )} • ${_vm.$get(
                            _vm.getServiceDetails,
                            'contractor.phoneNumber',
                            ''
                        )} `,"label":`${_vm.$t('service:personContracts')}`,"readonly":""}})],1):_vm._e(),(
                        _vm.$get(
                            _vm.getServiceDetails,
                            'createdByHelpDeskUsers',
                            ''
                        )
                    )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","value":`${_vm.$get(
                            _vm.getServiceDetails,
                            'createdByHelpDeskUsers.name',
                            ''
                        )} ${_vm.$get(
                            _vm.getServiceDetails,
                            'createdByHelpDeskUsers.lastName',
                            ''
                        )} • ${_vm.$get(
                            _vm.getServiceDetails,
                            'createdByHelpDeskUsers.login',
                            ''
                        )} • ${_vm.$get(
                            _vm.getServiceDetails,
                            'createdByHelpDeskUsers.phoneNumber',
                            ''
                        )} `,"label":`${_vm.$t('service:personContracts')}`,"readonly":""}})],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","value":`${_vm.$get(_vm.location, 'address', '')}, ${_vm.$get(
                            _vm.location,
                            'postCode',
                            ''
                        )} ${_vm.$get(_vm.location, 'city', '')} ${
                            _vm.$get(_vm.location, 'floor', '')
                                ? '/ ' + _vm.$get(_vm.location, 'floor', '')
                                : ''
                        }`,"label":`${_vm.$t('service:locationDetails')}`,"readonly":""}})],1)],1)],1),_c('v-col',{staticClass:"px-5 pb-0",attrs:{"cols":"4","xl":"4","lg":"4","md":"12","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('service:scopeService')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"value":_vm.getServiceDetails.clientDescription,"dense":"","rows":"2","auto-grow":"","no-resize":"","label":`${_vm.$t('service:customerDescription')}`,"readonly":""}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"value":_vm.getServiceDetails.logisticianDescription,"dense":"","rows":"2","auto-grow":"","no-resize":"","label":`${_vm.$t('service:logisticDescription')}`,"readonly":""}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"dense":"","value":`Index ostatniej interwencji - ${_vm.$get(
                            _vm.getPrevIntervention,
                            'index',
                            'Brak'
                        )} - ${_vm.$get(
                            _vm.getPrevIntervention,
                            'realization.technicianComments',
                            ''
                        )}`,"rows":"2","auto-grow":"","no-resize":"","label":`${_vm.$t('service:lastInterventionComments')}`,"readonly":""}})],1)],1)],1),_c('v-col',{staticClass:"pr-5",attrs:{"cols":"4","xl":"4","lg":"4","md":"12","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('service:serviceScheduling')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"dense":"","value":`${_vm.$get(
                            _vm.getServiceDetails,
                            'preferredTechnician.name',
                            ''
                        )} ${_vm.$get(
                            _vm.getServiceDetails,
                            'preferredTechnician.lastname',
                            ''
                        )}`,"label":`${_vm.$t('service:preferredTechnic')}`,"readonly":""}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"dense":"","value":`${
                            _vm.getServiceDetails.expectedServiceDate
                                ? _vm.$moment(
                                      _vm.getServiceDetails.expectedServiceDate
                                  ).format('DD-MM-YYYY')
                                : '-'
                        } ${
                            _vm.getServiceDetails.expectedServiceTime
                                ? _vm.getServiceDetails.expectedServiceTime
                                : ''
                        }`,"label":`${_vm.$t('service:expectedServiceDate')}`,"readonly":""}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"dense":"","value":`${_vm.$get(
                            _vm.getServiceDetails,
                            'technician.name',
                            ''
                        )} ${_vm.$get(
                            _vm.getServiceDetails,
                            'technician.lastname',
                            ''
                        )}`,"label":`${_vm.$t('service:realizingTechnic')}`,"readonly":""}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"dense":"","value":`${
                            _vm.getServiceDetails.plannedServiceDate
                                ? _vm.$moment(
                                      _vm.getServiceDetails.plannedServiceDate
                                  ).format('DD-MM-YYYY')
                                : '-'
                        } ${
                            _vm.getServiceDetails.plannedServiceTime
                                ? _vm.getServiceDetails.plannedServiceTime
                                : ''
                        }`,"label":`${_vm.$t('service:plannedServiceDate')}`,"readonly":""}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4","xl":"4","lg":"6"}},[_c('v-checkbox',{staticClass:"checkbox--implementation",attrs:{"input-value":_vm.$get(
                                _vm.getServiceDetails,
                                'realizationCheckboxes.survey',
                                false
                            ),"color":"secondary","readonly":"","label":`${_vm.$t('service:survey')}`}}),_c('v-checkbox',{staticClass:"checkbox--implementation",attrs:{"input-value":_vm.$get(
                                _vm.getServiceDetails,
                                'realizationCheckboxes.repair',
                                false
                            ),"color":"secondary","readonly":"","label":`${_vm.$t('service:repair')}`}}),_c('v-checkbox',{staticClass:"checkbox--implementation",attrs:{"input-value":_vm.$get(
                                _vm.getServiceDetails,
                                'realizationCheckboxes.installation',
                                false
                            ),"color":"secondary","readonly":"","label":`${_vm.$t('service:implementation')}`}}),_c('v-checkbox',{staticClass:"checkbox--implementation",attrs:{"input-value":_vm.$get(
                                _vm.getServiceDetails,
                                'realizationCheckboxes.diagnostics',
                                false
                            ),"color":"secondary","readonly":"","label":`${_vm.$t('service:diagnostics')}`}}),_c('v-checkbox',{staticClass:"checkbox--implementation",attrs:{"input-value":_vm.$get(
                                _vm.getServiceDetails,
                                'realizationCheckboxes.itService',
                                false
                            ),"color":"secondary","readonly":"","label":`${_vm.$t('service:ItService')}`}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"4","xl":"4","lg":"6"}},[_c('v-checkbox',{staticClass:"checkbox--implementation",attrs:{"input-value":_vm.$get(
                                _vm.getServiceDetails,
                                'realizationCheckboxes.firstInstallation',
                                false
                            ),"color":"secondary","readonly":"","label":`${_vm.$t('service:firstInstallation')}`}}),_c('v-checkbox',{staticClass:"checkbox--implementation",attrs:{"input-value":_vm.$get(
                                _vm.getServiceDetails,
                                'realizationCheckboxes.repairWorkshop',
                                false
                            ),"color":"secondary","readonly":"","label":`${_vm.$t('service:repairWorkshop')}`}}),_c('v-checkbox',{staticClass:"checkbox--implementation",attrs:{"input-value":_vm.$get(
                                _vm.getServiceDetails,
                                'realizationCheckboxes.remoteService',
                                false
                            ),"color":"secondary","readonly":"","label":`${_vm.$t('service:remote')}`}}),_c('v-checkbox',{staticClass:"checkbox--implementation",attrs:{"input-value":_vm.$get(
                                _vm.getServiceDetails,
                                'realizationCheckboxes.reception',
                                false
                            ),"color":"secondary","readonly":"","label":`${_vm.$t('service:reception')}`}}),_c('v-checkbox',{staticClass:"checkbox--implementation",attrs:{"input-value":_vm.$get(
                                _vm.getServiceDetails,
                                'realizationCheckboxes.supply',
                                false
                            ),"color":"secondary","readonly":"","label":`${_vm.$t('service:supply')}`}})],1),_c('v-col',{staticClass:"pt-0 pr-0",attrs:{"cols":"4","xl":"4","lg":"6"}},[_c('v-checkbox',{staticClass:"checkbox--implementation",attrs:{"input-value":_vm.$get(
                                _vm.getServiceDetails,
                                'realizationCheckboxes.continuation',
                                false
                            ),"color":"secondary","readonly":"","label":`${_vm.$t('service:continuation')}`}}),_c('v-checkbox',{staticClass:"checkbox--implementation",attrs:{"input-value":_vm.$get(
                                _vm.getServiceDetails,
                                'realizationCheckboxes.complaint',
                                false
                            ),"color":"secondary","readonly":"","label":`${_vm.$t('service:complaint')}`}}),_c('v-checkbox',{staticClass:"checkbox--implementation",attrs:{"input-value":_vm.$get(
                                _vm.getServiceDetails,
                                'realizationCheckboxes.urgent',
                                false
                            ),"color":"red","readonly":"","label":`${_vm.$t('service:urgent')}`}}),_c('v-checkbox',{staticClass:"checkbox--implementation",attrs:{"input-value":_vm.warranty(_vm.$get(_vm.getServiceDetails, 'device')),"color":"secondary","readonly":"","label":`${_vm.$t('service:isWarranty')}`}}),_c('v-checkbox',{staticClass:"checkbox--implementation",attrs:{"input-value":_vm.$get(
                                _vm.getServiceDetails,
                                'realizationCheckboxes.costInformation',
                                false
                            ),"color":"secondary","readonly":"","label":`${_vm.$t('service:costInformation')}`}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"margin--implementation",attrs:{"cols":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('service:implemen')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.$get(
                                _vm.getServiceDetails,
                                'realization.startDate',
                                ''
                            )
                                ? _vm.$moment(
                                      _vm.$get(
                                          _vm.getServiceDetails,
                                          'realization.startDate',
                                          ''
                                      )
                                  ).format('DD-MM-YYYY')
                                : '',"label":`${_vm.$t('service:startService')}`,"readonly":""}},[_c('template',{slot:"prepend"},[_c('v-icon',{staticClass:"mt-1",attrs:{"color":"#19aa8d","size":"20"}},[_vm._v("mdi-play-circle")])],1)],2)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.$get(
                                _vm.getServiceDetails,
                                'realization.endDate',
                                ''
                            )
                                ? _vm.$moment(
                                      _vm.$get(
                                          _vm.getServiceDetails,
                                          'realization.endDate',
                                          ''
                                      )
                                  ).format('DD-MM-YYYY')
                                : '',"label":`${_vm.$t('service:endService')}`,"readonly":""}},[_c('template',{slot:"prepend"},[_c('v-icon',{staticClass:"mt-1",attrs:{"color":"red","size":"20"}},[_vm._v("mdi-stop-circle")])],1)],2)],1),_c('v-col',{staticClass:"background--total pl-2 py-0 mb-2",attrs:{"cols":"4"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"text--settlement"},[_vm._v(_vm._s(_vm.$t('service:timeService')))])])],1),_c('v-row',[_c('v-col',{staticClass:"pr-0 text--settlement",attrs:{"cols":"4"}},[_vm._v(_vm._s(`${_vm.$t('service:h')}:`)+" "),_c('span',[_vm._v(_vm._s(_vm.getServiceTime().h))])]),_c('v-col',{staticClass:"pl-1 text--settlement",attrs:{"cols":"4"}},[_vm._v(_vm._s(`${_vm.$t('service:m')}:`)),_c('span',[_vm._v(_vm._s(_vm.getServiceTime().m))])]),_c('v-col',{staticClass:"pl-0 text--settlement",attrs:{"cols":"4"}},[_vm._v(_vm._s(`${_vm.$t('service:d')}:`)),_c('span',[_vm._v(_vm._s(_vm.getServiceTime().d))])])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }