<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="e1" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step :rules="[]" editable step="1">{{
                                $t('service:basicInformations')
                            }}</v-stepper-step>
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <Separator
                                            :text="
                                                $t('service:lookingForParts')
                                            "
                                        />
                                    </v-col>
                                    <v-col cols="12" md="5">
                                        <v-combobox
                                            dense
                                            label="Szukaj"
                                            single-line
                                            hide-details
                                            @click="clear()"
                                            class="mb-3"
                                            v-model="searchedPart"
                                            item-text="manufacturerIndex"
                                            :items="getServicePartsList.items"
                                            @keyup="
                                                searchParts($event.target.value)
                                            "
                                            @input="assignToTable"
                                            return-object
                                        >
                                            <template v-slot:item="{ item }">
                                                {{
                                                    `${item.manufacturerIndex} - ${item.name}`
                                                }}
                                            </template>
                                            <template slot="prepend">
                                                <img
                                                    src="@/assets/img/global/search.png"
                                                    width="16"
                                                    height="16"
                                                    alt
                                                    class="mt-1"
                                                />
                                            </template>
                                        </v-combobox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <v-data-table
                                            class="serviceParts__modal"
                                            :height="
                                                this.isTablet() ? '262' : '362'
                                            "
                                            fixed-header
                                            :mobile-breakpoint="1100"
                                            hide-default-footer
                                            :items="getServicePartsTable"
                                            :headers="headers"
                                        >
                                            <template v-slot:body>
                                                <tbody>
                                                    <tr
                                                        v-for="(item,
                                                        index) in getServicePartsTable"
                                                        :key="index"
                                                    >
                                                        <td>
                                                            {{
                                                                item.manufacturerIndex
                                                            }}
                                                        </td>
                                                        <td>{{ item.name }}</td>
                                                        <td>
                                                            {{
                                                                item.substitute
                                                                    ? 'Tak'
                                                                    : 'Nie'
                                                            }}
                                                        </td>
                                                        <td>
                                                            {{ item.price }}
                                                        </td>
                                                        <td>
                                                            {{
                                                                item.quantity ||
                                                                1
                                                            }}
                                                        </td>
                                                        <td>
                                                            <v-checkbox
                                                                dense
                                                                color="secondary"
                                                                :ripple="false"
                                                                v-model="
                                                                    item.wasUsed
                                                                "
                                                            ></v-checkbox>
                                                        </td>
                                                        <td>
                                                            {{
                                                                setSum(
                                                                    item.price,
                                                                    item.quantity
                                                                )
                                                            }}
                                                        </td>
                                                        <td>
                                                            <div class="d-flex">
                                                                <v-icon
                                                                    small
                                                                    class="mr-2"
                                                                    @click="
                                                                        editPart(
                                                                            item
                                                                        )
                                                                    "
                                                                    >mdi-pencil</v-icon
                                                                >
                                                                <v-icon
                                                                    small
                                                                    @click="
                                                                        deletePart(
                                                                            item
                                                                        )
                                                                    "
                                                                    >mdi-delete</v-icon
                                                                >
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                            <template v-slot:top>
                                                <v-dialog
                                                    v-model="dialog"
                                                    width="400px"
                                                    style="z-index: 999;"
                                                >
                                                    <v-card>
                                                        <v-card-title>
                                                            <span
                                                                class="headline"
                                                                >Informacje</span
                                                            >
                                                        </v-card-title>
                                                        <v-container>
                                                            <v-row>
                                                                <v-col
                                                                    cols="12"
                                                                    sm="4"
                                                                    md="4"
                                                                >
                                                                    <money-field
                                                                        label="Cena"
                                                                        v-model="
                                                                            editInfo.price
                                                                        "
                                                                    ></money-field>
                                                                </v-col>
                                                                <v-col
                                                                    cols="12"
                                                                    sm="4"
                                                                    md="4"
                                                                >
                                                                    <v-text-field
                                                                        v-model="
                                                                            editInfo.quantity
                                                                        "
                                                                        label="Ilość"
                                                                    ></v-text-field>
                                                                </v-col>
                                                                <v-col
                                                                    cols="12"
                                                                    sm="4"
                                                                    md="4"
                                                                >
                                                                    <v-select
                                                                        :items="
                                                                            wasUsedItems
                                                                        "
                                                                        item-text="text"
                                                                        item-value="value"
                                                                        label="Wymienione"
                                                                        v-model="
                                                                            editInfo.wasUsed
                                                                        "
                                                                    ></v-select>
                                                                </v-col>
                                                            </v-row>
                                                        </v-container>

                                                        <v-card-actions>
                                                            <v-spacer></v-spacer>
                                                            <v-btn
                                                                class="buttons mr-3"
                                                                @click="
                                                                    closeDialog
                                                                "
                                                                >Anuluj</v-btn
                                                            >

                                                            <v-btn
                                                                dark
                                                                class="buttons buttons--add"
                                                                @click="
                                                                    saveInfo
                                                                "
                                                                >Zapisz</v-btn
                                                            >
                                                        </v-card-actions>
                                                    </v-card>
                                                </v-dialog>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <Separator
                                            :text="$t('service:partReckoning')"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="4">
                                        <v-text-field
                                            dense
                                            :label="$t('service:partFVNumver')"
                                            v-model="
                                                getServicePartsFields.soldPartsInvoiceNumber
                                            "
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="8">
                                        <v-text-field
                                            dense
                                            :label="$t('service:comments')"
                                            v-model="
                                                getServicePartsFields.description
                                            "
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <!-- <v-text-field
                                            dense
                                            :label="
                                                $t('service:reckoningDateInner')
                                            "
                                            v-model="getServicePartsFields.internalPaymentDate"
                                        ></v-text-field> -->
                                        <v-menu
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                            v-model="reckoningDateInnerCalendar"
                                            ><template
                                                v-slot:activator="{
                                                    on,
                                                    attrs,
                                                }"
                                            >
                                                <v-text-field
                                                    readonly
                                                    dense
                                                    v-model="
                                                        getServicePartsFields.internalPaymentDate
                                                    "
                                                    clearable
                                                    @click:clear="
                                                        getServicePartsFields.internalPaymentDate = null
                                                    "
                                                    :label="`${$t(
                                                        'service:reckoningDateInner'
                                                    )}`"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                </v-text-field>
                                            </template>
                                            <v-date-picker
                                                first-day-of-week="1"
                                                :min="
                                                    new Date()
                                                        .toISOString()
                                                        .substr(0, 10)
                                                "
                                                v-model="
                                                    getServicePartsFields.internalPaymentDate
                                                "
                                                @input="
                                                    reckoningDateInnerCalendar = false
                                                "
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-text-field
                                            dense
                                            :label="
                                                $t(
                                                    'service:documentNumberInner'
                                                )
                                            "
                                            v-model="
                                                getServicePartsFields.internalDocumentNumber
                                            "
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-menu
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                            v-model="reckoningDateOuterCalendar"
                                            ><template
                                                v-slot:activator="{
                                                    on,
                                                    attrs,
                                                }"
                                            >
                                                <v-text-field
                                                    readonly
                                                    dense
                                                    v-model="
                                                        getServicePartsFields.externalPaymentDate
                                                    "
                                                    clearable
                                                    @click:clear="
                                                        getServicePartsFields.externalPaymentDate = null
                                                    "
                                                    :label="`${$t(
                                                        'service:reckoningDateOuter'
                                                    )}`"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                </v-text-field>
                                            </template>
                                            <v-date-picker
                                                first-day-of-week="1"
                                                :min="
                                                    new Date()
                                                        .toISOString()
                                                        .substr(0, 10)
                                                "
                                                v-model="
                                                    getServicePartsFields.externalPaymentDate
                                                "
                                                @input="
                                                    reckoningDateOuterCalendar = false
                                                "
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-text-field
                                            dense
                                            :label="
                                                $t(
                                                    'service:documentNumberOuter'
                                                )
                                            "
                                            v-model="
                                                getServicePartsFields.externalDocumentNumber
                                            "
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    data() {
        return {
            search: '',
            searchedPart: [],
            reckoningDateInnerCalendar: false,
            reckoningDateOuterCalendar: false,
            dialog: false,
            editable: true,
            e1: 1,
            wasUsedItems: [
                { text: this.$t('global:yes'), value: true },
                { text: this.$t('global:no'), value: false },
            ],
            headers: [
                {
                    text: this.$t('service:index'),
                    align: 'left',
                    value: 'manufacturerIndex',
                    width: 80,
                },
                { text: this.$t('global:name'), value: 'name', width: 130 },
                {
                    text: this.$t('service:replacement'),
                    value: 'substitute',
                    width: 110,
                },
                { text: this.$t('service:price'), value: 'price', width: 80 },
                {
                    text: this.$t('service:quantity'),
                    value: 'quantity',
                    width: 80,
                },
                {
                    text: this.$t('service:wasUsed'),
                    value: 'wasUsed',
                    width: 90,
                },
                { text: this.$t('service:total'), value: 'sum', width: 105 },
                { width: 50 },
            ],
            editInfo: {
                id: '',
                quantity: '',
                price: '',
                wasUsed: '',
            },
        }
    },
    methods: {
        ...mapActions(['fetchServiceParts']),
        ...mapMutations([
            'setServicePartsTable',
            'setPartInfo',
            'removeTablePart',
        ]),
        searchParts(search) {
            this.fetchServiceParts({
                search: search,
                searchFields: ['manufacturerIndex'],
            })
        },
        assignToTable(e) {
            this.setServicePartsTable(this.searchedPart)
            this.searchedPart = []
        },
        editPart(el) {
            this.editInfo.id = el._id
            this.editInfo.quantity = el.quantity || 1
            this.editInfo.price = el.price || 0
            this.editInfo.wasUsed = el.wasUsed || false
            this.dialog = true
        },
        deletePart(el) {
            this.removeTablePart(el)
        },
        saveInfo() {
            this.setPartInfo(this.editInfo)
            this.dialog = false
        },
        closeDialog() {
            this.dialog = false
        },
        clear() {
            this.getServicePartsList.items = []
        },
        setSum(price, quantity) {
            let sum = price * quantity
            if (!quantity) {
                sum = price
            } else {
                sum = sum.toFixed(2)
            }
            return sum
        },
        isTablet() {
            if (
                navigator.userAgent.includes('Android') ||
                navigator.userAgent.includes('iPhone') ||
                navigator.userAgent.includes('iPad')
            ) {
                return true
            }
            return false
        },
    },
    computed: {
        ...mapGetters([
            'getServicePartsList',
            'getServicePartsTable',
            'getServicePartsFields',
        ]),
    },
}
</script>

<style lang="sass" scoped>
.totalSum
    margin-top: 5px
    font-size: 14px
    font-weight: 500

.table
    border-bottom: 1px solid grey
</style>
