<template>
    <div>
        <div class="justify-end buttons--details">
            <v-tooltip left>
                <template v-slot:activator="{ on }">
                    <v-btn
                        fab
                        dark
                        v-on="on"
                        small
                        class="mr-3 buttons--add"
                        v-bind:class="{
                            hasBeenClicked: getEstimatesBtns.download.clicked,
                        }"
                        @click="setBtn('download')"
                    >
                        <v-icon size="18">mdi-download</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('estimates:download') }}</span>
            </v-tooltip>
            <v-tooltip left>
                <template v-slot:activator="{ on }">
                    <v-btn
                        fab
                        dark
                        v-on="on"
                        small
                        class="mr-3 buttons--add"
                        v-bind:class="{
                            hasBeenClicked: getEstimatesBtns.print.clicked,
                        }"
                        @click="setBtn('print')"
                    >
                        <v-icon size="18">mdi-printer</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('estimates:print') }}</span>
            </v-tooltip>
            <v-tooltip left>
                <template v-slot:activator="{ onn }">
                    <span v-on="onn">
                        <v-menu
                            v-model="emailMenu"
                            :close-on-content-click="false"
                            :nudge-width="260"
                            offset-x
                            :max-width="440"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    v-bind:class="{
                                        hasBeenClicked:
                                            getEstimatesBtns.email.clicked,
                                    }"
                                    v-on="on"
                                    fab
                                    small
                                    class="mr-3 buttons--add"
                                    icon
                                >
                                    <v-icon size="18">mdi-email-fast</v-icon>
                                </v-btn>
                            </template>

                            <v-card class="pa-4" height="120">
                                <v-combobox
                                    @input.native="
                                        representativeMail =
                                            $event.srcElement.value
                                    "
                                    @input="representativeMail = $event"
                                    dense
                                    return-object
                                    item-value="_id"
                                    :label="$t('service:emailAddress')"
                                    :items="contractors"
                                    ><template v-slot:selection="data">
                                        <div
                                            class="mr-1"
                                            v-if="
                                                !data.item.name &&
                                                !data.item.lastname
                                            "
                                        >
                                            {{ data.item }}
                                        </div>
                                        <div class="mr-1" v-else>
                                            {{
                                                ` ${data.item.name} ${data.item.lastname}`
                                            }}
                                        </div>
                                        <span
                                            class="mr-1"
                                            v-if="data.item.email"
                                        >
                                            {{ ` • ${data.item.email} ` }}
                                        </span>
                                        <span v-if="data.item.phoneNumber">
                                            {{ ` • ${data.item.phoneNumber}` }}
                                        </span>
                                    </template>
                                    <template v-slot:item="data">
                                        <div class="mr-1">
                                            {{
                                                ` ${data.item.name} ${data.item.lastname}`
                                            }}
                                        </div>
                                        <span
                                            class="mr-1"
                                            v-if="data.item.email"
                                        >
                                            {{ ` • ${data.item.email} ` }}
                                        </span>
                                        <span v-if="data.item.phoneNumber">
                                            {{ ` • ${data.item.phoneNumber}` }}
                                        </span></template
                                    >
                                    <template slot="prepend">
                                        <v-icon
                                            color="#19aa8d"
                                            class="mt-1"
                                            size="20"
                                            >mdi-email</v-icon
                                        >
                                    </template>
                                </v-combobox>
                                <v-btn
                                    @click="setBtn('email')"
                                    dark
                                    class="buttons buttons--add no-print"
                                    style="position: absolute; right: 30px;"
                                    >{{ $t('global:send') }}</v-btn
                                >
                            </v-card></v-menu
                        ></span
                    >
                </template>
                <span>{{ $t('estimates:createEstimate') }}</span>
            </v-tooltip>
            <v-tooltip left>
                <template v-slot:activator="{ on }">
                    <v-btn
                        fab
                        dark
                        v-on="on"
                        small
                        class="mr-3 buttons--preview"
                        @click="goToService('preview')"
                    >
                        <v-icon size="18">mdi-eye</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('estimates:previewEstimate') }}</span>
            </v-tooltip>
            <v-tooltip left>
                <template v-slot:activator="{ on }">
                    <v-btn
                        @click="$emit('createEstimateOpenModal')"
                        fab
                        dark
                        v-on="on"
                        small
                        class="buttons--add"
                    >
                        <v-icon size="18">mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('estimates:createEstimate') }}</span>
            </v-tooltip>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import moment from 'moment'
export default {
    data: () => ({
        emailMenu: false,
        representativeMail: '',
    }),

    name: 'EstimateButtonsComponent',
    computed: {
        ...mapGetters([
            'getEstimatesBtns',
            'getServiceDetails',
            'getSettingsDetails',
            'getProfileDetails',
        ]),
        estimatesBtns: {
            get() {
                return this.getEstimatesBtns
            },
        },
        contractors: {
            get() {
                let representatives = this.$get(
                    this.getServiceDetails,
                    'device.client.representatives',
                    []
                )
                return representatives.filter(
                    representative => representative.email
                )
            },
        },
    },
    methods: {
        ...mapMutations(['setSnackbars']),
        ...mapActions([
            'setBtns',
            'updateServiceEstimate',
            'sendMail',
            'setEstimateStatus',
            'fetchSettings',
        ]),
        async setBtn(type) {      
            this.setBtns(type)
            await this.updateServiceEstimate({
                id: this.$route.params.id,
            })
            
            this.emailMenu = false;
            this.goToService(type)
        },

        validateEmail(email) {
            return String(email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                )
        },

        goToService(type) {
            const value = this.representativeMail
            let email = ''
            if (value?.email) {
                email = value.email
            } else {
                email = this.representativeMail
            }
          
            if (type === 'email') {
                if (this.validateEmail(email)) {
                    const userOfficialName = this.getProfileDetails.name
                    const userOfficialLastName = this.getProfileDetails.lastname
                    let routeData = this.$router.resolve({
                        name: 'estimateDocumentGenerator',
                        query: {
                            id: this.$route.params.id,
                            type: type,
                            userOfficialName: userOfficialName,
                            userOfficialLastName: userOfficialLastName,
                            mail: email,
                        },
                    })
                    window.open(routeData.href, '_blank')
                } else {
                    this.setSnackbars({
                        type: 'error',
                        text: `Niepoprawny adres e-mail`,
                    })
                }
            } else {
                let routeData = this.$router.resolve({
                    name: 'estimateDocumentGenerator',
                    query: {
                        id: this.$route.params.id,
                        type: type,
                    },
                })
                window.open(routeData.href, '_blank')
            }
        },
    },
}
</script>
<style style="scss" scoped>
.hasBeenClicked {
    background-color: gray !important;
    opacity: 0.8;
}
</style>
