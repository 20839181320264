<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="getServiceStep.step" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () => getServiceError.stepOne.validation,
                                ]"
                                editable
                                step="1"
                                >{{
                                    $t('service:basicInformations')
                                }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () => getServiceError.stepTwo.validation,
                                ]"
                                editable
                                step="2"
                                >{{
                                    $t('service:detailsInformations')
                                }}</v-stepper-step
                            >

                            <v-divider></v-divider>
                            <v-divider
                                v-if="
                                    getServiceModal.settleTheTransportCheckboxValue
                                "
                            ></v-divider>
                            <v-stepper-step
                                v-if="
                                    getServiceModal.settleTheTransportCheckboxValue
                                "
                                :rules="[
                                    () => getServiceError.stepThree.validation,
                                ]"
                                editable
                                step="3"
                                >{{ $t('service:transport') }}</v-stepper-step
                            >

                            <v-divider
                                v-if="
                                    getServiceModal.settleTheTransportCheckboxValue
                                "
                            ></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-row>
                                    <v-col
                                        cols="3"
                                        class="background--date pl-5 py-0 mb-2"
                                    >
                                        <v-menu
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                            v-model="startDate"
                                            ><template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-text-field
                                                    :value="
                                                        $get(
                                                            getServiceModal,
                                                            'realization.startDate',
                                                            false
                                                        )
                                                            ? $moment(
                                                                  getServiceModal
                                                                      .realization
                                                                      .startDate
                                                              ).format(
                                                                  'DD-MM-YYYY'
                                                              )
                                                            : ''
                                                    "
                                                    clearable
                                                    @click:clear="
                                                        getServiceModal.realization.startDate = null
                                                    "
                                                    :label="`${$t(
                                                        'service:startDate'
                                                    )}`"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    <template v-slot:prepend>
                                                        <v-icon
                                                            @click="
                                                                presentTime(
                                                                    'start'
                                                                )
                                                            "
                                                            size="35"
                                                            color="secondary"
                                                            >mdi-play-circle</v-icon
                                                        >
                                                    </template></v-text-field
                                                >
                                            </template>
                                            <v-date-picker
                                                first-day-of-week="1"
                                                :min="
                                                    new Date()
                                                        .toISOString()
                                                        .substr(0, 10)
                                                "
                                                v-model="
                                                    getServiceModal.realization
                                                        .startDate
                                                "
                                                @input="startDate = false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col
                                        cols="2"
                                        md="2"
                                        class="background--date pl-5 py-0 mb-2"
                                    >
                                        <v-menu
                                            ref="menu2"
                                            v-model="startTime"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            :return-value.sync="
                                                getServiceModal.realization
                                                    .startTime
                                            "
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="290px"
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-text-field
                                                    clearable
                                                    v-model="
                                                        getServiceModal
                                                            .realization
                                                            .startTime
                                                    "
                                                    @click:clear="
                                                        getServiceModal.realization.startTime = null
                                                    "
                                                    :label="`${$t(
                                                        'service:startTime'
                                                    )}`"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-time-picker
                                                v-if="startTime"
                                                v-model="
                                                    getServiceModal.realization
                                                        .startTime
                                                "
                                                full-width
                                                format="24hr"
                                                @click:minute="
                                                    $refs.menu2.save(
                                                        getServiceModal
                                                            .realization
                                                            .startTime
                                                    )
                                                "
                                            ></v-time-picker> </v-menu
                                    ></v-col>
                                    <v-col
                                        cols="3"
                                        md="3"
                                        class="background--date pl-5 py-0 mb-2"
                                    >
                                        <v-menu
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                            v-model="endDate"
                                            ><template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-text-field
                                                    :value="
                                                        $get(
                                                            getServiceModal,
                                                            'realization.endDate',
                                                            false
                                                        )
                                                            ? $moment(
                                                                  getServiceModal
                                                                      .realization
                                                                      .endDate
                                                              ).format(
                                                                  'DD-MM-YYYY'
                                                              )
                                                            : ''
                                                    "
                                                    clearable
                                                    @click:clear="
                                                        getServiceModal.realization.endDate = null
                                                    "
                                                    :label="`${$t(
                                                        'service:endDate'
                                                    )}`"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    :disabled="
                                                        !getServiceModal
                                                            .realization
                                                            .startDate
                                                    "
                                                >
                                                    <template v-slot:prepend>
                                                        <v-icon
                                                            @click="
                                                                presentTime(
                                                                    'stop'
                                                                )
                                                            "
                                                            size="35"
                                                            color="red"
                                                            :disabled="
                                                                !getServiceModal
                                                                    .realization
                                                                    .startDate
                                                            "
                                                            >mdi-stop-circle</v-icon
                                                        >
                                                    </template></v-text-field
                                                >
                                            </template>
                                            <v-date-picker
                                                first-day-of-week="1"
                                                :min="
                                                    new Date()
                                                        .toISOString()
                                                        .substr(0, 10)
                                                "
                                                v-model="
                                                    getServiceModal.realization
                                                        .endDate
                                                "
                                                @input="endDate = false"
                                                :disabled="
                                                    !getServiceModal.realization
                                                        .startDate
                                                "
                                            ></v-date-picker> </v-menu
                                    ></v-col>
                                    <v-col
                                        cols="2"
                                        md="2"
                                        class="background--date pl-5 py-0 mb-2"
                                    >
                                        <v-menu
                                            ref="menu"
                                            v-model="endTime"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            :return-value.sync="
                                                getServiceModal.realization
                                                    .endTime
                                            "
                                            transition="scale-transition"
                                            format="24hr"
                                            offset-y
                                            max-width="290px"
                                            min-width="290px"
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-text-field
                                                    clearable
                                                    v-model="
                                                        getServiceModal
                                                            .realization.endTime
                                                    "
                                                    @click:clear="
                                                        getServiceModal.realization.endTime = null
                                                    "
                                                    :label="`${$t(
                                                        'service:endTime'
                                                    )}`"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    :disabled="
                                                        !getServiceModal
                                                            .realization
                                                            .startDate &&
                                                        !getServiceModal
                                                            .realization
                                                            .startTime
                                                    "
                                                ></v-text-field>
                                            </template>
                                            <v-time-picker
                                                v-if="endTime"
                                                v-model="
                                                    getServiceModal.realization
                                                        .endTime
                                                "
                                                full-width
                                                format="24hr"
                                                @click:minute="
                                                    $refs.menu.save(
                                                        getServiceModal
                                                            .realization.endTime
                                                    )
                                                "
                                            ></v-time-picker> </v-menu
                                    ></v-col>
                                    <v-col
                                        cols="2"
                                        md="2"
                                        class="background--total pl-2 py-0 mb-2"
                                    >
                                        <v-row>
                                            <v-col cols="12" class="py-0">
                                                <span
                                                    class="text--settlement"
                                                    >{{
                                                        $t(
                                                            'service:timeService'
                                                        )
                                                    }}</span
                                                ></v-col
                                            >
                                        </v-row>
                                        <v-row>
                                            <v-col
                                                cols="4"
                                                class="pr-0 text--settlement"
                                                >{{ `${$t('service:h')}:` }}
                                                <span>{{
                                                    getServiceTime().h
                                                }}</span></v-col
                                            >
                                            <v-col
                                                cols="4"
                                                class="pl-1 text--settlement"
                                                >{{ `${$t('service:m')}:`
                                                }}<span>{{
                                                    getServiceTime().m
                                                }}</span></v-col
                                            >
                                            <v-col
                                                cols="4"
                                                class="pl-0 text--settlement"
                                                >{{ `${$t('service:d')}:`
                                                }}<span>{{
                                                    getServiceTime().d
                                                }}</span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-5">
                                        <Separator
                                            :text="$t('service:timeSettlement')"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4" md="4" lg="4" class="py-0">
                                        <v-text-field
                                            :value="`${$get(
                                                getServiceDetails,
                                                'technician.name',
                                                ''
                                            )} ${$get(
                                                getServiceDetails,
                                                'technician.lastname',
                                                ''
                                            )}`"
                                            readonly
                                            :label="
                                                $t(
                                                    'service:nameLastnameTechnic'
                                                )
                                            "
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="4" md="4" lg="4" class="py-0">
                                        <v-select
                                            v-model="
                                                getServiceModal.carRegistrationNumber
                                            "
                                            clearable
                                            @click="searchCars"
                                            :items="
                                                cars.map(
                                                    e => e.registrationNumber
                                                )
                                            "
                                            :label="
                                                $t('service:registrationNumber')
                                            "
                                            ><template v-slot:item="data">
                                                <v-tooltip right
                                                    ><template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs,
                                                        }"
                                                        ><div
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            {{ data.item }}
                                                        </div></template
                                                    ><span
                                                        >Model:
                                                        {{
                                                            cars.find(
                                                                e =>
                                                                    e.registrationNumber ===
                                                                    data.item
                                                            ).model
                                                        }}</span
                                                    ></v-tooltip
                                                ></template
                                            ></v-select
                                        >
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="2" md="2" lg="2" class="py-0">
                                        <v-text-field
                                            v-model="
                                                getServiceModal.realization
                                                    .survey
                                            "
                                            :min="0"
                                            readonly
                                            :label="$t('service:survey')"
                                            ><template v-slot:prepend>
                                                <v-icon
                                                    @click="
                                                        () => {
                                                            getServiceModal.realization.survey -= 0.5
                                                            setImplementationServiceWasChanged(
                                                                true
                                                            )
                                                        }
                                                    "
                                                    size="20"
                                                    color="grey"
                                                    >mdi-minus</v-icon
                                                > </template
                                            ><template v-slot:append-outer>
                                                <v-icon
                                                    @click="
                                                        () => {
                                                            getServiceModal.realization.survey += 0.5
                                                            setImplementationServiceWasChanged(
                                                                true
                                                            )
                                                        }
                                                    "
                                                    size="20"
                                                    color="grey"
                                                    >mdi-plus</v-icon
                                                >
                                            </template></v-text-field
                                        >
                                    </v-col>
                                    <v-col cols="2" md="2" lg="2" class="py-0">
                                        <v-text-field
                                            v-model="
                                                getServiceModal.realization
                                                    .repair
                                            "
                                            readonly
                                            :label="$t('service:repair')"
                                            ><template v-slot:prepend>
                                                <v-icon
                                                    @click="
                                                        () => {
                                                            getServiceModal.realization.repair -= 0.5
                                                            setImplementationServiceWasChanged(
                                                                true
                                                            )
                                                        }
                                                    "
                                                    size="20"
                                                    color="grey"
                                                    >mdi-minus</v-icon
                                                > </template
                                            ><template v-slot:append-outer>
                                                <v-icon
                                                    @click="
                                                        () => {
                                                            getServiceModal.realization.repair += 0.5
                                                            setImplementationServiceWasChanged(
                                                                true
                                                            )
                                                        }
                                                    "
                                                    size="20"
                                                    color="grey"
                                                    >mdi-plus</v-icon
                                                >
                                            </template></v-text-field
                                        >
                                    </v-col>
                                    <v-col cols="2" md="2" lg="2" class="py-0">
                                        <v-text-field
                                            v-model="
                                                getServiceModal.realization
                                                    .installation
                                            "
                                            readonly
                                            :label="
                                                $t('service:implementation')
                                            "
                                            ><template v-slot:prepend>
                                                <v-icon
                                                    @click="
                                                        () => {
                                                            getServiceModal.realization.installation -= 0.5
                                                            setImplementationServiceWasChanged(
                                                                true
                                                            )
                                                        }
                                                    "
                                                    size="20"
                                                    color="grey"
                                                    >mdi-minus</v-icon
                                                > </template
                                            ><template v-slot:append-outer>
                                                <v-icon
                                                    @click="
                                                        () => {
                                                            getServiceModal.realization.installation += 0.5
                                                            setImplementationServiceWasChanged(
                                                                true
                                                            )
                                                        }
                                                    "
                                                    size="20"
                                                    color="grey"
                                                    >mdi-plus</v-icon
                                                >
                                            </template></v-text-field
                                        >
                                    </v-col>
                                    <v-col cols="2" md="2" lg="2" class="py-0">
                                        <v-text-field
                                            v-model="
                                                getServiceModal.realization
                                                    .diagnostics
                                            "
                                            readonly
                                            :label="$t('service:diagnostics')"
                                            ><template v-slot:prepend>
                                                <v-icon
                                                    @click="
                                                        () => {
                                                            getServiceModal.realization.diagnostics -= 0.5
                                                            setImplementationServiceWasChanged(
                                                                true
                                                            )
                                                        }
                                                    "
                                                    size="20"
                                                    color="grey"
                                                    >mdi-minus</v-icon
                                                > </template
                                            ><template v-slot:append-outer>
                                                <v-icon
                                                    @click="
                                                        () => {
                                                            getServiceModal.realization.diagnostics += 0.5
                                                            setImplementationServiceWasChanged(
                                                                true
                                                            )
                                                        }
                                                    "
                                                    size="20"
                                                    color="grey"
                                                    >mdi-plus</v-icon
                                                >
                                            </template></v-text-field
                                        >
                                    </v-col>
                                    <v-col cols="2" md="2" lg="2" class="py-0">
                                        <v-text-field
                                            v-model="
                                                getServiceModal.realization
                                                    .itService
                                            "
                                            readonly
                                            :label="$t('service:ItService')"
                                            ><template v-slot:prepend>
                                                <v-icon
                                                    @click="
                                                        () => {
                                                            getServiceModal.realization.itService -= 0.5
                                                            setImplementationServiceWasChanged(
                                                                true
                                                            )
                                                        }
                                                    "
                                                    size="20"
                                                    color="grey"
                                                    >mdi-minus</v-icon
                                                > </template
                                            ><template v-slot:append-outer>
                                                <v-icon
                                                    @click="
                                                        () => {
                                                            getServiceModal.realization.itService += 0.5
                                                            setImplementationServiceWasChanged(
                                                                true
                                                            )
                                                        }
                                                    "
                                                    size="20"
                                                    color="grey"
                                                    >mdi-plus</v-icon
                                                >
                                            </template></v-text-field
                                        >
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4" md="4" lg="4">
                                        <money-field
                                            readonly
                                            :label="$t('service:nettoStandard')"
                                            :value="standardPrice()"
                                        />
                                    </v-col>
                                    <v-col cols="4" md="4" lg="4">
                                        <money-field
                                            v-if="
                                                this
                                                    .getImplementationServiceWasChanged
                                            "
                                            @input="
                                                e => setDedicatedPriceValue(e)
                                            "
                                            :disabled="canEditPrice()"
                                            :label="
                                                $t('service:nettoDedicated')
                                            "
                                            :value="dedicatedPrice()"
                                        />
                                        <money-field
                                            v-else
                                            @input="
                                                e => setDedicatedPriceValue(e)
                                            "
                                            :disabled="canEditPrice()"
                                            :label="
                                                $t('service:nettoDedicated')
                                            "
                                            :value="
                                                this.getServiceModal.realization
                                                    .dedicatedPrice
                                            "
                                        />
                                    </v-col>
                                    <!-- <v-col cols="4" md="4" lg="4">
                                        <money-field
                                            readonly
                                            :label="
                                                $t('service:transportationCost')
                                            "
                                            v-model="
                                                getServiceModal.realization
                                                    .transportCost
                                            "
                                        >
                                        </money-field>
                                    </v-col> -->
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-5">
                                        <Separator
                                            :text="$t('service:counters')"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4" md="4" lg="4" class="py-0">
                                        <span class="text--implementation">{{
                                            $t('service:countersMonoDevice')
                                        }}</span>
                                        <v-row>
                                            <v-col
                                                cols="6"
                                                md="6"
                                                lg="6"
                                                class="py-0"
                                            >
                                                <v-text-field
                                                    v-model="
                                                        getServiceModal
                                                            .realization
                                                            .counters
                                                            .monoDeviceStart
                                                    "
                                                    @keypress="onlyNumber"
                                                    :label="
                                                        $t(
                                                            'service:startCounter'
                                                        )
                                                    "
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                cols="6"
                                                md="6"
                                                lg="6"
                                                class="py-0"
                                            >
                                                <v-text-field
                                                    :disabled="
                                                        !getServiceModal
                                                            .realization
                                                            .counters
                                                            .monoDeviceStart
                                                    "
                                                    v-model="
                                                        getServiceModal
                                                            .realization
                                                            .counters
                                                            .monoDeviceEnd
                                                    "
                                                    @keypress="onlyNumber"
                                                    :label="
                                                        $t('service:endCounter')
                                                    "
                                                    :error-messages="
                                                        getServiceImplementationError.monoDeviceEnd
                                                    "
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="8" md="8" lg="8" class="py-0">
                                        <span class="text--implementation">{{
                                            $t('service:countersColorDevice')
                                        }}</span>
                                        <v-row>
                                            <v-col
                                                cols="4"
                                                md="4"
                                                lg="4"
                                                class="py-0"
                                            >
                                                <v-text-field
                                                    v-model="
                                                        getServiceModal
                                                            .realization
                                                            .counters
                                                            .colorDeviceStart
                                                    "
                                                    @keypress="onlyNumber"
                                                    :label="
                                                        $t(
                                                            'service:startCounter'
                                                        )
                                                    "
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                cols="4"
                                                md="4"
                                                lg="4"
                                                class="py-0"
                                            >
                                                <v-text-field
                                                    :disabled="
                                                        !getServiceModal
                                                            .realization
                                                            .counters
                                                            .colorDeviceStart
                                                    "
                                                    v-model="
                                                        getServiceModal
                                                            .realization
                                                            .counters
                                                            .colorDeviceEnd
                                                    "
                                                    @keypress="onlyNumber"
                                                    :label="
                                                        $t('service:endCounter')
                                                    "
                                                    :error-messages="
                                                        getServiceImplementationError.colorDeviceEnd
                                                    "
                                                ></v-text-field>
                                            </v-col>

                                            <v-col
                                                cols="4"
                                                md="4"
                                                lg="4"
                                                class="py-0"
                                            >
                                                <v-text-field
                                                    v-model="
                                                        getServiceModal
                                                            .realization
                                                            .counters.scans
                                                    "
                                                    @keypress="onlyNumber"
                                                    :label="$t('service:scan')"
                                                ></v-text-field> </v-col
                                        ></v-row>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="py-0">
                                        <span class="text--implementation">{{
                                            $t('service:countersCar')
                                        }}</span>
                                        <v-row>
                                            <v-col class="py-0">
                                                <v-text-field
                                                    v-model="
                                                        getServiceModal
                                                            .realization
                                                            .counters.carStart
                                                    "
                                                    @keypress="onlyNumber"
                                                    :label="
                                                        $t(
                                                            'service:startCounter'
                                                        )
                                                    "
                                                ></v-text-field>
                                            </v-col>
                                            <v-col class="py-0">
                                                <v-text-field
                                                    v-model="
                                                        getServiceModal
                                                            .realization
                                                            .counters.carEnd
                                                    "
                                                    @keypress="onlyNumber"
                                                    @keyup="
                                                        setTransportCalculate
                                                    "
                                                    :label="
                                                        $t('service:endCounter')
                                                    "
                                                    :rules="[
                                                        val =>
                                                            val >=
                                                            getServiceModal
                                                                .realization
                                                                .counters
                                                                .carStart,
                                                    ]"
                                                    :error-messages="
                                                        getServiceImplementationError.carEnd
                                                    "
                                                ></v-text-field>
                                            </v-col>

                                            <v-col class="py-0">
                                                <v-text-field
                                                    :value="kmTraveled || 0"
                                                    readonly
                                                    :label="$t('service:km')"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col class="py-0">
                                                <money-field
                                                    :value="carsCost"
                                                    readonly
                                                    :label="
                                                        $t(
                                                            'service:transportationCost'
                                                        )
                                                    "
                                                    :error-messages="
                                                        this
                                                            .rateOneKmTransportError
                                                    "
                                                ></money-field>
                                            </v-col>
                                            <v-col class="py-0">
                                                <v-checkbox
                                                    dense
                                                    v-model="
                                                        getServiceModal.settleTheTransportCheckboxValue
                                                    "
                                                    @click="
                                                        settleTheTransportCheckboxClick
                                                    "
                                                    :label="
                                                        $t(
                                                            'service:settleTheTransport'
                                                        )
                                                    "
                                                ></v-checkbox>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                            <v-stepper-content step="2">
                                <v-row>
                                    <v-col cols="6" md="6" class="pb-5">
                                        <span
                                            class="ml-3 text--implementation"
                                            >{{
                                                `${$t('service:required')}`
                                            }}</span
                                        >
                                        <v-checkbox
                                            v-model="
                                                getServiceModal.realization
                                                    .requirements.continuation
                                            "
                                            class="checkbox--implementation"
                                            color="secondary"
                                            :label="`${$t(
                                                'service:continuation'
                                            )}`"
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="
                                                getServiceModal.realization
                                                    .requirements.valuation
                                            "
                                            class="checkbox--implementation"
                                            color="secondary"
                                            :label="`${$t(
                                                'service:valuation'
                                            )}`"
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="
                                                getServiceModal.realization
                                                    .requirements.itService
                                            "
                                            class="checkbox--implementation"
                                            color="secondary"
                                            :label="`${$t(
                                                'service:ItService'
                                            )}`"
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="
                                                getServiceModal.realization
                                                    .requirements.claim
                                            "
                                            class="checkbox--implementation"
                                            color="secondary"
                                            :label="`${$t('service:claim')}`"
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="
                                                getServiceModal.realization
                                                    .requirements
                                                    .placeholderDevice
                                            "
                                            class="checkbox--implementation"
                                            color="secondary"
                                            :label="`${$t(
                                                'service:replacementDevice'
                                            )}`"
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="
                                                getServiceModal.realization
                                                    .requirements.expertise
                                            "
                                            class="checkbox--implementation"
                                            color="secondary"
                                            :label="`${$t(
                                                'service:expertise'
                                            )}`"
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="
                                                getServiceModal.realization
                                                    .requirements.invoice
                                            "
                                            class="checkbox--implementation"
                                            color="secondary"
                                            :label="`${$t('service:invoice')}`"
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col cols="6" md="6">
                                        <span
                                            class="ml-3 text--implementation"
                                            >{{
                                                `${$t('service:comments')}:`
                                            }}</span
                                        >
                                        <v-checkbox
                                            v-model="
                                                getServiceModal.realization
                                                    .requirements.repairWorkshop
                                            "
                                            class="checkbox--implementation"
                                            color="secondary"
                                            :label="`${$t(
                                                'service:repairWorkshop'
                                            )}`"
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="
                                                getServiceModal.realization
                                                    .requirements.remoteService
                                            "
                                            class="checkbox--implementation"
                                            color="secondary"
                                            :label="`${$t('service:remote')}`"
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="
                                                getServiceModal.realization
                                                    .requirements
                                                    .unjustifiedService
                                            "
                                            class="checkbox--implementation"
                                            color="secondary"
                                            :label="`${$t(
                                                'service:serviceUnreasonable'
                                            )}`"
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="
                                                getServiceModal.realization
                                                    .requirements.clientApproval
                                            "
                                            class="checkbox--implementation"
                                            color="secondary"
                                            :label="`${$t(
                                                'service:correctOperation'
                                            )}`"
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="
                                                getServiceModal.realization
                                                    .requirements
                                                    .clientCostInformed
                                            "
                                            class="checkbox--implementation mt-5 pt-3"
                                            color="secondary"
                                            :label="`${$t(
                                                'service:informedCustomer'
                                            )}`"
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="12" class="mt-5">
                                        <v-textarea
                                            no-resize
                                            rows="1"
                                            v-model="
                                                getServiceModal.realization
                                                    .interventionRange
                                            "
                                            auto-grow
                                            :label="`${$t(
                                                'service:scopeOfIntervention'
                                            )}`"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <v-textarea
                                            no-resize
                                            v-model="
                                                getServiceModal.realization
                                                    .technicianComments
                                            "
                                            rows="1"
                                            auto-grow
                                            :label="`${$t(
                                                'service:commentsToNextIntervention'
                                            )}`"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <Separator
                                            :text="
                                                $t(
                                                    'service:settlementIntervention'
                                                )
                                            "
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6" md="6" lg="6" class="py-0">
                                        <span class="text--implementation">{{
                                            $t('service:internalSettlement')
                                        }}</span>
                                        <v-row>
                                            <v-col
                                                cols="6"
                                                md="6"
                                                lg="6"
                                                class="py-0"
                                            >
                                                <v-menu
                                                    :close-on-content-click="
                                                        false
                                                    "
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="290px"
                                                    v-model="internalSettlement"
                                                    ><template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs,
                                                        }"
                                                    >
                                                        <v-text-field
                                                            :value="
                                                                $get(
                                                                    getServiceModal,
                                                                    'realization.payment.internalDate',
                                                                    false
                                                                )
                                                                    ? $moment(
                                                                          getServiceModal
                                                                              .realization
                                                                              .payment
                                                                              .internalDate
                                                                      ).format(
                                                                          'DD-MM-YYYY'
                                                                      )
                                                                    : ''
                                                            "
                                                            clearable
                                                            @click:clear="
                                                                getServiceModal.realization.payment.internalDate = null
                                                            "
                                                            :label="
                                                                $t(
                                                                    'service:date'
                                                                )
                                                            "
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        first-day-of-week="1"
                                                        v-model="
                                                            getServiceModal
                                                                .realization
                                                                .payment
                                                                .internalDate
                                                        "
                                                        @input="
                                                            internalSettlement = false
                                                        "
                                                    ></v-date-picker>
                                                </v-menu>
                                            </v-col>

                                            <v-col
                                                cols="6"
                                                md="6"
                                                lg="6"
                                                class="py-0"
                                            >
                                                <v-text-field
                                                    v-model="
                                                        getServiceModal
                                                            .realization.payment
                                                            .internalDocumentNumber
                                                    "
                                                    :label="
                                                        $t('service:docNumber')
                                                    "
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="6" md="6" lg="6" class="py-0">
                                        <span class="text--implementation">{{
                                            $t('service:externalSettlement')
                                        }}</span>
                                        <v-row>
                                            <v-col
                                                cols="6"
                                                md="6"
                                                lg="6"
                                                class="py-0"
                                            >
                                                <v-menu
                                                    :close-on-content-click="
                                                        false
                                                    "
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="290px"
                                                    v-model="externalSettlement"
                                                    ><template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs,
                                                        }"
                                                    >
                                                        <v-text-field
                                                            :value="
                                                                $get(
                                                                    getServiceModal,
                                                                    'invoiceDate',
                                                                    false
                                                                )
                                                                    ? $moment(
                                                                          getServiceModal.invoiceDate
                                                                      ).format(
                                                                          'DD-MM-YYYY'
                                                                      )
                                                                    : ''
                                                            "
                                                            clearable
                                                            @click:clear="
                                                                getServiceModal.invoiceDate = null
                                                            "
                                                            :label="
                                                                $t(
                                                                    'service:date'
                                                                )
                                                            "
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        first-day-of-week="1"
                                                        v-model="
                                                            getServiceModal.invoiceDate
                                                        "
                                                        @input="
                                                            externalSettlement = false
                                                        "
                                                    ></v-date-picker>
                                                </v-menu>
                                            </v-col>

                                            <v-col
                                                cols="6"
                                                md="6"
                                                lg="6"
                                                class="py-0"
                                            >
                                                <v-text-field
                                                    v-model="
                                                        getServiceModal.invoiceNumber
                                                    "
                                                    :label="
                                                        $t('service:docNumber')
                                                    "
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                            <v-stepper-content step="3">
                                <v-row class="px-2">
                                    <v-col cols="6" md="6" class="pb-5">
                                        <span
                                            class="ml-3 text--implementation"
                                            >{{
                                                `${$t(
                                                    'service:transportSettlement'
                                                )}`
                                            }}</span
                                        >

                                        <v-checkbox
                                            v-model="
                                                getServiceModal.transportConfig
                                                    .settleFromTheBaseAutomatically
                                            "
                                            class="checkbox--implementation"
                                            color="secondary"
                                            :label="`${$t(
                                                'service:settleFromTheBaseAutomatically'
                                            )}`"
                                            checked
                                        ></v-checkbox>

                                        <v-checkbox
                                            v-model="
                                                getServiceModal.transportConfig
                                                    .settleReturn
                                            "
                                            class="checkbox--implementation"
                                            color="secondary"
                                            :label="`${$t(
                                                'service:settleReturn'
                                            )}`"
                                            @click="setTransportCalculate"
                                        ></v-checkbox>
                                        <!-- <v-checkbox
                                            v-model="
                                                getServiceModal.transportConfig
                                                    .settleFromThePreviousPoint
                                            "
                                            class="checkbox--implementation"
                                            color="secondary"
                                            :label="`${$t(
                                                'service:settleFromThePreviousPoint'
                                            )}`"
                                        ></v-checkbox> -->
                                    </v-col>
                                    <v-col cols="6" md="6" class="pb-5">
                                        <div class="transportConfigErrors">
                                            <div
                                                v-if="
                                                    getServiceImplementationError.minRateTransport
                                                "
                                            >
                                                {{
                                                    $t(
                                                        'service:minimalRateError'
                                                    )
                                                }}
                                            </div>
                                            <div
                                                v-if="
                                                    getServiceImplementationError.rateOneKmTransport
                                                "
                                            >
                                                {{ $t('service:oneKmError') }}
                                            </div>
                                            <div
                                                v-if="
                                                    getServiceImplementationError.staticRateTransport
                                                "
                                            >
                                                {{
                                                    $t(
                                                        'service:staticRateError'
                                                    )
                                                }}
                                            </div>
                                        </div>
                                        <v-col>
                                            <div>
                                                <v-tooltip bottom>
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                        }"
                                                    >
                                                        <v-icon
                                                            v-on="on"
                                                            class="transportInfoIcon"
                                                            >mdi-information</v-icon
                                                        >
                                                    </template>
                                                    <span>{{
                                                        $t(
                                                            'service:transportInfoIcon'
                                                        )
                                                    }}</span>
                                                </v-tooltip>
                                            </div>
                                        </v-col>
                                    </v-col>
                                </v-row>

                                <v-row class="px-5">
                                    <v-col cols="4" md="4" lg="4" class="py-2">
                                        <v-text-field
                                            v-model="
                                                getServiceModal.transportConfig
                                                    .kilometersToBeSettled
                                            "
                                            :label="
                                                $t(
                                                    'service:kilometersToBeSettled'
                                                )
                                            "
                                            @keyup="
                                                kilometersToBeSettledManualEdit
                                            "
                                            :disabled="
                                                getServiceModal.transportConfig
                                                    .settleFromTheBaseAutomatically
                                            "
                                        ></v-text-field>
                                        <div class="setByManual">
                                            <span
                                                v-if="
                                                    !getServiceModal
                                                        .transportConfig
                                                        .settleFromTheBaseAutomatically
                                                "
                                                >{{
                                                    'Ustawiono ręcznie ilość kilometrów.'
                                                }}</span
                                            >
                                        </div>
                                    </v-col>
                                    <!-- <v-col
                                        cols="5"
                                        md="5"
                                        class="pt-5 durationTime"
                                    >
                                        <div v-if="this.travelDuration">
                                            {{
                                                $t(
                                                    'service:estimatedTravelTime'
                                                )
                                            }}
                                            {{ this.travelDuration }}
                                        </div>
                                    </v-col> -->
                                </v-row>
                                <v-row class="px-5">
                                    <v-col cols="4" md="4" lg="4" class="py-0">
                                        <v-select
                                            :items="typeOfKm"
                                            :label="
                                                $t(
                                                    'service:typeOfAccountingKilometers'
                                                )
                                            "
                                            v-model="
                                                getServiceModal.transportConfig
                                                    .typeOfAccountingKilometers
                                            "
                                            @change="setTransportCalculate"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="4" md="4" lg="4" class="py-0">
                                        <div>
                                            <money-field
                                                v-model="
                                                    getServiceModal
                                                        .transportConfig
                                                        .transportCostToBeSettled
                                                "
                                                :label="
                                                    $t(
                                                        'service:transportCostToBeSettled'
                                                    )
                                                "
                                            ></money-field>

                                            <div
                                                v-if="minRateTransportSet"
                                                class="minRateTransportTrue"
                                            >
                                                {{
                                                    $t(
                                                        'service:settledMinimumRate'
                                                    )
                                                }}
                                            </div>
                                            <div
                                                v-if="staticRateTransportSet"
                                                class="minRateTransportTrue"
                                            >
                                                {{
                                                    $t(
                                                        'service:settledStaticRate'
                                                    )
                                                }}
                                            </div>
                                            <div
                                                v-if="lessThanOwnTransportCost"
                                                class="transportConfigErrors"
                                            >
                                                {{
                                                    $t(
                                                        'service:settledCostLessThanOwn'
                                                    )
                                                }}
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import moment from 'moment'
import Rest from '../../../../../../plugins/Rest'
export default {
    data() {
        return {
            editable: true,
            internalSettlement: '',
            externalSettlement: '',
            carsCost: '',
            kmTraveled: 0,
            startDate: '',
            endDate: '',
            startTime: '',
            endTime: '',
            date: '',
            date2: '',
            date3: '',
            date4: '',
            time: '',
            time2: '',
            exampleValue: 0,
            e1: 1,
            cars: [],
            rateOneKmTransportError: '',

            typeOfKm: [
                'Stawka za 1 km transportu',
                'Stawka stała za transport',
            ],
            minRateTransportSet: false,
            staticRateTransportSet: false,
            transportConfigErrors: {
                minRateTransport: false,
                rateOneKmTransport: false,
            },
            lessThanOwnTransportCost: false,
            travelDuration: '',
        }
    },
    computed: {
        ...mapGetters([
            'getServiceModal',
            'getServiceDetails',
            'getServiceError',
            'getServiceImplementationError',
            'getProfileDetails',
            'getImplementationServiceWasChanged',
            'getSettingsDetails',
            'getServiceStep',
        ]),
    },

    methods: {
        ...mapActions(['updateService', 'fetchSettings', 'setServiceStep']),
        ...mapMutations([
            'setDedicatedPrice',
            'setImplementationServiceWasChanged',
            'setSnackbars',
            'setKmAndCostInServiceDetails',
        ]),

        getKilometersTraveled(carEnd, carStart) {
            if (carEnd && carStart) {
                return Number(carEnd) - Number(carStart)
            }

            return 0
        },

        kilometersToBeSettledManualEdit() {
            let typeOfAccountingKilometers = this.getServiceModal
                ?.transportConfig?.typeOfAccountingKilometers
            const rateOneKmTransport = this.getServiceDetails?.device.model
                ?.servicePrice?.rateOneKmTransport

            if (typeOfAccountingKilometers === 'Stawka za 1 km transportu') {
                if (rateOneKmTransport) {
                    this.getCalculateOneKmTransport(rateOneKmTransport)
                }
            }

            if (typeOfAccountingKilometers === 'Stawka stała za transport') {
                this.getCalculateStaticRateTransport()
            }
        },

        getCalculateOneKmTransport(rateOneKmTransport) {
            this.getServiceModal.transportConfig.transportCostToBeSettled = (
                this.getServiceModal.transportConfig.kilometersToBeSettled *
                rateOneKmTransport.price
            ).toFixed(2)

            if (
                this.getServiceModal?.transportConfig
                    ?.transportCostToBeSettled <=
                this.getServiceDetails?.device?.model?.servicePrice
                    ?.minRateTransport?.price
            ) {
                this.minRateTransportSet = true
                this.getServiceModal.transportConfig.transportCostToBeSettled = this.getServiceDetails?.device?.model?.servicePrice?.minRateTransport?.price
            }
        },

        getCalculateStaticRateTransport() {
            const staticRateTransport = this.getServiceDetails?.device?.model
                ?.servicePrice?.staticRateTransport?.price

            if (staticRateTransport) {
                this.getServiceModal.transportConfig.transportCostToBeSettled = staticRateTransport.toFixed(
                    2
                )
                this.staticRateTransportSet = true
            }
        },

        setTransportCalculate() {
            this.minRateTransportSet = false
            this.staticRateTransportSet = false
            this.lessThanOwnTransportCost = false
            let kilometers = this.getKilometersTraveled(
                this.getServiceModal.realization.counters.carEnd,
                this.getServiceModal.realization.counters.carStart
            )

            const rateOneKmTransport = this.getServiceDetails?.device.model
                ?.servicePrice?.rateOneKmTransport
            if (
                this.getServiceModal.transportConfig
                    .settleFromTheBaseAutomatically &&
                this.getServiceModal.settleTheTransportCheckboxValue
            ) {
                kilometers = this.getGoogleArrayDataDistance()

                this.carsCost = (
                    this.getKilometersTraveled(
                        this.getServiceModal.realization.counters.carEnd,
                        this.getServiceModal.realization.counters.carStart
                    ) * rateOneKmTransport?.price
                ).toFixed(2)
            } else {
                kilometers = this.getServiceModal.transportConfig
                    .kilometersToBeSettled
                if (rateOneKmTransport) {
                    this.carsCost = (
                        kilometers * rateOneKmTransport?.price
                    ).toFixed(2)
                }
            }

            let typeOfAccountingKilometers = this.getServiceModal
                ?.transportConfig?.typeOfAccountingKilometers

            if (kilometers <= 0) {
                this.kmTraveled = 0
                this.getServiceModal.transportConfig.kilometersToBeSettled = 0
            } else {
                this.kmTraveled = this.getKilometersTraveled(
                    this.getServiceModal.realization.counters.carEnd,
                    this.getServiceModal.realization.counters.carStart
                )

                if (this.getServiceModal?.transportConfig?.settleReturn) {
                    this.getServiceModal.transportConfig.kilometersToBeSettled =
                        kilometers * 2
                } else {
                    this.getServiceModal.transportConfig.kilometersToBeSettled = kilometers
                }

                // if (rateOneKmTransport) {
                //     // this.carsCost = (
                //     //     kilometers * rateOneKmTransport?.price
                //     // ).toFixed(2)
                // }

                if (
                    typeOfAccountingKilometers === 'Stawka za 1 km transportu'
                ) {
                    if (rateOneKmTransport) {
                        this.getCalculateOneKmTransport(rateOneKmTransport)
                    }
                }

                if (
                    typeOfAccountingKilometers === 'Stawka stała za transport'
                ) {
                    this.getCalculateStaticRateTransport()
                }
                const transportCostToBeSettled = this.getServiceModal
                    ?.transportConfig?.transportCostToBeSettled

                if (transportCostToBeSettled && this.carsCost) {
                    if (
                        Number(transportCostToBeSettled) < Number(this.carsCost)
                    ) {
                        this.lessThanOwnTransportCost = true
                    }
                }
            }

            if (!this.getServiceModal.settleTheTransportCheckboxValue) {
                this.carsCost = 0
                this.setKmAndCostInServiceDetails({
                    kilometersToBeSettled: 0,
                    transportCostToBeSettled: 0,
                })
                return
            }

            this.setKmAndCostInServiceDetails({
                kilometersToBeSettled:
                    this.getServiceModal.transportConfig
                        .kilometersToBeSettled || 0,
                transportCostToBeSettled:
                    this.getServiceModal?.transportConfig
                        ?.transportCostToBeSettled || 0,
            })
        },

        setDedicatedPriceValue(value) {
            this.getServiceModal.realization.dedicatedPrice = value
        },
        canEditPrice() {
            const allowedRoles = [
                'koordynatordziałuserwisu',
                'logistykserwisu',
                'dyrektorserwisu',
            ]
            const userRole = this.getProfileDetails.role.name
                .replace(/\s/g, '')
                .toLowerCase()
            if (allowedRoles.includes(userRole)) return false
            return true
        },
        getServiceTime() {
            if (
                !this.getServiceModal.realization.endDate ||
                !this.getServiceModal.realization.startDate ||
                !this.getServiceModal.realization.endTime ||
                !this.getServiceModal.realization.startTime
            )
                return { h: '--', m: '--', d: '--' }
            let end = moment(
                `${this.getServiceModal.realization.endDate}T${this.getServiceModal.realization.endTime}:00`
            )

            let start = moment(
                `${this.getServiceModal.realization.startDate}T${this.getServiceModal.realization.startTime}:00`
            )
            let result = moment.duration(end.diff(start))
            return {
                h: result._data.hours,
                m: result._data.minutes,
                d: result._data.days,
            }
        },
        presentTime(event) {
            const date = moment().format('YYYY-MM-DD')
            // const date = new Date().toISOString()
            const time = moment().format('HH:mm')
            if (event == 'start') {
                this.getServiceModal.realization.startDate = date
                this.getServiceModal.realization.startTime = time
            } else {
                this.getServiceModal.realization.endDate = date
                this.getServiceModal.realization.endTime = time
            }
        },
        onlyNumber($event) {
            if ($event.target.value[0] == 0)
                $event.target.value = $event.target.value.slice(1)
            let keyCode = $event.keyCode ? $event.keyCode : $event.which
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46)
                $event.preventDefault()
        },
        standardPrice() {
            let {
                repair,
                survey,
                itService,
                diagnostics,
                installation,
            } = this.getServiceDetails.device.model.servicePrice
            let sum =
                repair.price * this.getServiceModal.realization.repair +
                survey.price * this.getServiceModal.realization.survey +
                itService.price * this.getServiceModal.realization.itService +
                diagnostics.price *
                    this.getServiceModal.realization.diagnostics +
                installation.price *
                    this.getServiceModal.realization.installation
            this.getServiceModal.realization.standardPrice = sum
            return sum
        },
        dedicatedPrice() {
            let sum = 0
            if (this.getServiceDetails.device.dedicatedPrice) {
                sum +=
                    this.getServiceDetails.device.repair.dedicated *
                    this.getServiceModal.realization.repair
                sum +=
                    this.getServiceDetails.device.survey.dedicated *
                    this.getServiceModal.realization.survey
                sum +=
                    this.getServiceDetails.device.itService.dedicated *
                    this.getServiceModal.realization.itService
                sum +=
                    this.getServiceDetails.device.diagnostics.dedicated *
                    this.getServiceModal.realization.diagnostics
                sum +=
                    this.getServiceDetails.device.installation.dedicated *
                    this.getServiceModal.realization.installation
            }
            return sum
        },
        async searchCars() {
            let response = await new this.Rest('GET', 'cars')
                .select('registrationNumber model')
                .send()
            this.cars = response.data.items
        },

        validate(val, errorField, fields, secondFields) {
            this.getServiceError.stepOne.validation = true
            let value = parseInt(val, 10)
            const text = this.$t('service:implementationCountersError')

            if (
                secondFields &&
                value < this.getServiceModal.realization.counters[secondFields]
            ) {
                this.getServiceImplementationError[errorField] = text
                this.getServiceImplementationError.countersError = true
                this.getServiceError.stepOne.validation = false
            } else if (
                !secondFields &&
                value > this.getServiceModal.realization.counters[fields]
            ) {
                this.getServiceImplementationError[errorField] = text
                this.getServiceImplementationError.countersError = true
                this.getServiceError.stepOne.validation = false
            } else {
                this.getServiceImplementationError[errorField] = ''
                this.getServiceImplementationError.countersError = false
            }
        },
        checkIsTechnicianAssigned() {
            return this.getServiceDetails.status.name === 'Przypisany'
                ? true
                : false
        },
        async settleTheTransportCheckboxClick() {
            if (!this.getServiceModal.settleTheTransportCheckboxValue) {
                this.setTransportCalculate()
                return
            }

            try {
                const result = await this.getGoogleLocationData()
                if (result) {
                    this.getServiceModal.transportConfig.getGoogleMapValue = result

                    // await this.updateService({ id: this.$route.params.id })
                }
            } catch (err) {
                console.log(err)
                throw err
            }

            if (
                this.getServiceModal.transportConfig?.getGoogleMapValue &&
                this.getServiceModal.transportConfig
                    .settleFromTheBaseAutomatically
            ) {
                this.getServiceModal.transportConfig.kilometersToBeSettled = this.getGoogleArrayDataDistance()
                // SETUP DURATION OF DRIVE
                // this.travelDuration = this.getGoogleArrayDataDuration()

                this.setTransportCalculate()
            }

            this.getServiceModal.settleTheTransportCheckboxValue !=
                this.getServiceModal.settleTheTransportCheckboxValue
            if (this.getServiceModal.settleTheTransportCheckboxValue) {
                this.setServiceStep(3)
            }
        },

        startCarCost() {
            // block calculcate if google api is settle

            const rateOneKmTransport =
                this.getServiceDetails?.device.model?.servicePrice
                    ?.rateOneKmTransport || false

            if (!rateOneKmTransport) {
                this.rateOneKmTransportError =
                    'Brak stawki za 1 km dla danego pakietu cenowego'
            }
            let res =
                this.getServiceModal.realization.counters.carEnd -
                this.getServiceModal.realization.counters.carStart

            if (res <= 0) {
                this.carsCost = 0
                this.kmTraveled = 0
                return 0
            } else {
                this.setTransportCalculate()
            }
        },
        async getGoogleLocationData() {
            try {
                //      companyCountry: '',
                // companyCity: '',
                // companyPostCode: '',
                // companyStreet: '',
                // companyHouseNumber: '',
                // companyApartmentNumber: '',
                const getAdressInfoFromBranches = this.getAdressInfoFromBranches()
                await this.fetchSettings({ type: 'add' })

                const companyLoaction = {
                    country: this.getSettingsDetails?.companyCountry,
                    city: this.getSettingsDetails?.companyCity,
                    postCode: this.getSettingsDetails?.companyPostCode,
                    street: this.getSettingsDetails?.companyStreet,
                    houseNumber: this.getSettingsDetails?.companyHouseNumber,
                    apartmentNumber: this.getSettingsDetails
                        ?.companyApartmentNumber,
                }

                const clientLocation = this.createClientAddress(
                    getAdressInfoFromBranches,
                    companyLoaction
                )

                const response = await new Rest('POST', '/getGeoLocation')
                    .setBody({
                        clientLocation,
                        serviceDetails: this.getServiceDetails,
                        companyLoaction,
                    })
                    .send()

                if (response.data.status === 'OK') {
                    return response.data
                } else {
                    return ''
                }
            } catch (e) {
                console.log(e)
            }
        },
        createClientAddress(addressData) {
            let fullAddress = ''
            if (addressData?.address) {
                fullAddress += addressData.address + ' '
            } else {
                fullAddress += ''
            }
            if (addressData?.city) {
                fullAddress += addressData.city + ' '
            } else {
                fullAddress += ''
            }
            if (addressData?.postCode) {
                fullAddress += addressData.postCode
            } else {
                fullAddress += ''
            }

            return fullAddress
        },
        getGoogleArrayDataDuration() {
            if (this.getServiceModal.transportConfig?.getGoogleMapValue) {
                const googleArrayData = this.getServiceModal.transportConfig
                    ?.getGoogleMapValue?.rows[0]?.elements
                const duration = googleArrayData[0].duration.text

                return duration
            }
            return ''
        },
        getGoogleArrayDataDistance() {
            if (this.getServiceModal.transportConfig?.getGoogleMapValue) {
                const googleArrayData = this.getServiceModal.transportConfig
                    ?.getGoogleMapValue?.rows[0]?.elements
                const howManyKmToDrive = parseFloat(
                    googleArrayData[0].distance.text
                        .replace(',', '.')
                        .replace('km', '')
                        .trim()
                )
                return howManyKmToDrive
            }
            return 0
        },

        getAdressInfoFromBranches() {
            let branches = this.$get(
                this.getServiceDetails,
                'device.client.branches',
                []
            )
            let branch = branches.find(
                el => el._id === this.getServiceDetails.device.branch
            )
            if (branch) {
                const [address, city, postCode] = [
                    branch?.address,
                    branch?.city,
                    branch?.postCode,
                ]
                return { address, city, postCode }
            } else return ''
        },
    },
    watch: {
        exampleValue: function (after) {
            if (after <= 0) this.exampleValue = 0
        },

        'getServiceModal.realization.survey': function (val) {
            if (val < 0) this.getServiceModal.realization.survey = 0
        },
        'getServiceModal.realization.repair': function (val) {
            if (val < 0) this.getServiceModal.realization.repair = 0
        },
        'getServiceModal.realization.installation': function (val) {
            if (val < 0) this.getServiceModal.realization.installation = 0
        },
        'getServiceModal.realization.diagnostics': function (val) {
            if (val < 0) this.getServiceModal.realization.diagnostics = 0
        },
        'getServiceModal.realization.itService': function (val) {
            if (val < 0) this.getServiceModal.realization.itService = 0
        },

        'getServiceModal.realization.counters.monoDeviceStart': function (val) {
            if (val === '') {
                this.getServiceModal.realization.counters.monoDeviceEnd = ''
            }
            const validationResult = this.validate(
                val,
                'monoDeviceEnd',
                'monoDeviceEnd'
            )
        },
        'getServiceModal.realization.counters.monoDeviceEnd': function (val) {
            const validationResult = this.validate(
                val,
                'monoDeviceEnd',
                'monoDeviceStart',
                'monoDeviceStart'
            )
            // console.log(validationResult)
        },
        'getServiceModal.realization.counters.colorDeviceStart': function (
            val
        ) {
            if (val === '') {
                this.getServiceModal.realization.counters.colorDeviceEnd = ''
            }
            this.validate(val, 'colorDeviceEnd', 'colorDeviceEnd')
        },
        'getServiceModal.realization.counters.colorDeviceEnd': function (val) {
            this.validate(
                val,
                'colorDeviceEnd',
                'colorDeviceStart',
                'colorDeviceStart'
            )
        },

        'getServiceModal.realization.counters.carEnd': function (val) {
            this.validate(val, 'carEnd', 'carStart', 'carStart')
        },
        'getServiceModal.realization.startDate': function (value) {
            if (!value) {
                this.getServiceModal.realization.endDate = value
                this.getServiceModal.realization.endTime = value
            }

            if (
                !this.checkIsTechnicianAssigned() &&
                this.getServiceModal.realization.startDate
            ) {
                this.getServiceModal.realization.startDate = null
                this.getServiceModal.realization.startTime = null
                this.setSnackbars({
                    type: 'error',
                    text: 'Najpierw przypisz zlecenie',
                })
            }
        },

        'getServiceModal.transportConfig.settleFromTheBaseAutomatically': function (
            val
        ) {
            if (val) {
                this.setTransportCalculate()
                // SETUP DURATION OF DRIVE
                this.travelDuration = this.getGoogleArrayDataDuration()

                this.setTransportCalculate()
            }
            if (!val) {
                this.travelDuration = ''
                this.startCarCost()

                let kilometers = this.getKilometersTraveled(
                    this.getServiceModal.realization.counters.carEnd,
                    this.getServiceModal.realization.counters.carStart
                )

                this.carsCost = (
                    kilometers *
                    this.getServiceDetails?.device.model?.servicePrice
                        ?.rateOneKmTransport.price
                ).toFixed(2)
            }
        },
    },
    async mounted() {
        this.setTransportCalculate()
        await this.searchCars()
    },
}
</script>
<style lang="sass" scoped>
.background--date
    background-color: #f6f7e6
.background--total
    background-color: #eaecd1
.text--settlement
    font-size: 13px
    color: #6f6f6f
.text--implementation
    color: #9e9e9e
.checkbox--implementation
    height: 20px
    margin-top: 10px
    margin-left: 10px
.minRateTransportTrue
    font-size: 0.8rem
    color: green

.transportConfigErrors
    color: red
    font-size: 0.8rem

.durationTime
    font-size: 0.84rem
    color: #00264d
    font-weight: bold
.transportInfoIcon
    postion: absolute
    left: -180px
    top: 26px
    color: #ffd200
.setByManual
    font-size: 0.8rem
    color: #D2770B
    margin-bottom: 10px
    height: 15px
</style>
