<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="3" md="3" lg="3" class="ml-3">
                <v-menu
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    v-model="estimateValidToPicker"
                    
                    ><template
                        v-slot:activator="{
                            on,
                            attrs,
                        }"
                    >
                        <v-text-field
                            :value="
                                $get(
                                    getEstimatesComments,
                                    'estimateValidTo',
                                    ''
                                )
                                    ? $moment(
                                          getEstimatesComments.estimateValidTo
                                      ).format('DD-MM-YYYY')
                                    : ''
                            "
                            clearable
                            @click:clear="
                                getEstimatesComments.estimateValidTo = null
                            "
                                :label="$t('estimates:estimateValidTo')"
                            v-bind="attrs"
                            v-on="on"
                        >
                        </v-text-field>
                    </template>
                    <v-date-picker
                        first-day-of-week="1"
                        @input="estimateValidToPicker = false"
                        v-model="getEstimatesComments.estimateValidTo"
                         :min="
                                                    new Date()
                                                        .toISOString()
                                                        .substr(0, 10)
                                                "
                    ></v-date-picker>
                </v-menu>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="7" md="7" lg="7" class="ml-3">
                <v-text-field
                    v-model="getEstimatesComments.estimateAdditionalContent"
                    :label="$t('estimates:estimateAdditionalContent')"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row><v-col class="mt-10"></v-col></v-row>
        <v-row><v-col class="mt-10"></v-col></v-row>
        <v-row><v-col class="mt-12"></v-col></v-row>
        <v-row>
            <v-col cols="12" md="12" lg="12" class="ml-3">
                <v-text-field
                    v-model="getEstimatesComments.remarks"
                    :label="$t('estimates:remarks')"
                ></v-text-field>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import _ from 'lodash'
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
    data: () => ({
        estimateValidToPicker: '',
    }),
    computed: {
        ...mapGetters(['getEstimates', 'getEstimatesComments']),
    },
    methods: {
        ...mapActions(['fetchServiceEstimatesTab']),
    },
    beforeMount() {
        this.fetchServiceEstimatesTab({ id: this.$route.params.id })
    },
}
</script>

<style></style>
