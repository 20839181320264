<template>
    <div>
        <v-btn @click="save()" class="buttons buttons--add">
            {{ $t('global:save') }}
        </v-btn>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    props: {
        saveSignature: {
            type: Object,
        },
    },

    methods: {
        ...mapActions(['saveSignatureModal']),
        save() {
            this.saveSignatureModal({
                signature: this.saveSignature,
                id: this.$route.params.id,
            })
            this.$emit('close-modal')
        },
    },
}
</script>
