<template>
    <v-container>
        <v-row>
            <v-col cols="12" xl="12" lg="12" md="12" sm="12">
                <v-row>
                    <v-col cols="12" md="12">
                        <v-data-table
                            class="serviceParts__modal"
                            :height="this.isTablet() ? '262' : '262'"
                            fixed-header
                            :mobile-breakpoint="1100"
                            hide-default-footer
                            :items="estimatesParts"
                            :headers="headersList"
                        >
                            <template v-slot:body>
                                <tbody>
                                    <tr
                                        v-for="(item, index) in estimatesParts"
                                        :key="index"
                                    >
                                        <td>
                                            {{ item.manufacturerIndex }}
                                        </td>
                                        <td>{{ item.name }}</td>
                                        <td>
                                            {{
                                                item.substitute ? 'Tak' : 'Nie'
                                            }}
                                        </td>
                                        <td>
                                            {{ item.price }}
                                        </td>
                                        <td>
                                            {{ item.quantity || 1 }}
                                        </td>

                                        <td>
                                            {{
                                                setSum(
                                                    item.price,
                                                    item.quantity
                                                )
                                            }}
                                        </td>
                                        <td v-if="mode === 'edit'">
                                            <div class="d-flex">
                                                <v-icon
                                                    small
                                                    color="#003399"
                                                    class="mr-2"
                                                    @click="editPart(item)"
                                                    >mdi-pencil</v-icon
                                                >
                                                <v-icon
                                                    small
                                                    color="#e60000"
                                                    @click="deletePart(item)"
                                                    >mdi-delete</v-icon
                                                >
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                            <template v-slot:top>
                                <v-dialog
                                    v-model="dialog"
                                    width="300px"
                                    style="z-index: 999;"
                                >
                                    <v-card>
                                        <v-card-title>
                                            <span class="headline"
                                                >Informacje</span
                                            >
                                        </v-card-title>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="6" sm="6" md="6">
                                                    <money-field
                                                        label="Cena"
                                                        v-model="editInfo.price"
                                                    ></money-field>
                                                </v-col>
                                                <v-col cols="6" sm="6" md="6">
                                                    <v-text-field
                                                        v-model="
                                                            editInfo.quantity
                                                        "
                                                        @keypress="isNumber($event)"
                                                        :min="0"
                                                        label="Ilość"
                                                        ><template
                                                            v-slot:prepend
                                                        >
                                                            <v-icon
                                                                @click="
                                                                    halfValue(
                                                                        'MINUS'
                                                                    )
                                                                "
                                                                size="20"
                                                                color="grey"
                                                                >mdi-minus</v-icon
                                                            > </template
                                                        ><template
                                                            v-slot:append-outer
                                                        >
                                                            <v-icon
                                                                @click="
                                                                    halfValue(
                                                                        'ADD'
                                                                    )
                                                                "
                                                                size="20"
                                                                color="grey"
                                                                >mdi-plus</v-icon
                                                            >
                                                        </template></v-text-field
                                                    >
                                                </v-col>
                                            </v-row>
                                        </v-container>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                class="buttons mr-3"
                                                @click="closeDialog"
                                                >Anuluj</v-btn
                                            >

                                            <v-btn
                                                dark
                                                class="buttons buttons--add"
                                                @click="saveInfo"
                                                >Zapisz</v-btn
                                            >
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <div class="d-flex justify-end totalSum">
                            {{ $t('service:total').toUpperCase() }}:
                            <span></span>
                            <money
                                v-bind="money"
                                class="parts--money"
                                readonly
                                :value="setTotalSum(estimatesParts)"
                            ></money>

                            <span> + 23% VAT</span>
                        </div>
                        <div
                            v-if="setTotalSumWithDiscount(estimatesParts)"
                            class="d-flex justify-end totalSum"
                        >
                            {{
                                $t(
                                    'service:totalSumWithDiscount'
                                ).toUpperCase()
                            }}:
                            <span></span>
                            <money
                                v-bind="money"
                                class="parts--money"
                                readonly
                                :value="setTotalSumWithDiscount(estimatesParts)"
                            ></money>

                            <span> + 23% VAT</span>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <!-- <Buttons /> -->
    </v-container>
</template>
<script>
import Buttons from './Buttons.vue'
import { mapActions, mapGetters } from 'vuex'
import findIndex from 'lodash.findindex'
export default {
    props: ['mode'],
    data() {
        return {
            estimatedSum: 0,
            search: '',
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: ' PLN',
                precision: 2,
                masked: false,
            },
            headers: [
                {
                    text: this.$t('service:index'),
                    align: 'left',
                    value: 'manufacturerIndex',
                    width: 80,
                },
                { text: this.$t('global:name'), value: 'name', width: 150 },
                {
                    text: this.$t('service:replacement'),
                    value: 'substitute',
                    width: 100,
                },
                { text: this.$t('service:price'), value: 'price', width: 80 },
                {
                    text: this.$t('service:quantity'),
                    value: 'quantity',
                    width: 80,
                },

                // {
                //     text: this.$t('service:wasUsedTotal'),
                //     value: 'wasUsedSum',
                //     width: 105,
                // },
                { text: this.$t('service:total'), value: 'sum', width: 105 },
            ],
            dialog: false,
            editInfo: {
                id: '',
                quantity: '',
                price: '',
                wasUsed: '',
            },
            wasUsedItems: [
                { text: this.$t('global:yes'), value: true },
                { text: this.$t('global:no'), value: false },
            ],
        }
    },
    components: {
        Buttons,
    },

    // DANE DOSTARCZANE ZOSTANĄ ZMIENIONE NA CZĘŚCI Z WYCENY
    computed: {
        ...mapGetters([
            'getServicePartsDetails',
            'getServiceDetails',
            'getEstimatesDetailsTable',
        ]),

        headersList: {
            get: function () {
                if (this.mode === 'edit') {
                    // ADD HEADER FOR EDITING
                    this.headers.push({ width: 50 })
                    return this.headers
                }
                return this.headers
            },
        },

        estimatesParts: {
            get: function () {
                if (this.getEstimatesDetailsTable) {
                    this.estimatedSum = this.setTotalWasUsedSum(
                        this.getEstimatesDetailsTable
                    )

                    return this.getEstimatesDetailsTable
                } else {
                    return []
                }
            },
        },
    },
    methods: {
        ...mapActions(['fetchServiceEstimatesTab']),
        setSum(price, quantity) {
            let sum = price * quantity
            sum = sum.toFixed(2)
            return sum
        },
        setTotalSum(items) {
            let temp = [0]
            items.forEach(item => {
                const sum = item.price * item.quantity
                temp.push(sum)
            })
            const totalSum = parseFloat(temp.reduce((acc, val) => acc + val))
            
            return totalSum
        },
        setTotalWasUsedSum(items) {
            let temp = [0]
            items.forEach(item => {
                const sum = item.price * item.quantity
                if (item.wasUsed) temp.push(sum)
            })
            const totalSum = parseInt(temp.reduce((acc, val) => acc + val))
            return totalSum
        },
        setTotalSumWithDiscount(items) {
            const discount = this.$get(
                this.getServiceDetails,
                'device.model.servicePrice.partsDiscountInPercent.price',
                ''
            )

            if (discount) {
                let temp = [0]
                items.forEach(item => {
                    const sum = item.price * item.quantity
                    temp.push(sum)
                })
                let totalSum = parseFloat(temp.reduce((acc, val) => acc + val))
                totalSum = totalSum - totalSum * (discount * 0.01)
                return totalSum
            } else {
                return ''
            }
        },
        setDate(date) {
            if (date) return this.$moment(date).format('DD-MM-YYYY')
        },
        editPart(el) {
            this.editInfo.id = el._id
            this.editInfo.quantity = el.quantity || 1
            this.editInfo.price = el.price || 0
            this.editInfo.wasUsed = el.wasUsed || false
            this.dialog = true
        },
        deletePart(el) {
            let arr = this.getEstimatesDetailsTable
            let index = arr.findIndex(arr => arr._id == el._id)
            arr.splice(index, 1)
        },
        saveInfo(e) {
            let arr = this.getEstimatesDetailsTable
            let index = findIndex(arr, e => e._id == this.editInfo.id)
            arr[index].price = this.editInfo.price
    
            arr[index].quantity = this.editInfo.quantity
            arr[index].wasUsed = this.editInfo.wasUsed
            arr[index].sum = this.editInfo.quantity * this.editInfo.price
            this.dialog = false
        },
        closeDialog() {
            this.dialog = false
        },
        halfValue(mode) {
            if (mode === 'ADD') {
                this.editInfo.quantity =
                    parseFloat(this.editInfo.quantity) + 1
            }
            if (mode === 'MINUS') {
                this.editInfo.quantity =
                    parseFloat(this.editInfo.quantity) - 1
            }
        },
        isNumber: function (evt) {
            evt = evt ? evt : window.event
            var charCode = evt.which ? evt.which : evt.keyCode
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault()
            } else {
                return true
            }
        },
    },
    async beforeMount() {
        await this.fetchServiceEstimatesTab({ id: this.$route.params.id })
    },
}
</script>

<style lang="sass" scoped>
.totalSum
    margin-top: 5px
    font-size: 14px
    font-weight: 500
.parts--money
    text-align: right
    margin-right: 5px
    max-width: 100px
.table
    border-bottom: 1px solid grey
</style>
