var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{staticClass:"details__card"},[_c('v-col',{staticClass:"pa-5 py-0",attrs:{"cols":"7","xl":"7","lg":"8","md":"12","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('service:settlementTimeCost')}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"4","md":"4","lg":"4"}},[_c('v-text-field',{attrs:{"value":`${_vm.$get(
                            _vm.getServiceDetails,
                            'technician.name',
                            ''
                        )} ${_vm.$get(
                            _vm.getServiceDetails,
                            'technician.lastname',
                            ''
                        )}`,"readonly":"","label":_vm.$t('service:nameLastnameTechnic')}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4","md":"4","lg":"4"}},[_c('v-text-field',{attrs:{"value":_vm.$get(
                                _vm.getServiceDetails,
                                'carRegistrationNumber',
                                ''
                            ),"readonly":"","label":_vm.$t('service:registrationNumber')}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-2 pt-4 background--implementation",attrs:{"cols":"2","md":"2","lg":"2"}},[_c('span',{staticClass:"text--implementation"},[_vm._v(_vm._s(_vm.$t('service:survey')))]),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"5"}},[_c('v-text-field',{attrs:{"value":_vm.$get(
                                        _vm.getServiceDetails,
                                        'realization.survey',
                                        0
                                    ) + 'h',"readonly":"","label":_vm.$t('service:time')}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"7"}},[_c('money-field',{attrs:{"label":_vm.$t('service:currency'),"value":_vm.getPrice('survey'),"readonly":true,"money":_vm.money}})],1)],1)],1),_c('v-col',{staticClass:"py-2 pt-4 background--implementation",attrs:{"cols":"2","md":"2","lg":"2"}},[_c('span',{staticClass:"text--implementation"},[_vm._v(_vm._s(_vm.$t('service:repair')))]),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"5"}},[_c('v-text-field',{attrs:{"value":_vm.$get(
                                        _vm.getServiceDetails,
                                        'realization.repair',
                                        0
                                    ) + 'h',"readonly":"","label":_vm.$t('service:time')}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"7"}},[_c('money-field',{attrs:{"label":_vm.$t('service:currency'),"value":_vm.getPrice('repair'),"readonly":true,"money":_vm.money}})],1)],1)],1),_c('v-col',{staticClass:"py-2 pt-4 background--implementation",attrs:{"cols":"2","md":"2","lg":"2"}},[_c('span',{staticClass:"text--implementation"},[_vm._v(_vm._s(_vm.$t('service:implementation')))]),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"5"}},[_c('v-text-field',{attrs:{"value":_vm.$get(
                                        _vm.getServiceDetails,
                                        'realization.installation',
                                        0
                                    ) + 'h',"readonly":"","label":_vm.$t('service:time')}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"7"}},[_c('money-field',{attrs:{"label":_vm.$t('service:currency'),"value":_vm.getPrice('installation'),"readonly":true,"money":_vm.money}})],1)],1)],1),_c('v-col',{staticClass:"py-2 pt-4 background--implementation",attrs:{"cols":"2","md":"2","lg":"2"}},[_c('span',{staticClass:"text--implementation"},[_vm._v(_vm._s(_vm.$t('service:diagnostics')))]),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"5"}},[_c('v-text-field',{attrs:{"value":_vm.$get(
                                        _vm.getServiceDetails,
                                        'realization.diagnostics',
                                        0
                                    ) + 'h',"readonly":"","label":_vm.$t('service:time')}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"7"}},[_c('money-field',{attrs:{"label":_vm.$t('service:currency'),"value":_vm.getPrice('diagnostics'),"readonly":true,"money":_vm.money}})],1)],1)],1),_c('v-col',{staticClass:"py-2 pt-4 background--implementation",attrs:{"cols":"2","md":"2","lg":"2"}},[_c('span',{staticClass:"text--implementation"},[_vm._v(_vm._s(_vm.$t('service:ItService')))]),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"5"}},[_c('v-text-field',{attrs:{"value":_vm.$get(
                                        _vm.getServiceDetails,
                                        'realization.itService',
                                        0
                                    ) + 'h',"readonly":"","label":_vm.$t('service:time')}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"7"}},[_c('money-field',{attrs:{"label":_vm.$t('service:currency'),"value":_vm.getPrice('itService'),"readonly":true,"money":_vm.money}})],1)],1)],1),_c('v-col',{staticClass:"py-2 pt-4 background--implementation--total",attrs:{"cols":"2","md":"2","lg":"2"}},[_c('span',{staticClass:"text--implementation"},[_vm._v(_vm._s(_vm.$t('service:total')))]),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"5"}},[_c('v-text-field',{attrs:{"value":_vm.getTotalTime(),"readonly":"","label":_vm.$t('service:time')}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"7"}},[_c('money-field',{attrs:{"label":_vm.$t('service:currency'),"value":_vm.getPrice('sum'),"readonly":true,"money":_vm.money}})],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-3",attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('service:counters')}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"6","lg":"6"}},[_c('span',{staticClass:"text--implementation"},[_vm._v(_vm._s(_vm.$t('service:countersMonoDevice')))]),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"4","md":"4","lg":"4"}},[_c('v-text-field',{attrs:{"value":_vm.$get(
                                        _vm.getServiceDetails,
                                        'realization.counters.monoDeviceStart',
                                        ''
                                    ),"readonly":"","label":_vm.$t('service:startCounter')}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4","md":"4","lg":"4"}},[_c('v-text-field',{attrs:{"value":_vm.$get(
                                        _vm.getServiceDetails,
                                        'realization.counters.monoDeviceEnd',
                                        ''
                                    ),"readonly":"","label":_vm.$t('service:endCounter')}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4","md":"4","lg":"4"}},[_c('v-text-field',{attrs:{"value":_vm.$get(
                                        _vm.getServiceDetails,
                                        'realization.counters.scans',
                                        ''
                                    ),"readonly":"","label":_vm.$t('service:scan')}})],1)],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"6","lg":"6"}},[_c('span',{staticClass:"text--implementation"},[_vm._v(_vm._s(_vm.$t('service:countersColorDevice')))]),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"6","lg":"6"}},[_c('v-text-field',{attrs:{"value":_vm.$get(
                                        _vm.getServiceDetails,
                                        'realization.counters.colorDeviceStart',
                                        ''
                                    ),"readonly":"","label":_vm.$t('service:startCounter')}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"6","lg":"6"}},[_c('v-text-field',{attrs:{"value":_vm.$get(
                                        _vm.getServiceDetails,
                                        'realization.counters.colorDeviceEnd',
                                        ''
                                    ),"readonly":"","label":_vm.$t('service:endCounter')}})],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"12","lg":"12","offset":"0"}},[_c('span',{staticClass:"text--implementation"},[_vm._v(_vm._s(_vm.$t('service:countersCar')))]),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"3","md":"3","lg":"3"}},[_c('v-text-field',{attrs:{"value":_vm.$get(
                                        _vm.getServiceDetails,
                                        'realization.counters.carEnd',
                                        0
                                    ) -
                                    _vm.$get(
                                        _vm.getServiceDetails,
                                        'realization.counters.carStart',
                                        0
                                    ),"readonly":"","label":_vm.$t('service:km')}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"3","md":"3","lg":"3"}},[_c('money-field',{attrs:{"value":_vm.ownTransportCost(),"readonly":"","label":_vm.$t('service:transportationCost')}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"3","md":"3","lg":"3"}},[_c('v-text-field',{attrs:{"value":_vm.getTransportKillometers(),"readonly":"","label":_vm.$t('service:kilometersToBeSettled')}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"3","md":"3","lg":"3"}},[_c('money-field',{attrs:{"value":_vm.getTransportCostToBeSettled(),"readonly":"","label":_vm.$t('service:transportCostToBeSettled')}})],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-1",attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('service:settlementIntervention')}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"6","lg":"6"}},[_c('span',{staticClass:"text--implementation"},[_vm._v(_vm._s(_vm.$t('service:internalSettlement')))]),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"6","lg":"6"}},[_c('v-text-field',{attrs:{"value":_vm.$get(
                                        _vm.getServiceDetails,
                                        'realization.payment.internalDate',
                                        false
                                    )
                                        ? _vm.$moment(
                                              _vm.getServiceDetails.realization
                                                  .payment.internalDate
                                          ).format('DD-MM-YYYY')
                                        : '',"readonly":"","label":_vm.$t('service:date')}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"6","lg":"6"}},[_c('v-text-field',{attrs:{"value":_vm.$get(
                                        _vm.getServiceDetails,
                                        'realization.payment.internalDocumentNumber',
                                        ''
                                    ),"readonly":"","label":_vm.$t('service:docNumber')}})],1)],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"6","lg":"6"}},[_c('span',{staticClass:"text--implementation"},[_vm._v(_vm._s(_vm.$t('service:externalSettlement')))]),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"6","lg":"6"}},[_c('v-text-field',{attrs:{"value":_vm.$get(
                                        _vm.getServiceDetails,
                                        'invoiceDate',
                                        false
                                    )
                                        ? _vm.$moment(
                                              _vm.getServiceDetails.invoiceDate
                                          ).format('DD-MM-YYYY')
                                        : '',"readonly":"","label":_vm.$t('service:date')}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"6","lg":"6"}},[_c('v-text-field',{attrs:{"value":_vm.$get(
                                        _vm.getServiceDetails,
                                        'invoiceNumber',
                                        ''
                                    ),"readonly":"","label":_vm.$t('service:docNumber')}})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"5","xl":"5","lg":"4","md":"12","sm":"12"}},[_c('v-row',[_c('v-col',{staticClass:"pb-5",attrs:{"cols":"6","xl":"6","lg":"12","md":"12","sm":"12"}},[_c('span',{staticClass:"ml-3 text--implementation"},[_vm._v(_vm._s(`${_vm.$t('service:required')}:`))]),_c('v-checkbox',{staticClass:"checkbox--implementation",attrs:{"input-value":_vm.$get(
                                _vm.getServiceDetails,
                                'realization.requirements.continuation',
                                ''
                            ),"color":"secondary","readonly":"","label":`${_vm.$t('service:continuation')}`}}),_c('v-checkbox',{staticClass:"checkbox--implementation",attrs:{"input-value":_vm.$get(
                                _vm.getServiceDetails,
                                'realization.requirements.valuation',
                                ''
                            ),"color":"secondary","readonly":"","label":`${_vm.$t('service:valuation')}`}}),_c('v-checkbox',{staticClass:"checkbox--implementation",attrs:{"input-value":_vm.$get(
                                _vm.getServiceDetails,
                                'realization.requirements.itService',
                                ''
                            ),"color":"secondary","readonly":"","label":`${_vm.$t('service:ItService')}`}}),_c('div',[_c('v-checkbox',{staticClass:"checkbox--implementation",attrs:{"input-value":_vm.$get(
                                    _vm.getServiceDetails,
                                    'realization.requirements.claim',
                                    ''
                                ),"color":"secondary","readonly":"","label":`${_vm.$t('service:claim')}`}}),(_vm.getServiceDetails.claimNumber)?_c('span',{staticClass:"claim--number"},[_vm._v(_vm._s(`(${_vm.getServiceDetails.claimNumber})`))]):_vm._e()],1),_c('v-checkbox',{staticClass:"checkbox--implementation",attrs:{"input-value":_vm.$get(
                                _vm.getServiceDetails,
                                'realization.requirements.placeholderDevice',
                                ''
                            ),"color":"secondary","readonly":"","label":`${_vm.$t('service:replacementDevice')}`}}),_c('v-checkbox',{staticClass:"checkbox--implementation",attrs:{"input-value":_vm.$get(
                                _vm.getServiceDetails,
                                'realization.requirements.expertise',
                                ''
                            ),"color":"secondary","readonly":"","label":`${_vm.$t('service:expertise')}`}}),_c('v-checkbox',{staticClass:"checkbox--implementation",attrs:{"input-value":_vm.$get(
                                _vm.getServiceDetails,
                                'realization.requirements.invoice',
                                ''
                            ),"color":"secondary","readonly":"","label":`${_vm.$t('service:invoice')}`}})],1),_c('v-col',{attrs:{"cols":"6","xl":"6","lg":"12","md":"12","sm":"12","z":""}},[_c('span',{staticClass:"ml-3 text--implementation"},[_vm._v(_vm._s(`${_vm.$t('service:comments')}:`))]),_c('v-checkbox',{staticClass:"checkbox--implementation",attrs:{"input-value":_vm.$get(
                                _vm.getServiceDetails,
                                'realization.requirements.repairWorkshop',
                                ''
                            ),"color":"secondary","readonly":"","label":`${_vm.$t('service:repairWorkshop')}`}}),_c('v-checkbox',{staticClass:"checkbox--implementation",attrs:{"input-value":_vm.$get(
                                _vm.getServiceDetails,
                                'realization.requirements.remoteService',
                                ''
                            ),"color":"secondary","readonly":"","label":`${_vm.$t('service:remote')}`}}),_c('v-checkbox',{staticClass:"checkbox--implementation",attrs:{"input-value":_vm.$get(
                                _vm.getServiceDetails,
                                'realization.requirements.unjustifiedService',
                                ''
                            ),"color":"secondary","readonly":"","label":`${_vm.$t('service:serviceUnreasonable')}`}}),_c('v-checkbox',{staticClass:"checkbox--implementation",attrs:{"input-value":_vm.$get(
                                _vm.getServiceDetails,
                                'realization.requirements.clientApproval',
                                ''
                            ),"color":"secondary","readonly":"","label":`${_vm.$t('service:correctOperation')}`}}),_c('v-checkbox',{staticClass:"checkbox--implementation mt-5 pt-3",attrs:{"input-value":_vm.$get(
                                _vm.getServiceDetails,
                                'realization.requirements.clientCostInformed',
                                ''
                            ),"color":"secondary","readonly":"","label":`${_vm.$t('service:informedCustomer')}`}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12","md":"12"}},[_c('v-textarea',{attrs:{"no-resize":"","rows":"1","value":_vm.$get(
                                _vm.getServiceDetails,
                                'realization.interventionRange',
                                ''
                            ),"auto-grow":"","readonly":"","label":`${_vm.$t('service:scopeOfIntervention')}`}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-textarea',{attrs:{"no-resize":"","value":_vm.$get(
                                _vm.getServiceDetails,
                                'realization.technicianComments',
                                ''
                            ),"rows":"1","auto-grow":"","readonly":"","label":`${_vm.$t(
                            'service:commentsToNextIntervention'
                        )}`}})],1)],1)],1)],1),_c('Buttons',{on:{"open-new-tabs":function($event){return _vm.openModels(_vm.path)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }