<template>
    <v-container class="p-relative">
        <v-row class="details__card">
            <v-col cols="8" xl="8" lg="8" md="8" sm="8" class="px-5 pb-0">
                <Separator :text="$t('estimates:partsAndMaterials')" />

                <!-- PARTS TABLE -->
                <BasicTable />
            </v-col>

            <v-col class="mt-6" cols="4" xl="4" lg="4" md="4" sm="4">
                <!-- <Separator :text="$t('estimates:estimatesStatus')" /> -->
                <v-col class="py-0 pb-0 pt-0 pl-0" cols="12" sm="12">
                    <v-select
                        class="select-top-zero"
                        :items="availableEstimatesStatuses"
                        v-model="estimatesStatus"
                        :label="$t('estimates:estimatesStatus')"
                        item-text="name"
                        return-object
                        @change="saveStatusInDb"
                        ><template v-slot:item="{ item, attrs, on }"
                            ><v-list-item
                                v-bind="attrs"
                                v-on="on"
                                :style="{
                                    background: item.color.background,
                                    color: item.color.font,
                                }"
                                >{{ item.name }}</v-list-item
                            >
                        </template>
                        <template v-slot:selection="{ item, attrs, on }"
                            ><div style="padding-top: 37px;"></div>
                            <v-list-item
                                v-bind="attrs"
                                v-on="on"
                                :style="{
                                    background: item.color.background,
                                    color: item.color.font,
                                    minHeight: '33px',
                                }"
                                >{{ item.name }}</v-list-item
                            >
                        </template>
                    </v-select>
                </v-col>

                <Separator
                    class="mt-3"
                    :text="$t('estimates:servicesAndTransport')"
                />
                <v-row>
                    <v-col cols="6" xl="6" lg="6" md="6" sm="6">
                        <money-field
                            :label="$t('estimates:servicesCost')"
                            v-model="estimates.chooseService.total"
                        ></money-field>
                    </v-col>
                </v-row>
                <v-row>
                    <services-to-performed-component />
                </v-row>
                <v-row
                    ><v-col cols="6" xl="6" lg="6" md="6" sm="6">
                        <v-text-field
                            :label="$t('estimates:kilometersToClient')"
                            v-model="
                                estimates.transportConfig.kilometersToBeSettled
                            "
                            readonly
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" xl="6" lg="6" md="6" sm="6">
                        <money-field
                            v-model="estimates.transportConfig.costOfTransport"
                            :label="$t('estimates:transportCosts')"
                        ></money-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" xl="12" lg="12" md="12" sm="12">
                        <Separator :text="$t('estimates:summary')" />
                        <money-field
                            :label="$t('estimates:summaryText')"
                            :value="totalServicesPartsTransport"
                        ></money-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <estimate-buttons-component
            @show-estimate-print="save"
            @createEstimateOpenModal="addEstimate()"
        />

        <Modal
            :title="$t('service:additionService')"
            :open="openAddEstimateModal"
            :width="'1000'"
            @close="closeAddEstimateModal"
            :overlayOpacity="'0.11'"
        >
            <AddEsimateModalContent slot="AEContent" />
            <AddEsimateModalButtons
                slot="buttons"
                @closeAddEstimateModal="openAddEstimateModal = false"
            />
        </Modal>
        <ConfirmModal
            :openConfirm="openContinuationModal"
            @close-confirm="closeConfirmModal"
        >
            <div slot="main">
                <ConfirmContent :type="type" />
            </div>
            <div slot="buttons">
                <div class="d-flex justify-center">
                    <v-btn
                        color="rgb(117, 117, 117)"
                        width="210"
                        height="40"
                        @click="closeContinuationModal()"
                        class="white--text mx-2"
                    >
                        Nie twórz kontynuacji
                    </v-btn>
                    <v-btn
                        width="210"
                        height="40"
                        color="#445d75"
                        class="white--text mx-2"
                        @click="createContination()"
                        >Utwórz kontynuację</v-btn
                    >
                </div>
            </div>
        </ConfirmModal>
        <portal-target name="destination"> </portal-target>
    </v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import BasicTable from '../EstimatesDetails/EstimatesPartTable/BasicPartsTable.vue'
import ServicesToPerformedComponent from '../EstimatesDetails/ServicesToPerformed/ServicesToPerformedComponent.vue'
import EstimateButtonsComponent from './EstimateButtonsComponent.vue'
import AddEsimateModalButtons from './CreateEstimateModal/Buttons.vue'
import AddEsimateModalContent from './CreateEstimateModal/Content.vue'
import ConfirmContent from './ConfirmModal.vue'
import ConfirmContinuationContent from './ConfirmContinuationContent.vue'
export default {
    name: 'EstimatesDetailsComponent',
    components: {
        BasicTable,
        ServicesToPerformedComponent,
        EstimateButtonsComponent,
        AddEsimateModalButtons,
        AddEsimateModalContent,
        ConfirmContent,
    },
    computed: {
        ...mapGetters([
            'getEstimatesStatus',
            'getEstimates',
            'getServiceDetails',
        ]),
        estimatesStatus: {
            get() {
                return this.getEstimatesStatus
            },
            set(status) {
                this.setEstimateStatus(status)
            },
        },
        estimates: {
            get() {
                return this.getEstimates
            },
        },
        totalServicesPartsTransport: {
            get() {
                return this.setTotalServicesPartsTransport()
            },
        },
    },
    data: () => ({
        type: '',
        openContinuationModal: false,
        openAddEstimateModal: false,
        availableEstimatesStatuses: [
            {
                name: 'Do weryfikacji',
                color: {
                    background: '#fae5cc',
                    font: 'green',
                },
            },
            {
                name: 'Wysłana',
                color: {
                    background: '#cbdaf9',
                    font: '#FFFFFF',
                },
            },
            {
                name: 'Zaakceptowana',
                color: {
                    background: '#daead2',
                    font: '#FFFFFF',
                },
            },
            {
                name: 'Odrzucona',
                color: {
                    background: '#e8d1dc',
                    font: '#FFFFFF',
                },
            },
        ],
        openPrintModal: false,
        type: '',
    }),
    methods: {
        ...mapActions([
            'setEstimateStatus',
            'updateServiceEstimate',
            'fetchServiceEstimatesTab',
            'updateService',
            'fetchServiceTable',
        ]),

        ...mapMutations(['setSnackbars', 'setServiceWhere', 'setServiceModal']),
        closeAddEstimateModal() {
            this.openAddEstimateModal = false
            this.$root.$emit('onClosePriceListInfo')
        },
        addEstimate() {
            this.openAddEstimateModal = true
        },
        togglePrintModal() {
            this.openPrintModal = true
        },
        async saveStatusInDb(e) {
            if (e) {
                const resp = await this.updateServiceEstimate({
                    id: this.$route.params.id,
                })

                if (resp) {
                    if (e.name === 'Zaakceptowana') {
                        this.openContinuationModal = true
                    }
                    if (e.name === 'Odrzucona') {
                        this.setIconFilter({
                            'realization.requirements.valuation': true,
                        })
                    }
                }
            }
        },
        setTotalServicesPartsTransport() {
            // TODO SET TOTAL SERVICE IF DONT EXIST ONE OF FEW
            const costOfTransport =
                this.estimates?.transportConfig?.costOfTransport || 0
            const costOfServices = this.estimates?.chooseService?.total || 0
            const costOfParts = this.estimates?.parts?.total || 0

            return (
                Number(costOfTransport) +
                Number(costOfServices) +
                Number(costOfParts)
            )
        },
        closeConfirmModal() {
            this.goToService()

            this.openPrintModal = false
            this.$root.$emit('clearModal')
        },
        goToService(type) {
            let routeData = this.$router.resolve({
                name: 'estimateDocumentGenerator',
                query: {
                    id: this.$route.params.id,
                    type: type,
                },
            })
            window.open(routeData.href, '_blank')
        },
        async save() {
            this.goToService()

            this.openPrintModal = false
            this.setSnackbars({
                type: 'success',
            })
        },
        closeContinuationModal() {
            this.openContinuationModal = false
            this.$root.$emit('clearModal')
        },
        async createContination() {
            await new this.Rest(
                'POST',
                `service/${this.getServiceDetails._id}/continue`
            ).send()

            this.openContinuationModal = false
            this.$router.push(`/service`)
        },
        async setIconFilter(filter) {
            let service = this.getServiceDetails

            service.realization.requirementsCompleted.valuation = true

            this.setServiceModal(service)

            await this.updateService({ id: this.getServiceDetails._id })

            await this.fetchServiceTable()
        },
    },
    beforeMount() {
        this.fetchServiceEstimatesTab({ id: this.$route.params.id })
    },
}
</script>

<style lang="scss" scoped>
.select-top-zero {
    margin-top: 0px;
    padding-top: 0px;
}
</style>
