<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="6" md="6">
                <v-checkbox
                    class="checkbox--implementation"
                    color="success"
                    :label="`${$t('service:settleFromTheBaseAutomatically')}`"
                    v-model="checkBoxAuto"
                    @change="automaticTransportCalculation()"
                ></v-checkbox>
            </v-col>

            <v-col>
                <div>
                    <v-tooltip bottom>
                        <template
                            v-slot:activator="{
                                on,
                            }"
                        >
                            <v-icon v-on="on" class="transportInfoIcon"
                                >mdi-information</v-icon
                            >
                        </template>
                        <span>{{ $t('service:transportInfoIcon') }}</span>
                    </v-tooltip>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6" md="6" class="mb-2">
                <v-checkbox
                    v-model="checkBoxReturn"
                    class="checkbox--implementation"
                    color="success"
                    :label="`${$t('service:settleReturn')}`"
                    checked
                    @click="onSetReturn()"
                ></v-checkbox>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="3" md="3" lg="3" class="ml-3">
                <v-text-field
                    ref="kilometersToBeSettled"
                    :label="$t('service:kilometersToBeSettled')"
                    suffix="km"
                    v-model="kilometersToBeSettled"
                    :disabled="kilometersToBeSettledField"
                    :rules="[rules.number]"
                    @keyup="setCostOfTransport"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row class="ml-3">
            <div class="setByManual">
                <span v-if="!checkBoxAuto">{{
                    'Ustawiono ręcznie ilość kilometrów.'
                }}</span>
            </div>
        </v-row>
        <v-row>
            <v-col cols="3" md="3" lg="3" class="ml-3 pt-0">
                <v-select
                    v-model="typeOfKm"
                    :items="[
                        'Stawka za 1 km transportu',
                        'Stawka stała za transport',
                    ]"
                    :label="$t('service:typeOfAccountingKilometers')"
                    @change="setCostOfTransport"
                ></v-select>
            </v-col>
            <v-col cols="3" md="3" lg="3" class="ml-3 pt-0">
                <money-field
                    v-model="costOfTransport"
                    :disabled="checkBoxAuto"
                    :label="$t('service:transportCostToBeSettled')"
                    @change.native="setManulCostOfTransport"
                ></money-field>
                <div v-if="minRateTransportSet" class="minRateTransportTrue">
                    {{ $t('service:settledMinimumRate') }}
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
    data: () => ({
        kilometersToBeSettled: 0,
        kilometersToBeSettledField: false,
        typeOfKm: 'Stawka za 1 km transportu',
        costOfTransport: 0,
        checkBoxReturn: false,
        checkBoxAuto: true,
        rules: {
            number: value => {
                const pattern = /^(\d+\.?\d*|\.\d+)$/
                return pattern.test(value)
            },
        },
        basicDistance: 0,
        minRateTransportSet: false,
        money: {
            decimal: ',',
            thousands: '.',
            prefix: '',
            suffix: ' PLN',
            precision: 2,
            masked: false,
        },
    }),
    computed: {
        ...mapGetters([
            'getServiceDetails',
            'getSettingsDetails',
            'getEstimates',
        ]),
    },

    methods: {
        ...mapActions([
            'fetchSettings',
            'fetchGoogleLocationData',
            'setEstimatesModalTransportConfig',
            'fetchServiceEstimatesTab',
        ]),
        ...mapMutations(['setSnackbars']),
        async automaticTransportCalculation() {
            this.kilometersToBeSettledField = false

            if (this.checkBoxAuto) {
                this.kilometersToBeSettledField = true
                try {
                    const getAdressInfoFromBranches = this.getAdressInfoFromBranches()
                    await this.fetchSettings({ type: 'add' })
                    const settings = this.getSettingsDetails

                    const service = this.getServiceDetails

                    const companyLoaction = {
                        country: settings?.companyCountry,
                        city: settings?.companyCity,
                        postCode: settings?.companyPostCode,
                        street: settings?.companyStreet,
                        houseNumber: settings?.companyHouseNumber,
                        apartmentNumber: settings?.companyApartmentNumber,
                    }

                    const clientLocation = this.createClientAddress(
                        getAdressInfoFromBranches,
                        companyLoaction
                    )

                    const result = await this.fetchGoogleLocationData({
                        clientLocation,
                        service,
                        companyLoaction,
                    })
                    if (result?.status === 'OK') {
                        let distance = result.rows[0].elements[0].distance.text
                        distance = distance
                            .replace('km', '')
                            .replace(',', '.')
                            .trim()
                        this.kilometersToBeSettled = distance
                        if (this.checkBoxReturn) {
                            this.kilometersToBeSettled = distance * 2
                        }
                        await this.setCostOfTransport()
                    } else {
                   
                        this.setSnackbars({
                            type: 'error',
                            text: result?.response?.data,
                        })
                    }
                } catch (err) {
                    console.log(err)
                    throw err
                }
            } else {
                await this.setCostOfTransport()
            }
        },
        createClientAddress(addressData) {
            let fullAddress = ''
            if (addressData?.address) {
                fullAddress += addressData.address + ' '
            } else {
                fullAddress += ''
            }
            if (addressData?.city) {
                fullAddress += addressData.city + ' '
            } else {
                fullAddress += ''
            }
            if (addressData?.postCode) {
                fullAddress += addressData.postCode
            } else {
                fullAddress += ''
            }

            return fullAddress
        },
        getAdressInfoFromBranches() {
            let branches = this.$get(
                this.getServiceDetails,
                'device.client.branches',
                []
            )
            let branch = branches.find(
                el => el._id === this.getServiceDetails.device.branch
            )
            if (branch) {
                const [address, city, postCode] = [
                    branch?.address,
                    branch?.city,
                    branch?.postCode,
                ]
                return { address, city, postCode }
            } else return ''
        },
        async setCostOfTransport() {
            this.minRateTransportSet = false
            const service = this.getServiceDetails
            const device = service.device
            const model = device.model
            const servicePrice = model.servicePrice

            if (this.typeOfKm === 'Stawka za 1 km transportu') {
                this.costOfTransport = (
                    servicePrice.rateOneKmTransport.price *
                    this.kilometersToBeSettled
                ).toFixed(2)
            }
            if (this.typeOfKm === 'Stawka stała za transport') {
                this.costOfTransport = (
                    servicePrice.staticRateTransport.price *
                    this.kilometersToBeSettled
                ).toFixed(2)
            }

            const transportConfig = {
                kilometersToBeSettled: Number(this.kilometersToBeSettled),
                typeOfKm: this.typeOfKm,
                costOfTransport: Number(this.costOfTransport).toFixed(2),
                checkBoxReturn: this.checkBoxReturn,
                checkBoxAuto: this.checkBoxAuto,
            }

            if (this.costOfTransport <= servicePrice?.minRateTransport?.price) {
                this.minRateTransportSet = true
                this.costOfTransport = Number(
                    servicePrice?.minRateTransport?.price
                ).toFixed(2)

                transportConfig.costOfTransport = Number(
                    this.costOfTransport
                ).toFixed(2)
            }

            this.setEstimatesModalTransportConfig(transportConfig)
        },
        async onSetReturn() {
            if (this.checkBoxReturn) {
                this.kilometersToBeSettledField = true
                this.kilometersToBeSettled = this.kilometersToBeSettled * 2
            }
            if (!this.checkBoxReturn) {
                if (!this.checkBoxAuto) {
                    this.kilometersToBeSettledField = false
                }

                this.kilometersToBeSettled = this.kilometersToBeSettled / 2
            }
            await this.setCostOfTransport()
        },
        setManulCostOfTransport() {
            const transportConfig = {
                kilometersToBeSettled: Number(this.kilometersToBeSettled),
                typeOfKm: this.typeOfKm,
                costOfTransport: Number(this.costOfTransport).toFixed(2),
                checkBoxReturn: this.checkBoxReturn,
                checkBoxAuto: this.checkBoxAuto,
            }

            this.setEstimatesModalTransportConfig(transportConfig)
        },
    },

    beforeMount() {
        this.fetchServiceEstimatesTab({ id: this.$route.params.id })
        // setup component data with vuex

        if (this.getEstimates?.transportConfig) {
            this.kilometersToBeSettled = this.getEstimates.transportConfig.kilometersToBeSettled
            this.typeOfKm = this.getEstimates.transportConfig.typeOfKm
            this.costOfTransport = this.getEstimates.transportConfig.costOfTransport
            this.checkBoxReturn = this.getEstimates.transportConfig.checkBoxReturn
            this.checkBoxAuto = this.getEstimates.transportConfig?.checkBoxAuto
        }
    },
    async mounted() {
        if (this.kilometersToBeSettled == 0) {
            this.automaticTransportCalculation()
        }
    },
}
</script>

<style lang="sass" scoped>

.checkbox--implementation
    height: 20px
    margin-top: 10px
    margin-left: 10px
.transportInfoIcon
    postion: absolute
    left: -200px
    top: 15px
    color: #ffd200
.minRateTransportTrue
    font-size: 0.8rem
    color: green
.setByManual
    font-size: 0.8rem
    color: #D2770B
    margin-bottom: 17px
    height: 15px
</style>
