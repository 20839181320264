<template>
    <v-container>
        <v-row class="details__card">
            <v-col cols="7" xl="7" lg="8" md="12" sm="12" class="pa-5 py-0">
                <v-row>
                    <v-col cols="12" md="12">
                        <Separator :text="$t('service:settlementTimeCost')" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4" md="4" lg="4" class="py-0">
                        <v-text-field
                            :value="`${$get(
                                getServiceDetails,
                                'technician.name',
                                ''
                            )} ${$get(
                                getServiceDetails,
                                'technician.lastname',
                                ''
                            )}`"
                            readonly
                            :label="$t('service:nameLastnameTechnic')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4" md="4" lg="4" class="py-0">
                        <v-text-field
                            :value="
                                $get(
                                    getServiceDetails,
                                    'carRegistrationNumber',
                                    ''
                                )
                            "
                            readonly
                            :label="$t('service:registrationNumber')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        cols="2"
                        md="2"
                        lg="2"
                        class="py-2 pt-4 background--implementation"
                    >
                        <span class="text--implementation">{{
                            $t('service:survey')
                        }}</span>
                        <v-row>
                            <v-col cols="6" md="5" class="py-0">
                                <v-text-field
                                    :value="
                                        $get(
                                            getServiceDetails,
                                            'realization.survey',
                                            0
                                        ) + 'h'
                                    "
                                    readonly
                                    :label="$t('service:time')"
                                ></v-text-field
                            ></v-col>
                            <v-col cols="6" md="7" class="py-0">
                                <money-field
                                    :label="$t('service:currency')"
                                    :value="getPrice('survey')"
                                    :readonly="true"
                                    :money="money"
                                ></money-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col
                        cols="2"
                        md="2"
                        lg="2"
                        class="py-2 pt-4 background--implementation"
                    >
                        <span class="text--implementation">{{
                            $t('service:repair')
                        }}</span>
                        <v-row>
                            <v-col cols="6" md="5" class="py-0">
                                <v-text-field
                                    :value="
                                        $get(
                                            getServiceDetails,
                                            'realization.repair',
                                            0
                                        ) + 'h'
                                    "
                                    readonly
                                    :label="$t('service:time')"
                                ></v-text-field
                            ></v-col>
                            <v-col cols="6" md="7" class="py-0">
                                <money-field
                                    :label="$t('service:currency')"
                                    :value="getPrice('repair')"
                                    :readonly="true"
                                    :money="money"
                                ></money-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col
                        cols="2"
                        md="2"
                        lg="2"
                        class="py-2 pt-4 background--implementation"
                    >
                        <span class="text--implementation">{{
                            $t('service:implementation')
                        }}</span>
                        <v-row>
                            <v-col cols="6" md="5" class="py-0">
                                <v-text-field
                                    :value="
                                        $get(
                                            getServiceDetails,
                                            'realization.installation',
                                            0
                                        ) + 'h'
                                    "
                                    readonly
                                    :label="$t('service:time')"
                                ></v-text-field
                            ></v-col>
                            <v-col cols="6" md="7" class="py-0">
                                <money-field
                                    :label="$t('service:currency')"
                                    :value="getPrice('installation')"
                                    :readonly="true"
                                    :money="money"
                                ></money-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col
                        cols="2"
                        md="2"
                        lg="2"
                        class="py-2 pt-4 background--implementation"
                    >
                        <span class="text--implementation">{{
                            $t('service:diagnostics')
                        }}</span>
                        <v-row>
                            <v-col cols="6" md="5" class="py-0">
                                <v-text-field
                                    :value="
                                        $get(
                                            getServiceDetails,
                                            'realization.diagnostics',
                                            0
                                        ) + 'h'
                                    "
                                    readonly
                                    :label="$t('service:time')"
                                ></v-text-field
                            ></v-col>
                            <v-col cols="6" md="7" class="py-0">
                                <money-field
                                    :label="$t('service:currency')"
                                    :value="getPrice('diagnostics')"
                                    :readonly="true"
                                    :money="money"
                                ></money-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col
                        cols="2"
                        md="2"
                        lg="2"
                        class="py-2 pt-4 background--implementation"
                    >
                        <span class="text--implementation">{{
                            $t('service:ItService')
                        }}</span>
                        <v-row>
                            <v-col cols="6" md="5" class="py-0">
                                <v-text-field
                                    :value="
                                        $get(
                                            getServiceDetails,
                                            'realization.itService',
                                            0
                                        ) + 'h'
                                    "
                                    readonly
                                    :label="$t('service:time')"
                                ></v-text-field
                            ></v-col>
                            <v-col cols="6" md="7" class="py-0">
                                <money-field
                                    :label="$t('service:currency')"
                                    :value="getPrice('itService')"
                                    :readonly="true"
                                    :money="money"
                                ></money-field
                            ></v-col>
                        </v-row>
                    </v-col>
                    <v-col
                        cols="2"
                        md="2"
                        lg="2"
                        class="py-2 pt-4 background--implementation--total"
                    >
                        <span class="text--implementation">{{
                            $t('service:total')
                        }}</span>
                        <v-row>
                            <v-col cols="6" md="5" class="py-0">
                                <v-text-field
                                    :value="getTotalTime()"
                                    readonly
                                    :label="$t('service:time')"
                                ></v-text-field
                            ></v-col>
                            <v-col cols="6" md="7" class="py-0">
                                <money-field
                                    :label="$t('service:currency')"
                                    :value="getPrice('sum')"
                                    :readonly="true"
                                    :money="money"
                                ></money-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12" class="mt-3">
                        <Separator :text="$t('service:counters')" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" md="6" lg="6" class="py-0">
                        <span class="text--implementation">{{
                            $t('service:countersMonoDevice')
                        }}</span>
                        <v-row>
                            <v-col cols="4" md="4" lg="4" class="py-0">
                                <v-text-field
                                    :value="
                                        $get(
                                            getServiceDetails,
                                            'realization.counters.monoDeviceStart',
                                            ''
                                        )
                                    "
                                    readonly
                                    :label="$t('service:startCounter')"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="4" md="4" lg="4" class="py-0">
                                <v-text-field
                                    :value="
                                        $get(
                                            getServiceDetails,
                                            'realization.counters.monoDeviceEnd',
                                            ''
                                        )
                                    "
                                    readonly
                                    :label="$t('service:endCounter')"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="4" md="4" lg="4" class="py-0">
                                <v-text-field
                                    :value="
                                        $get(
                                            getServiceDetails,
                                            'realization.counters.scans',
                                            ''
                                        )
                                    "
                                    readonly
                                    :label="$t('service:scan')"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="6" md="6" lg="6" class="py-0">
                        <span class="text--implementation">{{
                            $t('service:countersColorDevice')
                        }}</span>
                        <v-row>
                            <v-col cols="6" md="6" lg="6" class="py-0">
                                <v-text-field
                                    :value="
                                        $get(
                                            getServiceDetails,
                                            'realization.counters.colorDeviceStart',
                                            ''
                                        )
                                    "
                                    readonly
                                    :label="$t('service:startCounter')"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6" md="6" lg="6" class="py-0">
                                <v-text-field
                                    :value="
                                        $get(
                                            getServiceDetails,
                                            'realization.counters.colorDeviceEnd',
                                            ''
                                        )
                                    "
                                    readonly
                                    :label="$t('service:endCounter')"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="12" lg="12" offset="0" class="py-0">
                        <span class="text--implementation">{{
                            $t('service:countersCar')
                        }}</span>
                        <v-row>
                            <v-col cols="3" md="3" lg="3" class="py-0">
                                <v-text-field
                                    :value="
                                        $get(
                                            getServiceDetails,
                                            'realization.counters.carEnd',
                                            0
                                        ) -
                                        $get(
                                            getServiceDetails,
                                            'realization.counters.carStart',
                                            0
                                        )
                                    "
                                    readonly
                                    :label="$t('service:km')"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="3" md="3" lg="3" class="py-0">
                                <money-field
                                    :value="ownTransportCost()"
                                    readonly
                                    :label="$t('service:transportationCost')"
                                ></money-field>
                            </v-col>
                            <v-col cols="3" md="3" lg="3" class="py-0">
                                <v-text-field
                                    :value="getTransportKillometers()"
                                    readonly
                                    :label="$t('service:kilometersToBeSettled')"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="3" md="3" lg="3" class="py-0">
                                <money-field
                                    :value="getTransportCostToBeSettled()"
                                    readonly
                                    :label="
                                        $t('service:transportCostToBeSettled')
                                    "
                                ></money-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12" class="mt-1">
                        <Separator
                            :text="$t('service:settlementIntervention')"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" md="6" lg="6" class="py-0">
                        <span class="text--implementation">{{
                            $t('service:internalSettlement')
                        }}</span>
                        <v-row>
                            <v-col cols="6" md="6" lg="6" class="py-0">
                                <v-text-field
                                    :value="
                                        $get(
                                            getServiceDetails,
                                            'realization.payment.internalDate',
                                            false
                                        )
                                            ? $moment(
                                                  getServiceDetails.realization
                                                      .payment.internalDate
                                              ).format('DD-MM-YYYY')
                                            : ''
                                    "
                                    readonly
                                    :label="$t('service:date')"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="6" md="6" lg="6" class="py-0">
                                <v-text-field
                                    :value="
                                        $get(
                                            getServiceDetails,
                                            'realization.payment.internalDocumentNumber',
                                            ''
                                        )
                                    "
                                    readonly
                                    :label="$t('service:docNumber')"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="6" md="6" lg="6" class="py-0">
                        <span class="text--implementation">{{
                            $t('service:externalSettlement')
                        }}</span>
                        <v-row>
                            <v-col cols="6" md="6" lg="6" class="py-0">
                                <v-text-field
                                    :value="
                                        $get(
                                            getServiceDetails,
                                            'invoiceDate',
                                            false
                                        )
                                            ? $moment(
                                                  getServiceDetails.invoiceDate
                                              ).format('DD-MM-YYYY')
                                            : ''
                                    "
                                    readonly
                                    :label="$t('service:date')"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="6" md="6" lg="6" class="py-0">
                                <v-text-field
                                    :value="
                                        $get(
                                            getServiceDetails,
                                            'invoiceNumber',
                                            ''
                                        )
                                    "
                                    readonly
                                    :label="$t('service:docNumber')"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="5" xl="5" lg="4" md="12" sm="12">
                <v-row>
                    <v-col cols="6" xl="6" lg="12" md="12" sm="12" class="pb-5">
                        <span class="ml-3 text--implementation">{{
                            `${$t('service:required')}:`
                        }}</span>
                        <v-checkbox
                            :input-value="
                                $get(
                                    getServiceDetails,
                                    'realization.requirements.continuation',
                                    ''
                                )
                            "
                            class="checkbox--implementation"
                            color="secondary"
                            readonly
                            :label="`${$t('service:continuation')}`"
                        ></v-checkbox>
                        <v-checkbox
                            :input-value="
                                $get(
                                    getServiceDetails,
                                    'realization.requirements.valuation',
                                    ''
                                )
                            "
                            class="checkbox--implementation"
                            color="secondary"
                            readonly
                            :label="`${$t('service:valuation')}`"
                        ></v-checkbox>
                        <v-checkbox
                            :input-value="
                                $get(
                                    getServiceDetails,
                                    'realization.requirements.itService',
                                    ''
                                )
                            "
                            class="checkbox--implementation"
                            color="secondary"
                            readonly
                            :label="`${$t('service:ItService')}`"
                        ></v-checkbox>
                        <div>
                            <v-checkbox
                                :input-value="
                                    $get(
                                        getServiceDetails,
                                        'realization.requirements.claim',
                                        ''
                                    )
                                "
                                class="checkbox--implementation"
                                color="secondary"
                                readonly
                                :label="`${$t('service:claim')}`"
                            ></v-checkbox>
                            <span
                                v-if="getServiceDetails.claimNumber"
                                class="claim--number"
                                >{{
                                    `(${getServiceDetails.claimNumber})`
                                }}</span
                            >
                        </div>
                        <v-checkbox
                            :input-value="
                                $get(
                                    getServiceDetails,
                                    'realization.requirements.placeholderDevice',
                                    ''
                                )
                            "
                            class="checkbox--implementation"
                            color="secondary"
                            readonly
                            :label="`${$t('service:replacementDevice')}`"
                        ></v-checkbox>
                        <v-checkbox
                            :input-value="
                                $get(
                                    getServiceDetails,
                                    'realization.requirements.expertise',
                                    ''
                                )
                            "
                            class="checkbox--implementation"
                            color="secondary"
                            readonly
                            :label="`${$t('service:expertise')}`"
                        ></v-checkbox>
                        <v-checkbox
                            :input-value="
                                $get(
                                    getServiceDetails,
                                    'realization.requirements.invoice',
                                    ''
                                )
                            "
                            class="checkbox--implementation"
                            color="secondary"
                            readonly
                            :label="`${$t('service:invoice')}`"
                        ></v-checkbox>
                    </v-col>
                    <v-col cols="6" xl="6" lg="12" md="12" sm="12" z>
                        <span class="ml-3 text--implementation">{{
                            `${$t('service:comments')}:`
                        }}</span>
                        <v-checkbox
                            :input-value="
                                $get(
                                    getServiceDetails,
                                    'realization.requirements.repairWorkshop',
                                    ''
                                )
                            "
                            class="checkbox--implementation"
                            color="secondary"
                            readonly
                            :label="`${$t('service:repairWorkshop')}`"
                        ></v-checkbox>
                        <v-checkbox
                            :input-value="
                                $get(
                                    getServiceDetails,
                                    'realization.requirements.remoteService',
                                    ''
                                )
                            "
                            class="checkbox--implementation"
                            color="secondary"
                            readonly
                            :label="`${$t('service:remote')}`"
                        ></v-checkbox>
                        <v-checkbox
                            :input-value="
                                $get(
                                    getServiceDetails,
                                    'realization.requirements.unjustifiedService',
                                    ''
                                )
                            "
                            class="checkbox--implementation"
                            color="secondary"
                            readonly
                            :label="`${$t('service:serviceUnreasonable')}`"
                        ></v-checkbox>
                        <v-checkbox
                            :input-value="
                                $get(
                                    getServiceDetails,
                                    'realization.requirements.clientApproval',
                                    ''
                                )
                            "
                            class="checkbox--implementation"
                            color="secondary"
                            readonly
                            :label="`${$t('service:correctOperation')}`"
                        ></v-checkbox>
                        <v-checkbox
                            :input-value="
                                $get(
                                    getServiceDetails,
                                    'realization.requirements.clientCostInformed',
                                    ''
                                )
                            "
                            class="checkbox--implementation mt-5 pt-3"
                            color="secondary"
                            readonly
                            :label="`${$t('service:informedCustomer')}`"
                        ></v-checkbox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12" class="mt-5">
                        <v-textarea
                            no-resize
                            rows="1"
                            :value="
                                $get(
                                    getServiceDetails,
                                    'realization.interventionRange',
                                    ''
                                )
                            "
                            auto-grow
                            readonly
                            :label="`${$t('service:scopeOfIntervention')}`"
                        ></v-textarea>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-textarea
                            no-resize
                            :value="
                                $get(
                                    getServiceDetails,
                                    'realization.technicianComments',
                                    ''
                                )
                            "
                            rows="1"
                            auto-grow
                            readonly
                            :label="`${$t(
                                'service:commentsToNextIntervention'
                            )}`"
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <Buttons @open-new-tabs="openModels(path)" />
    </v-container>
</template>

<script>
import Buttons from './../Buttons/Buttons'
import { mapActions, mapGetters } from 'vuex'
export default {
    data() {
        return {
            path: `${process.env.VUE_APP_SERVER}/#/models/61483465bc9029945a4f6ab7`,
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: '',
                precision: 2,
                masked: false,
            },
        }
    },

    computed: {
        ...mapGetters(['getServiceDetails', 'getServiceModal']),
    },
    methods: {
        ...mapActions(['']),
        openModels() {
            let routeData = this.$router.resolve({
                name: 'models',
                id: this.getServiceDetails.device.model._id,
            })
            window.open(
                `${routeData.href}/${routeData.location.id}?tabs=attachments`,
                '_blank'
            )
        },
        getPrice(type) {
            // Funkcja wyświetlania
            let sum
            let {
                repair,
                survey,
                itService,
                diagnostics,
                installation,
            } = this.getServiceDetails.device.model.servicePrice
            if (
                this.getServiceDetails.device.dedicatedPrice &&
                type !== 'sum'
            ) {
                sum =
                    this.getServiceDetails.device[type].dedicated *
                    this.getServiceDetails.realization[type]
            } else if (type !== 'sum')
                sum =
                    this.getServiceDetails.device.model.servicePrice[type]
                        .price * this.getServiceDetails.realization[type]
            if (type === 'sum') {
                if (this.getServiceDetails.realization.dedicatedPrice)
                    sum = this.getServiceDetails.realization.dedicatedPrice
                else sum = this.getServiceDetails.realization.standardPrice
            }

            if (sum && !Number.isNaN(sum)) return sum.toFixed(2)
            else return ''
        },
        getTotalTime() {
            let sum =
                this.getServiceDetails.realization.itService +
                this.getServiceDetails.realization.diagnostics +
                this.getServiceDetails.realization.installation +
                this.getServiceDetails.realization.repair +
                this.getServiceDetails.realization.survey
            if (!Number.isNaN(sum)) return `${sum}h`
            else return ''
        },
        transportationCost() {
            if (this.getServiceDetails.realization.counters)
                return (
                    (this.getServiceDetails.realization.counters.carEnd || 0) -
                    (this.getServiceDetails.realization.counters.carStart || 0)
                )
            else return 0
        },
        ownTransportCost() {
            if (
                this.getServiceDetails.realization.counters &&
                this.getServiceDetails?.device?.model?.servicePrice
                    ?.rateOneKmTransport
            ) {
                return (
                    ((this.getServiceDetails.realization.counters.carEnd || 0) -
                        (this.getServiceDetails.realization.counters.carStart ||
                            0)) *
                    this.getServiceDetails?.device?.model?.servicePrice
                        ?.rateOneKmTransport?.price
                ).toFixed(2)
            } else return 0
        },
        getTransportCostToBeSettled() {
            if (
                this.getServiceDetails.transportConfig?.transportCostToBeSettled
            ) {
                return (
                    this.getServiceDetails.transportConfig?.transportCostToBeSettled.toFixed(
                        2
                    ) || 0.0
                )
            }
            return 0
        },
        getTransportKillometers() {
            if (this.getServiceDetails.transportConfig?.kilometersToBeSettled) {
                return this.getServiceDetails.transportConfig
                    ?.kilometersToBeSettled
            }
        },
    },
    components: {
        Buttons,
    },
}
</script>
<style lang="sass" scoped>
.background--implementation
    background-color: #f6f7e6
.background--implementation--total
    background-color: #eaecd1
.text--implementation
    font-size: 13px
    color: #445d75
.checkbox--implementation
    height: 20px
    margin-top: 10px
    margin-left: 10px
    position: relative
.claim--number
    font-size: 12px
    display: inline-block
    margin-top: -12px !important
    margin-left: 85px !important
    position: absolute

.currency
    color: rgba(0, 0, 0, 0.6)
    font-size: 11px
.money-value
    border-bottom: 1px solid #8a8a8a
    color: rgba(0, 0, 0, 0.87)
    width: 100%
</style>
