<template>
    <v-container>
        <div class="mobileViewDetails">
            <Header
                :registryStatus="filteredStatuses()"
                :registryTitle="'Serwis'"
                :recordIndex="getServiceDetails.index"
                :registryUrl="'service'"
                :actualStatus="getServiceDetails.status"
                @status-changed="changeStatus"
                :avatarData="getServiceDetails.createdBy"
            />
            <Main :items="getAvailableTabs" class="p-relative">
                <v-tab-item
                    v-for="item in getAvailableTabs"
                    :key="item.id"
                    slot="tabItems"
                    :id="item.id"
                >
                    <component :is="item.component"></component>
                </v-tab-item>
                <div slot="information">
                    <Information @view-map="openMap" />
                    <Modal
                        :title="$t('service:editionService')"
                        :open="open"
                        :height="'715'"
                        v-on:close="closeModal()"
                    >
                        <AEContent slot="AEContent" />
                        <Buttons
                            @closeModal="open = false"
                            :action="'edit'"
                            slot="buttons"
                        />
                    </Modal>
                    <Map
                        :open="map.open"
                        @close="map.open = false"
                        :coords="map.coords"
                        :zoom="19"
                    />
                    <ConfirmModal
                        :openConfirm="openConfirm"
                        @close-confirm="closeConfirmModal"
                    >
                        <div slot="main">
                            <ConfirmContent :type="type" />
                        </div>
                        <div slot="buttons">
                            <div class="d-flex justify-center">
                                <v-btn
                                    color="rgb(117, 117, 117)"
                                    width="110"
                                    height="40"
                                    @click="closeConfirmModal()"
                                    class="white--text mx-2"
                                >
                                    {{
                                        type == 'price'
                                            ? $t('global:no')
                                            : $t('global:cancel')
                                    }}
                                </v-btn>
                                <v-btn
                                    width="110"
                                    height="40"
                                    color="#445d75"
                                    class="white--text mx-2"
                                    @click="save()"
                                    >{{
                                        type == 'price'
                                            ? $t('global:yes')
                                            : $t('global:save')
                                    }}</v-btn
                                >
                            </div>
                        </div>
                    </ConfirmModal>
                    <ConfirmModal
                        :width="'700'"
                        :openConfirm="openConfirmSelectedClosed"
                        @close-confirm="closeConfirmModalSelectedClosed"
                    >
                        <div slot="main">
                            <SelectedStatusClosedConfirm
                                :clientEmail="getClientEmail()"
                            />
                        </div>
                        <div slot="buttons">
                            <OnStatusClosedButtons
                                :serviceDetails="getServiceDetails"
                                @close-confirm="closeConfirmModalSelectedClosed"
                            />
                        </div>
                    </ConfirmModal>
                    <div class="d-flex justify-end buttons--details">
                        <ServiceButtonsDetails
                            @edit-service="editService()"
                            @continuation="confirmAction('continuation')"
                            @show-service-order="showServiceOrder()"
                            @show-service-card="showServiceOrder(true)"
                        />
                        <!-- @show-service-order="showServiceOrder('technic')"
                        @show-service-card="showServiceOrder()" -->
                    </div>
                </div>
            </Main>
            <Snackbars />
        </div>
    </v-container>
</template>
<script>
import store from './../../../store/index'
import Information from './../../../components//Registries/Service/Details/Information'
import Buttons from './../../../components//Registries/Service/Modal/Buttons'
import ServiceButtonsDetails from './../../../components//Registries/Service/Details/Buttons'
import AEContent from './../../../components//Registries/Service/Modal/Content'
import ImplementationService from './../../../components//Registries/Service/Tabs/ImplementationService/Details/Implementation'
import Parts from '@/components/Registries/Service/Tabs/Parts/Details/Parts'
import Map from './../../../components/Global/GoogleMap/Map'
import Archive from './../../../components/Registries/Service/Tabs/Archive/Archive'
import ConfirmContent from './../../../components/Registries/Service/Table/ConfirmModal'
import EsitmatesDetailsComponent from '@/components/Registries/Service/Tabs/Estimates/EstimatesDetails/EstaimesDetailsComponent'
import SelectedStatusClosedConfirm from './SelectedStatusClosedConfirm.vue'
import OnStatusClosedButtons from '../../../components/Registries/Service/Details/OnStatusClosed/Buttons.vue'
import { mapGetters, mapMutations, mapActions } from 'vuex'

import { cloneDeep } from 'lodash'
export default {
    i18nOptions: { namespaces: ['global'] },
    components: {
        Information,
        Buttons,
        Map,
        AEContent,
        ServiceButtonsDetails,
        ImplementationService,
        ConfirmContent,
        SelectedStatusClosedConfirm,
        OnStatusClosedButtons,
    },

    data() {
        return {
            open: false,
            openConfirm: false,
            openConfirmSelectedClosed: false,
            type: '',
            isPrice: false,
            isTechnic: false,
            map: {
                coords: {},
                open: false,
            },

            items: [],
        }
    },

    computed: {
        ...mapGetters([
            'getServicesRegistry',
            'getServiceStep',
            'getServiceStatuses',
            'getServiceDetails',
            'getProfileDetails',
        ]),
        getAvailableTabs: {
            get() {
                this.items = [
                    {
                        name: 'Realizacja usług',
                        id: 'dodatkowa',
                        icon: 'mdi-badge-account-horizontal',
                        component: ImplementationService,
                        visibility: true,
                    },
                    {
                        name: 'Cześci',
                        id: 'parts',
                        icon: 'mdi-cogs',
                        component: Parts,
                        visibility: true,
                    },
                    {
                        name: 'Wyceny',
                        id: 'estimates',
                        icon: 'mdi-cash-fast',
                        component: EsitmatesDetailsComponent,
                        visibility: this.getServiceDetails.realization
                            ?.requirements?.valuation,
                    },
                    {
                        name: 'Archiwum',
                        id: 'archives',
                        icon: 'mdi-archive',
                        component: Archive,
                        visibility: true,
                    },
                ]
                return this.items.filter(e => e.visibility)
            },
        },
    },

    methods: {
        ...mapMutations([
            'clearServiceErrors',
            'setServiceModal',
            'setSnackbars',
        ]),
        ...mapActions([
            'updateServiceStatus',
            'fetchServicePartsTab',
            'fetchRegistriesTable',
            'updateService',
            'fetchService',
        ]),

        goToService() {
            let routeData = this.$router.resolve({
                name: 'service-document-generator',
                query: {
                    id: this.$route.params.id,
                    isTechnic: this.isTechnic,
                    isPrice: this.isPrice,
                },
            })
            // let routeData = this.$router.resolve({
            //     name: 'print',
            //     query: {
            //         id: this.$route.params.id,
            //         isTechnic: this.isTechnic,
            //         isPrice: this.isPrice,
            //     },
            // })
            window.open(routeData.href, '_blank')
        },

        async save() {
            // this.goToService()
            // return
            if (this.type == 'price') {
                this.isPrice = true
                this.openConfirm = false
                this.goToService()
            } else {
                await new this.Rest(
                    'POST',
                    `service/${this.getServiceDetails._id}/continue`
                ).send()
                this.openConfirm = false
                this.fetchService({ id: this.$route.params.id })
                this.setSnackbars({
                    type: 'success',
                })
            }
        },

        async editService() {
            this.clearServiceErrors()

            this.setServiceModal()
            this.fetchRegistriesTable({
                select: 'icon url',
                where: { url: 'devices' },
            })
            this.open = true
        },

        async changeStatus(status) {
            const prevStatus = cloneDeep(this.getServiceDetails.status)
            const nextStatus = status.name

            if (status.name === this.getServiceDetails.status.name) {
                return
            }

            if (
                nextStatus === 'Zamknięty' &&
                prevStatus.name !== 'Zakończony'
            ) {
                this.setSnackbars({
                    type: 'warning',
                    text: `Wymagany status Zakończony `,
                })
                this.getServiceDetails.status = prevStatus
                return
            }

            if (
                nextStatus === 'Zatwierdzony' &&
                prevStatus.name !== 'Zamknięty'
            ) {
                this.setSnackbars({
                    type: 'warning',
                    text: `Wymagany status Zamknięty `,
                })
                this.getServiceDetails.status = prevStatus
                return
            }

            // 27.10.2022 Dodanie możliwości ustawienia statusu Anulowany od razu. Bez wymaganych poprzednich statusów.

            // if (
            //     nextStatus === 'Anulowany' &&
            //     (prevStatus.name !== 'Otwarty' ||
            //         prevStatus.name === 'Przypisany')
            // ) {
            //     this.setSnackbars({
            //         type: 'warning',
            //         text: `Wymagany status Otwarty lub Przypisany `,
            //     })
            //     this.getServiceDetails.status = prevStatus
            //     return
            // }

            if (
                status.name === 'Zamknięty' &&
                this.getServiceDetails?.realization?.requirements?.invoice
            ) {
                this.openConfirmSelectedClosed = true
            }

            let service = this.getServiceDetails
            service.status = status

            this.setServiceModal(service)
            await this.updateService({ id: this.$route.params.id })
        },

        getClientEmail() {
            return this.getServiceDetails.client?.email || ''
        },

        closeModal() {
            this.open = false
            this.getServiceStep.step = 1
        },

        closeConfirmModal() {
            if (this.type == 'price') {
                this.isPrice = false
                this.goToService()
            }

            this.openConfirm = false
            this.$root.$emit('clearModal')
        },
        closeConfirmModalSelectedClosed() {
            this.openConfirmSelectedClosed = false
        },

        confirmAction(type) {
            this.type = type
            this.openConfirm = true
        },

        openMap(location) {
            this.map.coords = location
            this.map.open = true
        },

        showServiceOrder(payload) {
            this.confirmAction('price')
            this.isTechnic = payload
        },
        filteredStatuses() {
            if (
                this.getProfileDetails.isAdmin ||
                this.getProfileDetails.isSuperAdmin
            )
                return this.getServiceStatuses
            if (this.getProfileDetails.specialRoleName.includes('technic'))
                return this.getServiceStatuses.filter(
                    status => status.name === 'Zamknięty'
                )
            if (
                this.getProfileDetails.specialRoleName.includes('logistic') ||
                this.getProfileDetails.specialRoleName.includes(
                    'logisticDirector'
                )
            )
                return this.getServiceStatuses.filter(
                    status =>
                        status.name === 'Zamknięty' ||
                        status.name === 'Zatwierdzony' ||
                        status.name === 'Anulowany'
                )
            return []
        },
    },
    beforeRouteEnter(to, from, next) {
        store.dispatch('fetchService', { id: to.params.id, next })
        store.dispatch('fetchServicePartsTab', {
            id: to.params.id,
            badge: true,
        })
    },
    beforeRouteUpdate(to, from, next) {
        store.dispatch('fetchService', { id: to.params.id, next })
    },
}
</script>
