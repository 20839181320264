<template>
    <tbody>
        <tr v-for="(item, index) in items" :key="index">
            <td class="truncate">
                <span>{{ item.index }}</span>
            </td>
            <td class="truncate">
                <DoubleLine
                    :firtstLine="$moment(item.createdAt).format('DD-MM-YYYY')"
                    :secondLine="$moment(item.createdAt).format('HH:mm')"
                />
            </td>
            <td class="truncate">
                <span>{{
                    `${$get(item, 'technician.name', '')} ${$get(
                        item,
                        'technician.lastname',
                        ''
                    )}`
                }}</span>
            </td>
            <td class="truncate">
                <DoubleLine
                    :firtstLine="
                        item.contractor
                            ? `${item.contractor.name} ${item.contractor.lastname} `
                            : ''
                    "
                    :secondLine="
                        item.contractor ? item.contractor.phoneNumber : ''
                    "
                />
            </td>
            <td class="truncate">
                <span>{{ item.realization.interventionRange }}</span>
            </td>
            <td class="truncate">
                <span>{{ item.realization.technicianComments }}</span>
            </td>
            <td class="d-flex align-center justify-end">
                <v-btn small router :to="`/service/${item._id}`" icon>
                    <v-icon color="secondary" small
                        >mdi-arrow-right-thick</v-icon
                    >
                </v-btn>
            </td>
            <!-- <td class="d-flex align-center justify-end">
                numer zlecenia
                 <v-btn
                    icon
                    v-if="edit()"
                    @click="$emit('details', item)"
                    :action="'edit'"
                >
                    <v-icon size="20" color="secondary">mdi-pencil</v-icon>
                </v-btn> 
            </td>  --->
        </tr>
    </tbody>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
    props: ['items'],
    methods: {
        edit() {
            return this.checkPermissions('UPDATE')
        },
    },
}
</script>
