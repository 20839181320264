<template>
    <v-container>
        <h5>{{ $t('estimates:servicesToPerformed') }}</h5>
        <v-row>
            <v-col class="pl-10">
                <v-card elevation="0" height="60px" class="scroll">

                    <ol>
                        <li
                            v-for="(item, index) in choosenServices"
                            :key="index"
                        >
                            <span class="pl-8">{{ item.field }}</span>
                        </li>
                    </ol>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    name: 'ServicesToPerformedComponent',
    computed: {
        ...mapGetters(['getEstimatesChooseService']),
        choosenServices: {
            get() {
                return this.getEstimatesChooseService.estimatesServices
            },
        },
    },
}
</script>

<style lang="scss" scoped>
ol li {
    font-size: 0.8rem;
    color: grey;
}
.scroll {
   overflow-y: scroll
}
</style>
