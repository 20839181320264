<template>
    <v-container class="p-relative">
        <v-row>
            <v-col>
                <v-col cols="6" md="6">
                    <v-combobox
                        ref="el"
                        dense
                        label="Szukaj"
                        single-line
                        hide-details
                        @click="clear()"
                        class="mb-3"
                        v-model="comboBoxPart"
                        item-text="manufacturerIndex"
                        :items="getServicePartsList.items"
                        @keyup="searchParts($event.target.value)"
                        @input="assignToTable"
                        return-object
                    >
                        <template v-slot:item="{ item }">
                            {{ `${item.manufacturerIndex} - ${item.name}` }}
                        </template>
                        <template slot="prepend">
                            <img
                                src="@/assets/img/global/search.png"
                                width="16"
                                height="16"
                                alt
                                class="mt-1"
                            />
                        </template>
                    </v-combobox>
                </v-col>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <!-- PARTS TABLE -->
                <BasicTable :mode="'edit'" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import BasicTable from '../../EstimatesPartTable/BasicPartsTable.vue'
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
    components: {
        BasicTable,
    },
    data: () => ({
        comboBoxPart: '',
    }),
    computed: {
        ...mapGetters(['getServicePartsList', 'getEstimatesDetailsTable']),
    },
    methods: {
        ...mapActions(['setEstimateModalParts', 'fetchServiceParts']),
        clear() {
            this.comboBoxPart = []
        },
        assignToTable(e) {
   

            if (this.comboBoxPart?.manufacturerIndex) {
                let getEstimatesParts = this.getEstimatesDetailsTable || []
                this.comboBoxPart.quantity = 1
                getEstimatesParts.push(this.comboBoxPart)
       
                this.setEstimateModalParts(getEstimatesParts)
                this.reset()
            }
        },
        async searchParts(search) {
            await this.fetchServiceParts({
                search: search,
                searchFields: ['manufacturerIndex'],
            })
        },
        reset() {
            this.$refs.el.lazyValue = ''
            this.clear()
        },
    },
}
</script>

<style></style>
