<template>
    <div class="pa-5 confirmModal">
        <v-row>
            <v-col cols="12">
                <div class="d-flex justify-center py-2">
                    <img
                        width="100"
                        height="100"
                        src="@/assets/img/global/warning.svg"
                        alt="warning"
                    />
                </div>

                <div class="d-flex justify-center pa-2">
                    <v-card-title>Komunikat</v-card-title>
                </div>

                <v-card-text style="text-align: center;"
                    ><span
                        >{{ $t('service:ifStatusChangedToClosedMessage') }}
                    </span>
                    <div class="client-email-text mt-5">
                        <span>Adres e-mail klienta: {{ clientEmail }} </span>
                    </div>
                </v-card-text>
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    props: {
        type: {
            type: String,
        },
        clientEmail: {
            type: String,
        },
    },
    data() {
        return {
            menu: false,
            text: '',
            date: '',
        }
    },
    methods: {
        clearConfirmModal() {
            this.text = ''
            this.date = ''
        },
    },
    computed: {},
    mounted() {
        this.$root.$on('clearModal', () => {
            this.clearConfirmModal()
        })
    },
}
</script>
<style lang="scss" scoped>
.client-email-text {
    font-size: 0.75rem;
    font-weight: bold;
    color: rgb(68, 93, 117);
}
</style>
