<template>
    <div>
        <v-btn @click="edit()" class="buttons buttons--add">
            {{ $t('global:save') }}
        </v-btn>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    props: {
        action: {
            type: String,
        },
    },

    methods: {
        ...mapActions(['updateServiceParts', 'fetchService']),
        edit() {
            this.updateServiceParts({ id: this.$route.params.id }).then(() => {
                this.$emit('closeAddEstimateModal')
                this.fetchService({ id: this.$route.params.id })
            })
        },
    },
}
</script>
