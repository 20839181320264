<template>
    <div class="d-flex justify-center">
        <v-btn
            color="rgb(117, 117, 117)"
            width="110"
            height="40"
            @click="$emit('close-confirm')"
            class="white--text mx-2"
        >
            {{ $t('global:no') }}
        </v-btn>
        <v-btn
            width="110"
            height="40"
            color="#445d75"
            class="white--text mx-2"
            :disabled="emailLoading"
            @click="prepareToSendingMessage"
            >{{ $t('global:yes') }}</v-btn
        >
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import moment from 'moment'
export default {
    props: ['serviceDetails'],
    data() {
        return {
            emailLoading: false,
        }
    },
    computed: {
        ...mapGetters(['getSettingsDetails', 'getProfileDetails']),
    },
    methods: {
        ...mapActions(['fetchSettings', 'sendMail']),

        prepareDataFromDetailsComponent() {
      
            const data = {
                clientName: this.serviceDetails.client.name,
                clientEmail: this.serviceDetails.client.email,
                serviceId: this.serviceDetails._id,
            }

            return data
        },

        async sendMessageOnServiceStatusClosed() {
            try {
                this.emailLoading = true
                await this.fetchSettings({ type: 'add' })
                const companyServiceEmail = this.getSettingsDetails
                    .companyServiceEmail
                const applicationLogo = `https://${this.getSettingsDetails.applicationLink}/secured/logo/client_logo.png`

                let dataFromDetailsComponent = null
                if (this.serviceDetails) {
                    dataFromDetailsComponent = this.prepareDataFromDetailsComponent()
                }

                
                const dataToFormatEmail = {
                    companyServiceEmail,
                    applicationLogo,
                    alertSource: { type: 'SERVICE_ON_STATUS_CLOSED' },
                    subject: 'Potwierdzenie wykonania usługi',
                    clientName: dataFromDetailsComponent.clientName,
                    clientEmail: dataFromDetailsComponent.clientEmail,
                    creator: `${this.getProfileDetails.name} ${this.getProfileDetails.lastname}`,
                    serviceId: dataFromDetailsComponent.serviceId,
                }

                const response = await this.sendMail({
                    mailBody: {
                        case: 'onServiceStatusClosed',
                        mail: 'piotr.lipkowski@biuromax-balcer.pl',
                        alertSource: dataToFormatEmail.alertSource,
                        subject: dataToFormatEmail.subject,
                        details: {
                            clientName: dataToFormatEmail.clientName,
                            realisationTime: moment().format(
                                'DD-MM-YYYY, HH:mm '
                            ),
                        },
                        creator: dataToFormatEmail.creator,
                        applicationLogo: applicationLogo,
                        serviceId: dataToFormatEmail.serviceId,
                    },
                })
                if (response) {
                    this.emailLoading = false
                }
            } catch (err) {
                console.log(err)
            }
        },

        async prepareToSendingMessage() {
            await this.sendMessageOnServiceStatusClosed()

            this.$emit('close-confirm')
        },
    },
}
</script>

<style></style>
