<template>
    <v-container class="pt-0">
        <v-row>
            <v-col class="py-1" cols="12" md="6">
                <v-combobox
                    dense
                    label="Szukaj usługi"
                    single-line
                    hide-details
                    class="mb-3"
                    item-text="field"
                    :items="servicesDictionary"
                    @input="assignToTable"
                    return-object
                    ref="el"
                    @change="reset"
                >
                    <template v-slot:item="data">
                        <span v-if="data.item.backgroundColor">
                            <v-chip
                                class="mx-2"
                                :color="data.item.backgroundColor"
                                small
                                label
                                :text-color="
                                    data.item.textColor
                                        ? data.item.textColor
                                        : '#4d4d4d'
                                "
                                >{{ data.item.field }}</v-chip
                            >
                        </span>
                        <span class="truncate-list" v-else>{{
                            data.item.field
                        }}</span>
                    </template>
                    <template v-slot:selection="data">
                        <span v-if="data.item.backgroundColor">
                            <v-chip
                                class="mx-2"
                                :color="data.item.backgroundColor"
                                small
                                label
                                :text-color="
                                    data.item.textColor
                                        ? data.item.textColor
                                        : '#4d4d4d'
                                "
                                >{{ data.item.field }}</v-chip
                            >
                        </span>
                        <span class="truncate-list" v-else>{{
                            data.item.field
                        }}</span>
                    </template>
                    <template slot="prepend">
                        <img
                            src="@/assets/img/global/search.png"
                            width="16"
                            height="16"
                            alt
                            class="mt-1"
                        />
                    </template>
                </v-combobox>
            </v-col>
            <v-col style="text-align: end;" cols="6" md="6" class="pr-5">
                <div>
                    <v-btn
                        @click="toggle"
                        v-if="!this.isTablet()"
                        ref="btnPriceList"
                        class="buttons buttons--add"
                    >
                        {{ $t('estimates:priceList') }}
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <!-- SERVICES TABLE -->
                <Table :mode="'edit'" />
            </v-col>
        </v-row>

        <portal to="destination">
            <div
                v-if="openPriceListToggle"
                min-width="400"
                class="priceListDescModal"
                :style="{ left: x + 'px', top: y + 'px' }"
            >
                <v-card style="background-color: #45433f;" class="pa-7">
                    <h4 class="price--text pb-4">
                        Cennik
                    </h4>
                    <div class="price--desc">
                        <ol>
                            <li>
                                {{ $t('service-prices:survey.default') }} -
                                {{ $get(servicePrice, 'survey.price', '') }} zł
                            </li>
                            <li>
                                {{ $t('service-prices:repair.default') }} -
                                {{ $get(servicePrice, 'repair.price', '') }} zł
                            </li>
                            <li>
                                {{ $t('service-prices:installation.default') }}
                                -
                                {{
                                    $get(servicePrice, 'installation.price', '')
                                }}
                                zł
                            </li>
                            <li>
                                {{ $t('service-prices:diagnostics.default') }} -
                                {{
                                    $get(servicePrice, 'diagnostics.price', '')
                                }}
                                zł
                            </li>
                            <li>
                                {{ $t('service-prices:itService.default') }} -
                                {{ $get(servicePrice, 'itService.price', '') }}
                                zł
                            </li>
                            <li>
                                {{ $t('service-prices:remote.default') }} -
                                {{ $get(servicePrice, 'remote.price', '') }}
                                zł
                            </li>
                            <li>
                                {{ $t('service-prices:rateOneKmTransport') }} -
                                {{
                                    $get(
                                        servicePrice,
                                        'rateOneKmTransport.price',
                                        ''
                                    )
                                }}
                                zł
                            </li>

                            <li>
                                {{ $t('service-prices:staticRateTransport') }} -
                                {{
                                    $get(
                                        servicePrice,
                                        'staticRateTransport.price',
                                        ''
                                    )
                                }}
                                zł
                            </li>

                            <li>
                                {{ $t('service-prices:minRateTransport') }} -
                                {{
                                    $get(
                                        servicePrice,
                                        'minRateTransport.price',
                                        ''
                                    )
                                }}
                                zł
                            </li>
                        </ol>
                    </div>
                </v-card>
            </div>
        </portal>
    </v-container>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import Table from './ServicesTable.vue'
export default {
    props: ['modalRef'],
    data: () => ({
        openPriceListToggle: false,
        x: 0,
        y: 0,
    }),
    computed: {
        ...mapGetters([
            'getRegistryDictionaries',
            'getEstimatesChooseService',
            'getServiceDetails',
        ]),
        servicesDictionary: {
            get() {
             
                return this.getRegistryDictionaries.servicesDictionary.sort(
                    (a, b) => (a.field > b.field ? 1 : -1)
                )
            },
        },
        servicePrice: {
            get() {
                const servicePrice = this.getServiceDetails?.device?.model
                    ?.servicePrice
                return servicePrice
            },
        },
    },
    components: {
        Table,
    },
    methods: {
        ...mapMutations(['setSnackbars']),
        ...mapActions([
            'fetchServiceEstimatesTab',
            'setEstimatesModalChooseTable',
        ]),
        assignToTable(e) {
            const servicePrice = this.getServiceDetails?.device?.model
                ?.servicePrice
            if (servicePrice) {
                const repairCost = servicePrice?.repair.price

                e.price = repairCost
                e.quantity = 1
                e.total = repairCost
                this.setEstimatesModalChooseTable(e)
            } else {
                this.setSnackbars({
                    type: 'warning',
                    text: `Brak wymaganych danych pakietu cenowego`,
                })
            }
        },
        toggle(e) {
            const left = this.$refs.btnPriceList.$el.getBoundingClientRect()
                .left
            const top = this.$refs.btnPriceList.$el.getBoundingClientRect().top

            this.x = left - 150
            this.y = top - 330

            this.openPriceListToggle = !this.openPriceListToggle
        },
        isTablet() {
            if (
                navigator.userAgent.includes('Android') ||
                navigator.userAgent.includes('iPhone') ||
                navigator.userAgent.includes('iPad')
            ) {
                return true
            }
            return false
        },
        setOpenPriceListToggleToFalse() {
            this.openPriceListToggle = false
        },
        reset() {
            this.$refs.el.lazyValue = ''
        },
    },
    beforeMount() {
        this.fetchServiceEstimatesTab({ id: this.$route.params.id })
    },
    mounted() {
        this.$root.$on('onClosePriceListInfo', () => {
            this.setOpenPriceListToggleToFalse()
        })
    },
}
</script>

<style lang="scss" scoped>
.priceListDescModal {
    position: absolute;
    width: 300px;
    min-height: 200px !important;
    z-index: 9 !important;
}

.price--text {
    color: white;
}
.price--desc {
    font-size: 0.8rem;
    color: white;

    li {
        padding: 5px;
    }
}
</style>
